import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  /* 重置浏览器部分标签样式 */
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  * {
    box-sizing: border-box;
  }
  ul {
    padding: 0;
    margin: 0;
  }
  li {
    list-style: none;
  }
  p {
    margin: 0;
  }


  /* 重置 antd 部分组件样式 */
  /** Button */
  button[ant-click-animating-without-extra-node].no-trigger::after {
    border: 0 none;
    opacity: 0;
    animation: none 0 ease 0 1 normal;
  }
  .ant-btn.initial {
    background-color: unset;
    border: 0;
    outline: 0;
    box-shadow: initial;
  }
  .ant-btn {
    color: inherit;
    &:hover, &:focus {
      color: ${props => props.theme.theme};
    }
  }

  /** Dropdown */
  .ant-dropdown {
    .ant-dropdown-menu {
      background-color: ${props => props.theme.modalBg};
    }
    .ant-dropdown-menu-item {
      padding: 4px 12px;
      font-size: 12px;
      font-weight: 500;
      color: ${props => props.theme.primary};
      &-active,&:hover,&-selected {
        color: ${props => props.theme.theme};
        background-color: ${props => props.theme.themeLight};
      }
    }
  }

  /* Select */
  .ant-select {
    .ant-select-item,
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      font-size: 12px;
      font-weight: 700;
      color: ${props => props.theme.primary};
    }
    .ant-select-item:hover,
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${props => props.theme.themeLight};
    }
    &-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border-color: ${props => props.theme.theme};
      box-shadow: unset;
    }
  }
  .ant-select-dropdown {
    background-color: ${props => props.theme.modalBg};
    .ant-select-item {
      font-size: 12px;
      font-weight: 500;
      color: ${props => props.theme.primary};
    }
    .ant-select-item-option-active:not(.ant-select-item-option-disabled),
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      color: ${props => props.theme.theme};
      background-color: ${props => props.theme.themeLight};
    }
  }

  /* InputNumber */
  // -- 修改hover效果
  .ant-input-number {
    color: ${props => props.theme.primary};
    background-color: ${props => props.theme.border};
    border-color: transparent;
    border-radius: 4px;
    overflow: hidden;
    &-input {
      background-color: ${props => props.theme.cardBg};
    }
    &:not(.ant-input-number-disabled):hover {
      border-color: ${props => props.theme.theme};
    }
    // 修改focus效果
    &:focus,&-focused {
      border-color: ${props => props.theme.theme};
      box-shadow: unset;
    }
    // 上下箭头颜色
    .ant-input-number-handler {
      background-color: ${props => props.theme.cardBg};
      border-color: ${props => props.theme.border};
      &-up-inner, &-down-inner {
        color: ${props => props.theme.secondary};
      }
    }
    .ant-input-number-handler:hover .ant-input-number-handler-up-inner, .ant-input-number-handler:hover .ant-input-number-handler-down-inner {
      color: ${props => props.theme.theme};
    }
    &.ant-input-number-disabled {
      background-color: ${props => props.theme.btnBgDisabled};
      input {
        color: ${props => props.theme.btnTextDisabled};
      }
    }
    &-disabled:hover {
      border-color: ${props => props.theme.btnBgDisabled};
    }
  }
  /* tabs 超出下拉选择 */
  .ant-tabs.ant-tabs-editable-card > .ant-tabs-nav {
    .ant-tabs-nav-operations {
      color: ${props => props.theme.theme};
      background-color: ${props => props.theme.cardBg};
    }
  }
  .ant-tabs-dropdown {
    .ant-tabs-dropdown-menu {
      padding: 0;
      background-color: ${props => props.theme.modalBg};
    }
    .ant-tabs-dropdown-menu-item {
      height: 30px;
      padding: 0 8px 0 16px;
      font-size: 12px;
      font-weight: 500;
      color: ${props => props.theme.primary};
      &:hover {
        color: ${props => props.theme.theme};
        background-color: ${props => props.theme.themeLight};
      }
      &-remove {
        display: flex;
        justify-content: center;
        align-items: center;
        .icon-delete {
          width: 12px;
          height: 12px;
          box-sizing: content-box;
          fill: ${props => props.theme.secondary};
          &:hover {
            fill: ${props => props.theme.theme};
          }
        }

      }
    }
  }
  /* DataPicker */
  .ant-picker-dropdown {
    .ant-picker-panel {
      border: 0;
    }
    .ant-picker-panel-container {
      background-color: ${props => props.theme.modalBg};
    }
    // 顶部切换日期按钮
    .ant-picker-header {
      color: ${props => props.theme.title};
      border-color: ${props => props.theme.border};
      // -- 切换年/月按钮
      .ant-picker-header-view button:hover {
        color: ${props => props.theme.theme};
      }
      // -- 左右切换按钮
      & > button {
        color: ${props => props.theme.secondary};
        &:hover {
          color: ${props => props.theme.theme};
        }
      }
    }
    // 内容区
    // -- 星期行
    .ant-picker-content th {
      color: ${props => props.theme.primary};
    }
    // -- 日期项
    .ant-picker-cell {
      color: ${props => props.theme.secondary};
    }
    .ant-picker-cell-in-view {
      color: ${props => props.theme.primary}
    }
    // 底部
    .ant-picker-panel .ant-picker-footer {
      border-color: ${props => props.theme.border};
    }
    // -- 选中日期
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
      background-color: ${props => props.theme.theme};
    }
    // -- 今日日期
    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-color: ${props => props.theme.theme};
    }
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
      background-color: ${props => props.theme.themeLight};
    }
    // -- 回到今日按钮
    .ant-picker-today-btn {
      color: ${props => props.theme.theme};
    }
    /* RangePicker 相关 */
    // -- 禁用部分
    .ant-picker-cell-disabled {
      color: ${props => props.theme.btnTextDisabled};
      &::before {
        background-color: ${props => props.theme.btnBgDisabled};
      }
    }
    // -- 选中高亮日期框
    .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
      background-color: ${props => props.theme.theme};
    }
    // -- 选中日期段之间的背景
    .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
      background: ${props => props.theme.themeLight};
    }
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
      ${"" /* background: #576ba7; */}
      background: ${props => props.theme.pickerRangeHover};
    }
    // -- 选中日期段之间日期项的 hover 效果
    .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
    .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
      ${"" /* background: #576ba7; */}
      background: ${props => props.theme.pickerRangeHover};
    }
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
      background: ${props => props.theme};
    }
    // -- 选中日期段的高亮虚框显示
    .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
      border-color: ${props => props.theme.theme};
    }
  }
  /** Popover */
  .ant-popover {
    .ant-popover-arrow-content, &-inner {
      background-color: ${props => props.theme.modalBg};
    }
    &-inner {
      box-shadow: 0 2px 8px 0 rgb(0 0 0 / 14%);
    }
  }
  /** Divider */
  .ant-divider {
    margin: 16px 0;
    border-color: ${props => props.theme.border};
  }
  /** Table */
  .ant-table {
    &-container {
      .ant-table-thead {
        height: 30px;
        .ant-table-cell {
          padding: unset;
          height: 100%;
          background-color: ${props => props.theme.tableRowActive};
          font-size: 12px;
          color: ${props => props.theme.secondary};
          border-bottom: 0;
          &::before {
            display: none;
          }
        }
      }
      .ant-table-tbody .ant-table-row {
        .ant-table-cell {
          padding: 8px;
          font-size: 12px;
          font-weight: 600;
          color: ${props => props.theme.primary};
          border-color: ${props => props.theme.border};
        }
        &:hover .ant-table-cell {
          color: ${props => props.theme.theme};
          background-color: ${props => props.theme.themeLight};
        }
      }
      .ant-table-tbody > tr > td {
        border-color: ${props => props.theme.border};
        &.ant-table-cell-row-hover {
          background: inherit;
        }
      }
    }
    // -- sort 相关
    .ant-table-column-sorters {
      position: relative;
      // -- 被筛选的数据列
      td.ant-table-column-sort {
        background-color: ${props => props.theme.themeLight};
      }
      // -- 筛选按钮容器
      .ant-table-column-sorter {
        position: absolute;
        right: 8px;
        color: ${props => props.theme.btnTextDisabled};
      }
      // -- 被应用的筛选按钮
      .ant-table-column-sorter-up.active, .ant-table-column-sorter-down.active {
        color: ${props => props.theme.theme};
      }

    }
    // -- filter 相关
    .ant-table-filter-column {
      position: relative;
      .ant-table-filter-trigger {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 8px;
        z-index: 1;
        margin-left: 12px;
        color: ${props => props.theme.btnTextDisabled};
        &.active {
          color: ${props => props.theme.theme};
        }
      }
    }
    .ant-table-placeholder:hover td {
      background: unset !important;
    }
  }
  .scroll-auto.ant-table-wrapper {
    &,
    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-table.ant-table-fixed-header,
    .ant-table-container {
      height: 100%;
    }
  }
  /* -- Table Filter Dropdown */
  // -- 表格筛选弹窗。现仅用于历史订单方向筛选弹窗
  .ant-table-filter-dropdown {
    background-color: ${props => props.theme.modalBg};
    .ant-checkbox {
      .ant-checkbox-inner {
        background-color: ${props => props.theme.cardBg};
        border-color: ${props => props.theme.border};
      }
      &:hover .ant-checkbox-inner {
        border-color: ${props => props.theme.theme};
      }
      &-checked .ant-checkbox-inner {
        background-color: ${props => props.theme.theme};
        border-color: ${props => props.theme.theme};
      }
    }
    .ant-table-filter-dropdown-btns {
      border-color: ${props => props.theme.border};
      & > .ant-btn {
        font-size: 12px;
      }
      & > .ant-btn:first-child {
        color: ${props => props.theme.btnText};
        &:hover {
          color: ${props => props.theme.theme};
        }
      }
      & > .ant-btn:nth-child(2) {
        background-color: ${props => props.theme.theme};
        border-color: ${props => props.theme.theme};
        color: ${props => props.theme.white}
      }
    }
  }
  /** Description */
  .ant-descriptions-bordered {
    font-size: 13px;
    .ant-descriptions-view,
    .ant-descriptions-row,
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      border-color: ${props => props.theme.border};
    }
    .ant-descriptions-item-label,
    .ant-descriptions-item-content {
      padding: 6px 8px;
    }
    .ant-descriptions-item-label {
      font-size: inherit;
      color: ${props => props.theme.secondary};
      background-color: ${props => props.theme.descBg};
      white-space: nowrap;
    }
    .ant-descriptions-item-content {
      font-size: inherit;
      font-weight: 500;
      color: ${props => props.theme.primary};
    }
  }
  /** Switch */
  .ant-switch {
    ${"" /* background-color: ${props => props.theme.btnBg}; */}
    margin-right: 8px;
    background-color: ${props => props.theme.tradeModalBg};
    &:focus,
    &-checked:focus {
      box-shadow: unset;
    }
    // -- 取消光晕效果
    .ant-click-animating-node {
      display: none;
    }
    &.ant-switch-checked {
      background-color: ${props => props.theme.theme} !important;
    }
    .ant-switch-handle::before {
      background-color: ${props => props.theme.white};
    }
  }
  /** Confirm Modal */
  .ant-modal-confirm {
    .ant-modal-content {
      background-color: ${props => props.theme.modalBg};
    }
    .ant-modal-confirm-title {
      font-size: 14px;
      font-weight: 500;
      color: ${props => props.theme.title};
    }
    .ant-modal-confirm-content {
      font-size: 12px;
      line-height: 1.5em;
      font-weight: 500;
      color: ${props => props.theme.primary};
    }
    .ant-modal-confirm-btns {
      .ant-btn { // cancel
        color: ${props => props.theme.btnText};
        border-color: ${props => props.theme.border};
        background-color: transparent;
        &:hover {
          color: ${props => props.theme.theme};
          border-color: currentColor;
        }
      }
      .ant-btn-primary {  // ok
        border-color: ${props => props.theme.theme};
        color: ${props => props.theme.white};
        background-color: ${props => props.theme.theme};
        &:hover {
          color: ${props => props.theme.white};
          border-color: ${props => props.theme.theme};
        }
      }
    }
    .ant-modal-confirm-body > .anticon {
      color: ${props => props.theme.starLow};
    }
  }
  /** Form */
  .ant-form {
    .ant-form-item-label > label {
      font-size: 14px;
      font-weight: 500;
      color: ${props => props.theme.primary};
    }
  }
  /** Notification */
  .ant-notification {
    color: ${props => props.theme.primary};
    .ant-notification-notice {
      background-color: ${props => {
        const { key, ...theme } = props.theme
        return key === "light" ? theme.modalBg : theme.tooltipBg
      }};
      &.ant-notification-notice-success {
        .ant-notification-notice-message,
        .anticon.ant-notification-notice-icon-success {
          color: ${props => props.theme.themeGreen};
        }
      }
      &.ant-notification-notice-error {
        .ant-notification-notice-message,
        .anticon.ant-notification-notice-icon-error {
          color: ${props => props.theme.themeRed};
        }
      }
      &.ant-notification-notice-warning {
        .ant-notification-notice-message,
        .anticon.ant-notification-notice-icon-warning {
          color: ${props => props.theme.starLow};
        }
      }
    }
    .ant-notification-notice-message {
      font-weight: 500;
    }
    .ant-notification-notice-close {
      color: inherit;
    }
  }
  /** Message */
  .ant-message {
    color: ${props => props.theme.primary};
    .ant-message-notice-content {
      padding: 0;
      overflow: hidden;
    }
    .ant-message-custom-content {
      padding: 10px 16px;
      font-weight: 500;
      border-radius: 2px;
      &.ant-message-error {
        background-color: ${props => {
          const { key, ...theme } = props.theme
          return key === "light" ? theme.modalBg : theme.themeRed
        }};
      }
      &.ant-message-success {
        background-color: ${props => {
          const { key, ...theme } = props.theme
          return key === "light" ? theme.modalBg : theme.themeGreen
        }};
      }
      &.ant-message-warning {
        background-color: ${props => {
          const { key, ...theme } = props.theme
          return key === "light" ? theme.modalBg : theme.starLow
        }};
      }
    }
    .ant-message-error .anticon {
      color: ${props => {
        const { key, ...theme } = props.theme
        return key === "light" ? theme.themeRed : theme.primary
      }};
    }
    .ant-message-success .anticon {
      color: ${props => {
        const { key, ...theme } = props.theme
        return key === "light" ? theme.themeGreen : theme.primary
      }};
    }
    .ant-message-warning .anticon {
      color: ${props => {
        const { key, ...theme } = props.theme
        return key === "light" ? theme.starLow : theme.primary
      }};
    }
  }
  /** Tooltip */
  .ant-tooltip {
    &-arrow {
      &-content {
        background-color: ${props => props.theme.tooltipBg};
      }
    }
    &-inner {
      color: ${props => {
        const { key, ...theme } = props.theme
        return key === "light" ? "white" : theme.primary
      }};
      background-color: ${props => props.theme.tooltipBg};
    }
  }

  /* 全局弹窗样式 */
  .home-dropdown-locale {
    .abbr {
      position: relative;
      top: 1px;
    }
  }
  .home-dropdown-menu {
    .ant-dropdown-menu-title-content {
      display: inline-flex;
      align-items: center;
    }
    .icon-setting, .icon-logout {
      width: 14px;
      height: 14px;
      margin-right: 8px;
      fill: currentColor;
    }
  }

  /* 常用样式组合 */
  .flex,
  .flex-jsb,
  .flex-jc,
  .flex-ac,
  .flex-center,
  .flex-dir-col,
  .flex-1 {
    display: flex;
  }
  .flex-inline {
    display: inline-flex;
  }
  .flex-jsb {
    justify-content: space-between;
  }
  .flex-dir-col {
    flex-direction: column;
  }
  .flex-jc {
    justify-content: center;
  }
  .flex-ac {
    align-items: center;
  }
  .flex-as {
    align-items: flex-start !important;
  }
  .flex-ae {
    align-items: flex-end !important;
  }
  .flex-center {
    align-items: center;
    justify-content: center;
  }
  .flex-1 {
    flex: 1;
  }
  .ta-center {
    text-align: center;
  }
`
