import { message } from "antd"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import { Resizable } from "re-resizable"
import BottomPane from "../components/home/BottomPane"
import TopRightPane from "../components/home/TopRightPane"
import QuotePane from "../components/home/QuotePane"
import TopRightWidgets from "../components/home/TopRightWidgets"
import {
  checkLogin,
  getCurrAccount,
  getInfoByAccount,
} from "../utils/userHelper"
import { useDispatch } from "react-redux"
import {
  close,
  send,
  subscribeOnce,
  subscribe,
  init,
} from "../store/socketActions"
import { initSymbols, updateSymbolsByNames } from "../store/symbolSlice"
import { fetchOrders, updateOrders } from "../store/orderSlice"
import { throttle } from "lodash"
import config from "../config/config"

const { wsUrl: wsUrls } = config
const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  min-width: 1200px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: hidden;
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${props => props.theme.background};
  .hp-loading-x {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: ${props => (!props.isPageLoading ? "none" : "flex")};
    background-color: white;
    z-index: 9;
  }

  & > .hp-row-1 {
    width: 100%;
    /* height: 68.73vh; */
    flex: none;
    display: flex;
  }
  & > .hp-row-2 {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`

const HomePage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // const [isPageLoading, setIsPageLoading] = useState(true)
  useEffect(() => {
    if (!checkLogin()) {
      // 仅能判断是否有 token ，未验证其有效性
      message.error("登录失效，请重新登录！")
      setTimeout(() => {
        navigate("/login")
      }, 0)
      return
    }
    /** NOTE: 获取【symbols】数据 */
    // 初始化 websocket 连接
    const { type } = getInfoByAccount(getCurrAccount())
    const wsUrl = wsUrls[type]
    const socketInit = () => {
      dispatch(send({ cmd: "symbols", args: [""] }))
      // -- 订阅并初始化 symbols 数据，并发送报价的 socket 请求
      dispatch(
        subscribeOnce({
          evtName: "symbol",
          cb: data => {
            dispatch(initSymbols(data))
            const names = data.map(item => item.name)
            dispatch(send({ cmd: "quote", args: [names.join(",")] }))
          },
        })
      )
      /** NOTE: 订阅【报价】数据并更新 symbols 数据相应字段 */
      const cacheDataMap = new Map() // 用 Map 是为了去重，同一商品后面接收到的报价信息直接覆盖之前收到的
      // -- 报价更新做节流处理，降低渲染频率
      const updateQuote = throttle(cacheDataMap => {
        const cacheData = Object.values(
          Object.fromEntries(cacheDataMap.entries())
        )
        if (!cacheData.length) return
        dispatch(updateSymbolsByNames(cacheData))
        cacheDataMap.clear()
      }, 500)
      dispatch(
        subscribe({
          evtName: "quote",
          cb: data => {
            // cacheData.push(data)
            cacheDataMap.set(data.symbol, data)
            updateQuote(cacheDataMap)
          },
        })
      )
      /** NOTE: 订阅【订单】数据 */
      // -- 获取当前订单数据
      dispatch(fetchOrders())
      // -- 订阅订单变化 && 更新订单列表数据
      const { token } = getInfoByAccount()
      dispatch(send({ cmd: "order", args: [token] }))
      dispatch(
        subscribe({
          evtName: "order",
          cb: data => dispatch(updateOrders(data)),
        })
      )
    }
    if (!wsUrl) {
      message.error("连接建立失败，请重试")
      navigate("/login")
    }
    dispatch(init({ url: wsUrl, openCb: socketInit }))

    // TEMP: 获取并记录是否停盘【暂时不判断】
    // const getSuspensionInfo = async () => {
    //   const { error, data } = await post(apis.getDate)
    //   // mock data
    //   // const value = { Unix: 1613233200, datetime: '2021-02-14 00:20:00', weekday: 0 }
    //   let isSuspension = true
    //   const oneDayTime = 24 * 60 * 60 * 1000, // ms
    //     datetime = data.datetime.replace(/-/g, "/"),
    //     curdate1 = new Date(datetime.substr(0, 10) + " 00:06:00").getTime(),
    //     curdate2 = new Date(datetime.substr(0, 10) + " 00:05:00").getTime(),
    //     MondayTime = curdate1 - (data.weekday - 1) * oneDayTime,
    //     SaturdayTime = curdate2 + (6 - data.weekday) * oneDayTime

    //   if (data.Unix * 1000 > MondayTime && data.Unix * 1000 < SaturdayTime) {
    //     // console.log("交易时间")
    //     isSuspension = false
    //   } else {
    //     // console.log("非交易时间")
    //     isSuspension = true
    //   }

    //   setStorage(strings.storageKeyIsSuspension, isSuspension)
    // }
    // getSuspensionInfo()

    // TEMP: 离开当前页时手动关闭 websocket 连接
    return () => {
      // console.log("==> leave home")
      dispatch(close())
      // setStorage(strings.storageKeyThirdPartHadAutoLogin, 0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   const readyState = document.readyState
  //   console.log("==> readyState", readyState)
  //   // if (readyState === "complete") {
  //   //   setIsPageLoading(false)
  //   // } else {
  //   //   setIsPageLoading(true)
  //   // }
  // }, [document.readyState])

  // isPageLoading={isPageLoading}
  return (
    <Wrapper>
      <>
        {/* <PageLoading className="hp-loading-x" /> */}
        <Resizable
          className="hp-row-1"
          handleClasses="test"
          defaultSize={{
            height: "68.73vh",
          }}
          minHeight="30vh"
          maxHeight="68.73vh"
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
        >
          <Resizable
            defaultSize={{
              width: "400px",
              height: "100%",
            }}
            maxWidth="520px"
            minWidth={350}
            enable={{
              top: false,
              right: true,
              bottom: false,
              left: false,
              topRight: false,
              bottomRight: false,
              bottomLeft: false,
              topLeft: false,
            }}
          >
            <QuotePane />
          </Resizable>
          <TopRightPane />
          <TopRightWidgets />
        </Resizable>
        <BottomPane className="hp-row-2" />
      </>
    </Wrapper>
  )
}

export default HomePage
