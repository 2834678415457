import config from "../config/config"
import { getStorage } from "../utils/storageHelper"
import strings from "./strings"

const { thirdPartProjectNames } = config
const colors = {
  light: {
    key: "light",
    detail: "#ABB4C3",
    hoverBg: "#e2e6e9",

    /** 品牌色 */
    theme: "#4571e7",
    themeLight: "rgb(196 212 255 / 12%)",
    // theme: "#E72737",
    // themeLight: "rgb(237 196 199 / 8%)",
    /** 中性色 */
    // -- 文字相关
    title: "#333333",
    primary: "#666666",
    secondary: "#999999",
    btnText: "#666666",
    btnTextDisabled: "#c2c2c2",
    // -- 按钮相关
    btnBg: "#eaeaea",
    btnBgDisabled: "rgba(0, 0, 0, 0.04)",
    // -- 其余
    border: "#d9d9d9",
    background: "#f4f5f9",
    cardBg: "#ffffff",
    modalBg: "#ffffff",
    descBg: "#f4f5f9",
    tooltipBg: "#2b2e38",
    tableRowActive: "#f4f5f9",
    solidIcon: "rgba(0, 0, 0, 0.25)",
    // -- 交易弹窗
    tradeModalBg: "#f4f5f9",
    tradeModalTabActiveText: "#4571e7",
    tradeModalTabActiveBg: "#ffffff",
    pickerRangeHover: "rgba(69, 113, 231, 0.15)",
    scrollBarThumb: "#cccccc",
    scrollBarTrack: "white",
    chartSliderHandlerFill: "white",
    chartSliderHandlerStroke: "#d9d9d9",
    /** 辅助色 */
    white: "#FFFFFF",

    // 功能色
    // themeRed: "#fc3131",
    // themeGreen: "#00b276",

    themeRed: "#F53939",
    themeGreen: "#2FC297",
    themeRedActive: "#e53833",
    themeGreenActive: "#22a36c",
    starLow: "#ffac00",
    starHigh: "#E72737",
    starDefault: "#d0d0d0",
    error: "#ff4d4f",
  },
  dark: {
    key: "dark",
    detail: "#787b86",
    hoverBg: "#e2e6e9",

    /** 品牌色 */
    theme: "#6785de",
    themeLight: "#343a52",
    // theme: "#E72737",
    // themeLight: "rgb(237 196 199 / 8%)",
    /** 中性色 */
    // -- 文字相关
    title: "rgba(255 255 255 / 90%)",
    primary: "rgba(255 255 255 / 80%)",
    secondary: "rgba(255 255 255 / 40%)",
    btnText: "#666666",
    btnTextDisabled: "rgba(255 255 255 / 40%)",
    // -- 按钮相关
    btnBg: "#363a45",
    // btnBg: "#191c23",
    btnBgDisabled: "rgba(0, 0, 0, 0.04)",
    // -- 其余
    border: "rgba(255 255 255 / 10%)",
    background: "#05060a",
    cardBg: "#131722",
    // cardBg: "#16151a",
    modalBg: "#1e222d",
    descBg: "#131722",
    tooltipBg: "rgb(54 58 69 / 950%)",
    tableRowActive: "#1e222d",
    solidIcon: "rgb(121 122 128 / 42%)",
    // -- 交易弹窗
    tradeModalBg: "#131722",
    tradeModalTabActiveText: "#ffffff",
    tradeModalTabActiveBg: "#6785de",
    pickerRangeHover: "rgba(103, 133, 222, 0.3)",
    scrollBarThumb: "rgb(120 123 134 / 67%)",
    scrollBarTrack: "transparent",
    chartSliderHandlerFill: "transparent",
    chartSliderHandlerStroke: "#6785de",
    /** 辅助色 */
    white: "#ffffff",

    // 功能色
    themeRed: "#de5e56",
    themeGreen: "#51a39a",
    themeRedActive: "#c35048",
    themeGreenActive: "#438a82",
    starLow: "#deb887",
    starHigh: "#de5e56",
    starDefault: "#444951",
    error: "#ff4d4f",
  },
}

const hotsignProColors = {
  light: {
    ...colors.light,
    theme: "#E72737",
    themeLight: "rgb(231 39 55 / 8%)",
    background: "#F7F7F7",
    descBg: "#FAFAFA",
    tableRowActive: "#FAFAFA",
    border: "#F3F3F3",
    // -- 交易弹窗
    tradeModalBg: "rgba(237, 196, 199, 0.15)",
    tradeModalTabActiveText: "#E72737",
    pickerRangeHover: "rgba(237, 196, 199, 0.15)",
    // -- 图表
    tooltipBorder: "#d0d0d0",
    sliderForeground: "#E5E5E5",
  },
  dark: {
    ...colors.dark,
    theme: "#D12C41",
    themeLight: "rgb(237 196 199 / 8%)",
    background: "#101112",
    cardBg: "#1D1F23",
    tableRowActive: "#22252B",
    modalBg: "#1D1F23",
    descBg: "#22252B",
    border: "#282D38",
    // -- 交易弹窗
    tradeModalBg: "rgba(237, 196, 199, 0.15)",
    tradeModalTabActiveText: "#ffffff",
    tradeModalTabActiveBg: "#D12C41",
    pickerRangeHover: "rgba(237, 196, 199, 0.15)",
    // -- 图表
    tooltipBorder: "#565A5F",
    sliderForeground: "#282D38",
  },
}

const COLORS = {
  default: colors,
  [thirdPartProjectNames.hotsignPro]: hotsignProColors,
}
// const thirdPartProjectName = getStorage(strings.storageKeyThirdPartProjectName)
// const colorConfig = thirdPartProjectName ? COLORS[thirdPartProjectName] : colors
// console.log(thirdPartProjectName, colorConfig)
// console.log(COLORS)
// export { colorConfig as default, hotsignProColors as hpColors }
export default COLORS
