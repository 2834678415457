const home = {
  home: {
    quotePane: {
      title: "QUOTE",
      tabs: {
        favorite: "FAVORITES",
        inland: "DOMESTIC",
        intl: "INTERNATIONAL",
      },
      table: {
        ths: {
          th1: "SYMBOL",
          th2: "CHANGE",
          th3: "BID",
          th4: "ASK",
        },
        tr: {
          sell: "sell",
          buy: "buy",
          high: "High",
          low: "Low",
        },
      },
      message: {
        error: {
          marginNull: "Failed to get margin info, please try again later",
          transactFailed: "Failed to transact",
        },
        success: {
          transactSuccess: {
            title: "Transact Successfully",
            desc: "transact {desc} hands",
          },
        },
      },
      tooltip: {
        favoriteTab:
          "Only the symbols that can be traded under the current account are displayed",
      },
    },
    chartsPane: {
      title: "CHART",
    },
    calendarPane: {
      title: "CALENDAR",
      dateRadios: {
        label1: "Yesterday",
        label2: "Today",
        label3: "Tomorrow",
      },
      ecoTable: {
        title: "Economic Data",
        ths: {
          th1: "TIME",
          th2: "ECONOMIC INDICATOR",
          th3: "IMPACT",
          th4: "ACTUAL",
          th5: "CONSENSUS",
          th6: "PREVIOUS",
          th7: "More",
        },
        detailBtn: "Detail",
      },
      eventTable: {
        title: "Economic Events",
        ths: {
          th1: "TIME",
          th2: "COUNTRY",
          th3: "IMPACT",
          th4: "ECONOMIC EVENTS",
        },
        undetermined: "--",
      },
      filterPopover: {
        btnText: "Filter",
        title: "COUNTRY / REGION",
        countries: {
          all: "ALL",
          America: "America",
          Canada: "Canada",
          Eurozone: "Eurozone",
          Germany: "Germany",
          England: "England",
          France: "France",
          Switzerland: "Switzerland",
          Italy: "Italy",
          Spain: "Spain",
          China: "China",
          Japan: "Japan",
          Korea: "Korea",
          India: "India",
          HK: "Hong Kong, China",
          TW: "Taiwan, China",
          Singapore: "Singapore",
          Australia: "Australia",
          NewZealand: "NewZealand",
        },
        submitBtnText: "Submit",
      },
      modal: {
        infos: {
          item1: "PUBLISH TIME",
          item2: "ACTUAL",
          item3: "CONSENSUS",
          item4: "PREVIOUS",
          item5: "NEXT TIME",
          item6: "IMPACT",
          noPub: "Unpublished",
        },
        subTitle2: "HISTORICAL DATA",
        subTitle3: "HISTORICAL DATA",
        table: {
          thead1: "PUBLISH TIME",
          thead2: "PREVIOUS",
          thead3: "CONSENSUS",
          thead4: "ACTUAL",
        },
        error: {
          getData: "Fail to get data",
        },
      },
    },
    trWidgets: {
      menu: {
        settings: "Settings",
        signOut: "Sign out",
      },
    },
    orderPane: {
      tabs: {
        tab1: "MARKET",
        tab2: "LIMIT",
        tab3: "HISTORY",
      },
      market: {
        ths: {
          th1: "SYMBOL",
          th2: "VOLUME",
          th3: "TYPE",
          th4: "OP/CP",
          th5: "OPEN TIME",
          th6: "TP",
          th7: "SL",
          th8: "SWAP",
          th9: "PROFIT",
          th10: {
            btnText: "CLOSE",
            ddItem1: "Close All",
            ddItem2: "Close Profitable(net profit)",
            ddItem3: "Close Losing(net profit)",
          },
        },
        closeText: "Liquidate the order", // 英文版没用这项
        message: {
          success: {
            closeSuccess: "Liquidate the order successfully",
            closeSuccessDesc: "The order number being operated is: {tickets}",
          },
          errorPartially: {
            title: "Some orders failed to be closed",
            desc: "Failed tickets：{failedTickets}, The failure causes are in sequence：{failedReason}",
          },
          error: {
            noTickets: "There is no relevant order at present",
            closeFailed: "Failed to liquidate the order",
          },
        },
        confirm: {
          title: "Confirm to liquidate the following order number?",
          okText: "Yes",
          cancelText: "No",
        },
      },
      limit: {
        ths: {
          th4: "LP/CP",
          th10: "DELETE ALL",
        },
        closeText: "Liquidate the order", // 英文版没用这项
      },
      history: {
        picker: {
          placeholder: {
            startText: "START DATE",
            endText: "END DATE",
          },
        },
        ths: {
          th1: "SYMBOL",
          th2: "VOLUME",
          th3: "TYPE",
          th4: "OP/CP",
          th5: "OPEN TIME",
          th6: "CLOSE TIME",
          th7: "TP",
          th8: "SL",
          th9: "SWAP",
          th10: "PROFIT",
          th11: "TICKET", // 仅用于导出 excel
        },
        exportBtn: "Export",
      },
    },
    accountPane: {
      labels: {
        label1: "Present Equity",
        label2: "Free Margin",
        label3: "Risk Degree",
        label4: {
          market: "Profit",
          history: "History Profit",
        },
      },
    },
  },
}
export default home
