import { useState, useEffect } from "react"
import { message, notification, Tabs } from "antd"
import styled from "styled-components"
import GlobalModal from "../GlobalModal"
import config from "../../../config/config"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { selectSymbolByName } from "../../../store/symbolSlice"
import { post } from "../../../services/request"
import apis from "../../../services/apis"
import { toDecimal } from "../../../utils/util"
import { Loading } from "../../common/Loading"
import { MarketPane } from "./MarketPane"
import { LimitPane } from "./LimitPane"
import { useIntl } from "react-intl"

const { TabPane } = Tabs
const { cmdConfig, defaultString: DEFAULT_STRING, locales } = config
const [, , enus] = locales.ids

const Wrapper = styled(GlobalModal)`
  .ant-modal-header {
    .otm-title-x {
      display: flex;
      align-items: center;
      .spread {
        height: 1.5em;
        margin-left: 8px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 4px;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 500;
        color: ${props => props.theme.white};
        background-color: ${props => props.theme.themeRed};
        &.down {
          background-color: ${props => props.theme.themeGreen};
        }
      }
    }
  }
  .ant-tabs {
    overflow: visible;
    .ant-tabs-nav {
      &::before {
        content: unset;
      }
      &-wrap {
        justify-content: center;
        .ant-tabs-nav-list {
          padding: 2px;
          background-color: ${props => props.theme.tradeModalBg};
          border-radius: 4px;
          .ant-tabs-tab {
            margin: unset;
            width: 6em;
            height: 2em;
            display: inline-flex;
            justify-content: center;
            font-size: 12px;
            line-height: 2em;
            &-active {
              font-weight: initial;
              background-color: ${props => props.theme.tradeModalTabActiveBg};
              border-radius: 2px;
            }
          }
        }
      }
      .ant-tabs-tab {
        padding: unset;
        font-size: 13px;
        font-weight: 500;
        color: ${props => props.theme.secondary};
      }
      .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
      .ant-tabs-tab:hover,
      .ant-tabs-tab-btn:focus,
      .ant-tabs-tab-btn:active {
        color: ${props => props.theme.tradeModalTabActiveText};
      }
      /* tab 下方的选中横线 */
      .ant-tabs-ink-bar {
        display: none;
      }
    }
  }
  .otm-form {
    .ant-form-item {
      margin-bottom: 8px;
      .ant-form-item-label {
        .otm-limit-label {
          margin-right: 8px;
        }
        .otm-limit-tip {
          visibility: hidden;
          margin-right: auto;
          color: ${props => props.theme.secondary};
          &.show {
            visibility: visible;
          }
        }
      }
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: flex-end;
      }
      .otm-submit-btn {
        margin: 24px auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 32px;
        color: ${props => props.theme.white};
        background-color: ${props => props.theme.themeRed};
        border-radius: 4px;
        &:hover,
        &:active,
        &-active {
          background-color: ${props => props.theme.themeRedActive};
        }
        &.sell {
          background-color: ${props => props.theme.themeGreen};
          &:hover,
          &:active,
          &-active {
            background-color: ${props => props.theme.themeGreenActive};
          }
        }
      }
    }
  }
  .otm-profit-x {
    margin: 0 -16px 8px;
    padding: 0 16px;
    background-color: ${props => props.theme.tradeModalBg};
    font-size: 12px;
    color: ${props => props.theme.secondary};
    display: none;
    &.active {
      height: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    & > span {
      line-height: 1em;
    }
    & > .otm-profit {
      &.green {
        font-weight: 500;
        color: ${props => props.theme.themeRed};
      }
      &.red {
        font-weight: 500;
        color: ${props => props.theme.themeGreen};
      }
    }
  }
  .otm-desc-ul {
    margin-top: 12px;
    .otm-desc-li {
      height: 32px;
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      .label {
        color: ${props => props.theme.primary};
      }
      .val {
        color: ${props => props.theme.secondary};
      }
    }
  }
`
// 获取债券的维持保证金数据
const getMaintenanceCall = symbol => {
  let call
  switch (symbol) {
    case "@CLE":
    case "@DB":
      call = 1000
      break
    case "@GLE":
      call = 825
      break
    case "@USA":
      call = 500
      break
    case "@ZSE":
      call = 2500
      break
    default:
      call = 0
      break
  }
  return call
}

export const OrderTradeModal = ({ onCancel, symbol, type, ...props }) => {
  const intl = useIntl()
  const info = useSelector(state => selectSymbolByName(state, symbol))
  const {
    name,
    cn_name: cnName,
    spread,
    ask,
    bid,
    group,
    size,
    trans_price_ask: transPriceAsk,
  } = info

  const [symbolInfo, setSymbolInfo] = useState(info?.symbolInfo)
  useEffect(() => {
    const getSymbolInfo = async name => {
      try {
        const { error, data } = await post(apis.getSymbolInfo, { name })
        if (error) {
          throw Error(error)
        }
        setSymbolInfo(data)
      } catch (e) {
        e?.message && message.warning(e.message)
      }
    }
    if (!info?.symbolInfo) {
      getSymbolInfo(name)
      // message.warning(
      //   intl.formatMessage({ id: "modal.orderTrade.message.error.marginNull" })
      // )
    }
  }, [info?.symbolInfo, name])

  const {
    volume_min: volumeMin,
    margin_currency_rate: marginRate,
    margin_divider: marginDivider,
  } = symbolInfo || {}
  const isBuy = cmdConfig.buyIds.includes(type)
  const price = isBuy ? bid : ask
  // 止盈价格边界值
  const tpLimitPrice = isBuy ? ask : bid
  const slLimitPrice = isBuy ? bid : ask
  const extraData = { price, isBuy, tpLimitPrice, slLimitPrice }

  // 获取各品种类别的保证金数据
  const updateMarginValue = (volume = volumeMin, price = bid) => {
    if (!marginRate) {
      message.error(
        intl.formatMessage({ id: "modal.orderTrade.message.error.marginNull" })
      )
      return
    }
    let _margin
    switch (group) {
      case "货币":
        _margin = ((volume * 100000) / 100) * marginRate
        break
      case "债券和国际期货":
        _margin = getMaintenanceCall(symbol) * volume * marginRate
        break
      default:
        _margin = price * volume * size * marginDivider * marginRate
        break
    }
    _margin = toDecimal(_margin, 2)
    return _margin
  }
  const updateProfit = (volume, closePrice, isTp, openPrice = price) => {
    let profit
    closePrice = Number(closePrice) //type === 0 ? tp : sl
    if (!closePrice) {
      profit = DEFAULT_STRING
    } else if (
      (isBuy &&
        ((!isTp && closePrice < openPrice) ||
          (isTp && closePrice > openPrice))) ||
      (!isBuy &&
        ((!isTp && closePrice > openPrice) || (isTp && closePrice < openPrice)))
    ) {
      profit = intl.formatMessage({
        id: "modal.orderTrade.message.error.profitInvalid",
      })
    } else {
      const flag = (-1) ** (Number(isBuy) ^ 1)
      profit = (
        (closePrice - openPrice) *
        volume *
        size *
        transPriceAsk *
        flag
      ).toFixed(2)
    }
    return profit
  }
  // TODO: 后续需要判断 即时成交单 和 挂单的 cmd 值
  const handleSubmit = async (params, cb) => {
    if (!marginRate) {
      message.error(
        intl.formatMessage({ id: "modal.orderTrade.message.error.marginNull" })
      )
      return
    }
    const { error, data } = await post(apis.openOrder, { ...params, symbol })
    cb()
    if (error) {
      notification["error"]({
        message: intl.formatMessage({
          id: "modal.orderTrade.message.error.createOrder.title",
        }),
        description: error,
      })
      return
    }
    notification["success"]({
      message: intl.formatMessage({
        id: "modal.orderTrade.message.success.createOrder.title",
      }),
      description:
        data?.ticket &&
        intl.formatMessage(
          {
            id: "modal.orderTrade.message.success.createOrder.desc",
          },
          {
            ticket: data.ticket,
          }
        ),
    })
    onCancel()
  }

  const TABS = [
    {
      tab: intl.formatMessage({ id: "modal.orderTrade.tabs.tab1" }),
      key: "0",
      content: (
        <MarketPane
          extraData={extraData}
          data={info}
          symbolInfo={symbolInfo}
          updateMarginValue={updateMarginValue}
          updateProfit={updateProfit}
          handleSubmit={handleSubmit}
        />
      ),
    },
    {
      tab: intl.formatMessage({ id: "modal.orderTrade.tabs.tab2" }),
      key: "1",
      content: (
        <LimitPane
          extraData={extraData}
          data={info}
          symbolInfo={symbolInfo}
          updateMarginValue={updateMarginValue}
          updateProfit={updateProfit}
          handleSubmit={handleSubmit}
        />
      ),
    },
  ]

  return (
    <Wrapper
      title={
        <p className="otm-title-x">
          <span>{intl.locale !== enus ? cnName : name}</span>
          <span
            className={classNames("spread", {
              down: Number(spread) < 0,
            })}
          >
            {spread}%
          </span>
        </p>
      }
      width={340}
      footer={null}
      onCancel={onCancel}
      {...props}
    >
      {!!marginRate ? (
        <Tabs defaultActiveKey="0">
          {TABS.map(item => (
            <TabPane tab={item.tab} key={item.key}>
              {item.content}
            </TabPane>
          ))}
        </Tabs>
      ) : (
        <Loading />
      )}
    </Wrapper>
  )
}
