const modal = {
  modal: {
    symbolInfo: {
      mTitle1: "Basic Info",
      mTitle2: "Trading Time",
      weeks: {
        sun: "Sunday",
        mon: "Monday",
        tues: "Tuesday",
        wed: "Wednesday",
        thurs: "Thursday",
        fri: "Friday",
        sat: "Saturday",
      },
    },
    accInfoTip: {
      title: "The Rules of Account Info",
      item1: {
        label: "Present Equity",
        formula: "equity = balance ± profit",
      },
      item2: {
        label: "Free Margin",
        formula: "freeMargin = equity - margin",
      },
      item3: {
        label: "Risk Degree",
        formula: "riskDegree = margin / equity",
      },
      item4: {
        label: "Profit",
        formula: "profit = equity - balance",
      },
    },
    orderEdit: {
      formItem1: {
        label: "TP",
        profitText: "Expected Earnings:",
      },
      formItem2: {
        label: "SL",
        profitText: "Expected Losses:",
      },
      profitError: "Price Wrong",
      descUl: {
        item1: "Ticket",
        item2: "Open Time",
        item3: "Commission",
        item4: "Swap",
      },
      message: {
        error: {
          valNull: "TP or SL are required",
          tpInvalid: "TP Wrong",
          slInvalid: "SL Wrong",
          editFailed: {
            title: "Modify Failed",
            desc: "Failed to modify the ticket {ticket}: {error}",
          },
        },
        success: {
          editSuccess: {
            title: "Modify Successfully",
            desc: "The ticket is {ticket}",
          },
        },
      },
    },
    orderTrade: {
      tabs: {
        tab1: "Market",
        tab2: "Limit",
      },
      market: {
        descItem1: "Present Price",
        descItem2: "Margin",
        formItem1: "Volume",
        formItem2: {
          label: "TP",
          profitText: "Expected Earnings:",
        },
        formItem3: {
          label: "SL",
          profitText: "Expected Losses:",
        },
        submitBtnText: {
          buy: "Buy",
          sell: "Sell",
        },
        message: {
          error: {
            volumeInvalid: "Volume Wrong",
            tpInvalid: "TP Wrong",
            slInvalid: "SL Wrong",
          },
        },
      },
      limit: {
        formItemPrice: "Transaction Price",
        submitBtnText: {
          buyLimit: "Buy Limit",
          sellLimit: "Sell Limit",
          buyStop: "Buy Stop",
          sellStop: "Sell Stop",
        },
        message: {
          error: {
            priceNull: "Transaction price is required",
            volumeInvalid: "Volume Wrong",
            tpInvalid: "TP Wrong",
            slInvalid: "SL Wrong",
          },
        },
      },
      message: {
        success: {
          createOrder: {
            title: "Create Order Successfully",
            desc: "The ticket number is: {ticket}",
          },
        },
        error: {
          marginNull:
            "Failed to get margin of the symbol, please try again later",
          profitInvalid: "Price Wrong",
          createOrder: {
            title: "Failed to Create Order",
          },
        },
      },
    },
    settings: {
      title: "Settings",
      item1: {
        label: "Order Close Confirm",
        tooltip:
          "If the option is enabled, user confirmation is required before closing open positions.",
      },
      more: "Waiting for more custom configurations...",
    },
  },
}

export default modal
