export const IconFont = ({ className, extraClassName = "", ...props }) => {
  return (
    <svg
      className={`icon ${className} ${extraClassName}`}
      aria-hidden="true"
      {...props}
    >
      <use xlinkHref={`#${className}`}></use>
    </svg>
  )
}
