import styled from "styled-components"

const Wrapper = styled.div`
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    background-color: lightgray;
  }
  .container {
    width: 100%;
    margin-top: 74px;
    overflow-y: auto;
    .layout {
      width: 1856px;
      margin: 0 auto;
      display: flex;

      aside {
        width: 208px;
        flex: none;
        .sidebar {
          position: fixed;
          width: 208px;
          max-height: 100%;
          overflow-y: auto;
          background-color: lightcoral;
        }
      }
      section {
        margin-left: 24px;
        background-color: lightblue;
      }
    }
  }
`

const SettingsPage = () => {
  return (
    <Wrapper>
      {/* <div className="header"></div>
      <div className="container">
        <div className="layout">
          <aside>
            <div className="sidebar">
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
              <li>123</li>
            </div>
          </aside>
          <section>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Tempore
            sunt natus voluptate dolorem recusandae esse repellat. Suscipit id
            ipsum, eveniet eligendi magnam quam fugit, ad magni quisquam laborum
            doloremque quo. A autem tenetur quam dolorem dignissimos iure
            assumenda cupiditate quisquam officiis rerum! Harum maiores eum
            voluptatum, reprehenderit provident quam ducimus qui, iusto placeat
            cupiditate quos laboriosam nobis numquam vero molestiae? Quibusdam
            temporibus unde esse? Hic maxime, dolore tempore nobis autem animi
            illum illo voluptatum quas, possimus est veritatis necessitatibus
            labore ex quod earum tempora iste. Assumenda tempore mollitia ut
            repellat. Pariatur asperiores odio unde sint? Autem neque quas et
            mollitia aspernatur voluptatibus odit hic voluptatum laudantium
            error, distinctio odio molestias perspiciatis assumenda eaque
            obcaecati est. Vel aut id tempora deleniti? Laboriosam sed dolor
            officiis incidunt inventore neque recusandae, eum consequuntur
            possimus assumenda error itaque explicabo tenetur repellendus
            accusamus voluptatum eaque hic non magnam. Quidem facilis
            voluptatum, mollitia eaque quam officia. Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Cupiditate aut vel eum eveniet cum ea
            voluptas iusto eius, aliquam libero ipsum in voluptate modi
            provident! Optio similique sunt dolor voluptate? Incidunt nam nihil
            doloremque aliquam maiores. Totam cumque exercitationem natus saepe
            libero inventore at dignissimos facilis adipisci delectus, sed
            voluptas temporibus in maxime voluptate? Quisquam, labore!
            Inventore, neque molestiae. Beatae! Architecto, est atque. Minus
            eaque vero officiis doloremque laudantium eveniet nihil omnis
            exercitationem quos voluptates quasi, facilis optio nemo numquam
            ullam. Doloribus ipsam aspernatur iste dolore ad quod quaerat ab.
            Labore itaque nisi blanditiis ducimus dolor ullam, quae fugiat,
            laboriosam molestias omnis deleniti? Dignissimos consequuntur,
            numquam laudantium voluptate nesciunt, necessitatibus sequi suscipit
            distinctio ut, fugit quidem minima unde aut eum. Architecto illum
            quod, sed obcaecati omnis sint fugiat odit a consectetur similique
            officiis laudantium odio velit doloremque cum debitis. Ab cum nemo
            velit similique officia eveniet, obcaecati molestiae maiores
            blanditiis? Ad ea eligendi repellendus culpa fugit, soluta maiores
            deserunt, corporis illum possimus voluptas neque quas repellat. Sed
            deleniti minus quod, neque officiis veritatis vero itaque reiciendis
            dolorem libero reprehenderit aliquam! Sapiente nemo animi velit
            debitis commodi voluptatibus nulla deserunt illo, eos qui, suscipit
            laborum assumenda? Eligendi nulla ratione autem rerum harum aperiam
            impedit, quasi, voluptas nesciunt maxime necessitatibus excepturi
            porro. Id ullam aut voluptatum ducimus optio vero rem, eum explicabo
            earum debitis numquam quo animi voluptates iste reprehenderit. Quae
            doloribus totam odit officia ipsam eveniet corrupti sunt impedit hic
            facere? Animi minus repellat accusamus, rem aliquid ea, veniam
            error, iste qui molestias voluptatibus numquam officia placeat!
            Corporis, corrupti dolorum, asperiores architecto atque labore
            veritatis quos explicabo impedit ex expedita velit! Est libero
            fugiat quisquam. Quo cumque voluptates fugit ipsa! Harum porro
            necessitatibus voluptates suscipit illum, atque labore, fuga non rem
            vel fugit accusantium dignissimos consectetur incidunt doloribus!
            Eos, atque ipsa? Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Tempore sunt natus voluptate dolorem recusandae esse repellat.
            Suscipit id ipsum, eveniet eligendi magnam quam fugit, ad magni
            quisquam laborum doloremque quo. A autem tenetur quam dolorem
            dignissimos iure assumenda cupiditate quisquam officiis rerum! Harum
            maiores eum voluptatum, reprehenderit provident quam ducimus qui,
            iusto placeat cupiditate quos laboriosam nobis numquam vero
            molestiae? Quibusdam temporibus unde esse? Hic maxime, dolore
            tempore nobis autem animi illum illo voluptatum quas, possimus est
            veritatis necessitatibus labore ex quod earum tempora iste.
            Assumenda tempore mollitia ut repellat. Pariatur asperiores odio
            unde sint? Autem neque quas et mollitia aspernatur voluptatibus odit
            hic voluptatum laudantium error, distinctio odio molestias
            perspiciatis assumenda eaque obcaecati est. Vel aut id tempora
            deleniti? Laboriosam sed dolor officiis incidunt inventore neque
            recusandae, eum consequuntur possimus assumenda error itaque
            explicabo tenetur repellendus accusamus voluptatum eaque hic non
            magnam. Quidem facilis voluptatum, mollitia eaque quam officia.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate
            aut vel eum eveniet cum ea voluptas iusto eius, aliquam libero ipsum
            in voluptate modi provident! Optio similique sunt dolor voluptate?
            Incidunt nam nihil doloremque aliquam maiores. Totam cumque
            exercitationem natus saepe libero inventore at dignissimos facilis
            adipisci delectus, sed voluptas temporibus in maxime voluptate?
            Quisquam, labore! Inventore, neque molestiae. Beatae! Architecto,
            est atque. Minus eaque vero officiis doloremque laudantium eveniet
            nihil omnis exercitationem quos voluptates quasi, facilis optio nemo
            numquam ullam. Doloribus ipsam aspernatur iste dolore ad quod
            quaerat ab. Labore itaque nisi blanditiis ducimus dolor ullam, quae
            fugiat, laboriosam molestias omnis deleniti? Dignissimos
            consequuntur, numquam laudantium voluptate nesciunt, necessitatibus
            sequi suscipit distinctio ut, fugit quidem minima unde aut eum.
            Architecto illum quod, sed obcaecati omnis sint fugiat odit a
            consectetur similique officiis laudantium odio velit doloremque cum
            debitis. Ab cum nemo velit similique officia eveniet, obcaecati
            molestiae maiores blanditiis? Ad ea eligendi repellendus culpa
            fugit, soluta maiores deserunt, corporis illum possimus voluptas
            neque quas repellat. Sed deleniti minus quod, neque officiis
            veritatis vero itaque reiciendis dolorem libero reprehenderit
            aliquam! Sapiente nemo animi velit debitis commodi voluptatibus
            nulla deserunt illo, eos qui, suscipit laborum assumenda? Eligendi
            nulla ratione autem rerum harum aperiam impedit, quasi, voluptas
            nesciunt maxime necessitatibus excepturi porro. Id ullam aut
            voluptatum ducimus optio vero rem, eum explicabo earum debitis
            numquam quo animi voluptates iste reprehenderit. Quae doloribus
            totam odit officia ipsam eveniet corrupti sunt impedit hic facere?
            Animi minus repellat accusamus, rem aliquid ea, veniam error, iste
            qui molestias voluptatibus numquam officia placeat! Corporis,
            corrupti dolorum, asperiores architecto atque labore veritatis quos
            explicabo impedit ex expedita velit! Est libero fugiat quisquam. Quo
            cumque voluptates fugit ipsa! Harum porro necessitatibus voluptates
            suscipit illum, atque labore, fuga non rem vel fugit accusantium
            dignissimos consectetur incidunt doloribus! Eos, atque ipsa? Lorem,
            ipsum dolor sit amet consectetur adipisicing elit. Tempore sunt
            natus voluptate dolorem recusandae esse repellat. Suscipit id ipsum,
            eveniet eligendi magnam quam fugit, ad magni quisquam laborum
            doloremque quo. A autem tenetur quam dolorem dignissimos iure
            assumenda cupiditate quisquam officiis rerum! Harum maiores eum
            voluptatum, reprehenderit provident quam ducimus qui, iusto placeat
            cupiditate quos laboriosam nobis numquam vero molestiae? Quibusdam
            temporibus unde esse? Hic maxime, dolore tempore nobis autem animi
            illum illo voluptatum quas, possimus est veritatis necessitatibus
            labore ex quod earum tempora iste. Assumenda tempore mollitia ut
            repellat. Pariatur asperiores odio unde sint? Autem neque quas et
            mollitia aspernatur voluptatibus odit hic voluptatum laudantium
            error, distinctio odio molestias perspiciatis assumenda eaque
            obcaecati est. Vel aut id tempora deleniti? Laboriosam sed dolor
            officiis incidunt inventore neque recusandae, eum consequuntur
            possimus assumenda error itaque explicabo tenetur repellendus
            accusamus voluptatum eaque hic non magnam. Quidem facilis
            voluptatum, mollitia eaque quam officia. Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Cupiditate aut vel eum eveniet cum ea
            voluptas iusto eius, aliquam libero ipsum in voluptate modi
            provident! Optio similique sunt dolor voluptate? Incidunt nam nihil
            doloremque aliquam maiores. Totam cumque exercitationem natus saepe
            libero inventore at dignissimos facilis adipisci delectus, sed
            voluptas temporibus in maxime voluptate? Quisquam, labore!
            Inventore, neque molestiae. Beatae! Architecto, est atque. Minus
            eaque vero officiis doloremque laudantium eveniet nihil omnis
            exercitationem quos voluptates quasi, facilis optio nemo numquam
            ullam. Doloribus ipsam aspernatur iste dolore ad quod quaerat ab.
            Labore itaque nisi blanditiis ducimus dolor ullam, quae fugiat,
            laboriosam molestias omnis deleniti? Dignissimos consequuntur,
            numquam laudantium voluptate nesciunt, necessitatibus sequi suscipit
            distinctio ut, fugit quidem minima unde aut eum. Architecto illum
            quod, sed obcaecati omnis sint fugiat odit a consectetur similique
            officiis laudantium odio velit doloremque cum debitis. Ab cum nemo
            velit similique officia eveniet, obcaecati molestiae maiores
            blanditiis? Ad ea eligendi repellendus culpa fugit, soluta maiores
            deserunt, corporis illum possimus voluptas neque quas repellat. Sed
            deleniti minus quod, neque officiis veritatis vero itaque reiciendis
            dolorem libero reprehenderit aliquam! Sapiente nemo animi velit
            debitis commodi voluptatibus nulla deserunt illo, eos qui, suscipit
            laborum assumenda? Eligendi nulla ratione autem rerum harum aperiam
            impedit, quasi, voluptas nesciunt maxime necessitatibus excepturi
            porro. Id ullam aut voluptatum ducimus optio vero rem, eum explicabo
            earum debitis numquam quo animi voluptates iste reprehenderit. Quae
            doloribus totam odit officia ipsam eveniet corrupti sunt impedit hic
            facere? Animi minus repellat accusamus, rem aliquid ea, veniam
            error, iste qui molestias voluptatibus numquam officia placeat!
            Corporis, corrupti dolorum, asperiores architecto atque labore
            veritatis quos explicabo impedit ex expedita velit! Est libero
            fugiat quisquam. Quo cumque voluptates fugit ipsa! Harum porro
            necessitatibus voluptates suscipit illum, atque labore, fuga non rem
            vel fugit accusantium dignissimos consectetur incidunt doloribus!
            Eos, atque ipsa? Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Tempore sunt natus voluptate dolorem recusandae esse repellat.
            Suscipit id ipsum, eveniet eligendi magnam quam fugit, ad magni
            quisquam laborum doloremque quo. A autem tenetur quam dolorem
            dignissimos iure assumenda cupiditate quisquam officiis rerum! Harum
            maiores eum voluptatum, reprehenderit provident quam ducimus qui,
            iusto placeat cupiditate quos laboriosam nobis numquam vero
            molestiae? Quibusdam temporibus unde esse? Hic maxime, dolore
            tempore nobis autem animi illum illo voluptatum quas, possimus est
            veritatis necessitatibus labore ex quod earum tempora iste.
            Assumenda tempore mollitia ut repellat. Pariatur asperiores odio
            unde sint? Autem neque quas et mollitia aspernatur voluptatibus odit
            hic voluptatum laudantium error, distinctio odio molestias
            perspiciatis assumenda eaque obcaecati est. Vel aut id tempora
            deleniti? Laboriosam sed dolor officiis incidunt inventore neque
            recusandae, eum consequuntur possimus assumenda error itaque
            explicabo tenetur repellendus accusamus voluptatum eaque hic non
            magnam. Quidem facilis voluptatum, mollitia eaque quam officia.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate
            aut vel eum eveniet cum ea voluptas iusto eius, aliquam libero ipsum
            in voluptate modi provident! Optio similique sunt dolor voluptate?
            Incidunt nam nihil doloremque aliquam maiores. Totam cumque
            exercitationem natus saepe libero inventore at dignissimos facilis
            adipisci delectus, sed voluptas temporibus in maxime voluptate?
            Quisquam, labore! Inventore, neque molestiae. Beatae! Architecto,
            est atque. Minus eaque vero officiis doloremque laudantium eveniet
            nihil omnis exercitationem quos voluptates quasi, facilis optio nemo
            numquam ullam. Doloribus ipsam aspernatur iste dolore ad quod
            quaerat ab. Labore itaque nisi blanditiis ducimus dolor ullam, quae
            fugiat, laboriosam molestias omnis deleniti? Dignissimos
            consequuntur, numquam laudantium voluptate nesciunt, necessitatibus
            sequi suscipit distinctio ut, fugit quidem minima unde aut eum.
            Architecto illum quod, sed obcaecati omnis sint fugiat odit a
            consectetur similique officiis laudantium odio velit doloremque cum
            debitis. Ab cum nemo velit similique officia eveniet, obcaecati
            molestiae maiores blanditiis? Ad ea eligendi repellendus culpa
            fugit, soluta maiores deserunt, corporis illum possimus voluptas
            neque quas repellat. Sed deleniti minus quod, neque officiis
            veritatis vero itaque reiciendis dolorem libero reprehenderit
            aliquam! Sapiente nemo animi velit debitis commodi voluptatibus
            nulla deserunt illo, eos qui, suscipit laborum assumenda? Eligendi
            nulla ratione autem rerum harum aperiam impedit, quasi, voluptas
            nesciunt maxime necessitatibus excepturi porro. Id ullam aut
            voluptatum ducimus optio vero rem, eum explicabo earum debitis
            numquam quo animi voluptates iste reprehenderit. Quae doloribus
            totam odit officia ipsam eveniet corrupti sunt impedit hic facere?
            Animi minus repellat accusamus, rem aliquid ea, veniam error, iste
            qui molestias voluptatibus numquam officia placeat! Corporis,
            corrupti dolorum, asperiores architecto atque labore veritatis quos
            explicabo impedit ex expedita velit! Est libero fugiat quisquam. Quo
            cumque voluptates fugit ipsa! Harum porro necessitatibus voluptates
            suscipit illum, atque labore, fuga non rem vel fugit accusantium
            dignissimos consectetur incidunt doloribus! Eos, atque ipsa? Lorem,
            ipsum dolor sit amet consectetur adipisicing elit. Tempore sunt
            natus voluptate dolorem recusandae esse repellat. Suscipit id ipsum,
            eveniet eligendi magnam quam fugit, ad magni quisquam laborum
            doloremque quo. A autem tenetur quam dolorem dignissimos iure
            assumenda cupiditate quisquam officiis rerum! Harum maiores eum
            voluptatum, reprehenderit provident quam ducimus qui, iusto placeat
            cupiditate quos laboriosam nobis numquam vero molestiae? Quibusdam
            temporibus unde esse? Hic maxime, dolore tempore nobis autem animi
            illum illo voluptatum quas, possimus est veritatis necessitatibus
            labore ex quod earum tempora iste. Assumenda tempore mollitia ut
            repellat. Pariatur asperiores odio unde sint? Autem neque quas et
            mollitia aspernatur voluptatibus odit hic voluptatum laudantium
            error, distinctio odio molestias perspiciatis assumenda eaque
            obcaecati est. Vel aut id tempora deleniti? Laboriosam sed dolor
            officiis incidunt inventore neque recusandae, eum consequuntur
            possimus assumenda error itaque explicabo tenetur repellendus
            accusamus voluptatum eaque hic non magnam. Quidem facilis
            voluptatum, mollitia eaque quam officia. Lorem ipsum dolor sit amet
            consectetur adipisicing elit. Cupiditate aut vel eum eveniet cum ea
            voluptas iusto eius, aliquam libero ipsum in voluptate modi
            provident! Optio similique sunt dolor voluptate? Incidunt nam nihil
            doloremque aliquam maiores. Totam cumque exercitationem natus saepe
            libero inventore at dignissimos facilis adipisci delectus, sed
            voluptas temporibus in maxime voluptate? Quisquam, labore!
            Inventore, neque molestiae. Beatae! Architecto, est atque. Minus
            eaque vero officiis doloremque laudantium eveniet nihil omnis
            exercitationem quos voluptates quasi, facilis optio nemo numquam
            ullam. Doloribus ipsam aspernatur iste dolore ad quod quaerat ab.
            Labore itaque nisi blanditiis ducimus dolor ullam, quae fugiat,
            laboriosam molestias omnis deleniti? Dignissimos consequuntur,
            numquam laudantium voluptate nesciunt, necessitatibus sequi suscipit
            distinctio ut, fugit quidem minima unde aut eum. Architecto illum
            quod, sed obcaecati omnis sint fugiat odit a consectetur similique
            officiis laudantium odio velit doloremque cum debitis. Ab cum nemo
            velit similique officia eveniet, obcaecati molestiae maiores
            blanditiis? Ad ea eligendi repellendus culpa fugit, soluta maiores
            deserunt, corporis illum possimus voluptas neque quas repellat. Sed
            deleniti minus quod, neque officiis veritatis vero itaque reiciendis
            dolorem libero reprehenderit aliquam! Sapiente nemo animi velit
            debitis commodi voluptatibus nulla deserunt illo, eos qui, suscipit
            laborum assumenda? Eligendi nulla ratione autem rerum harum aperiam
            impedit, quasi, voluptas nesciunt maxime necessitatibus excepturi
            porro. Id ullam aut voluptatum ducimus optio vero rem, eum explicabo
            earum debitis numquam quo animi voluptates iste reprehenderit. Quae
            doloribus totam odit officia ipsam eveniet corrupti sunt impedit hic
            facere? Animi minus repellat accusamus, rem aliquid ea, veniam
            error, iste qui molestias voluptatibus numquam officia placeat!
            Corporis, corrupti dolorum, asperiores architecto atque labore
            veritatis quos explicabo impedit ex expedita velit! Est libero
            fugiat quisquam. Quo cumque voluptates fugit ipsa! Harum porro
            necessitatibus voluptates suscipit illum, atque labore, fuga non rem
            vel fugit accusantium dignissimos consectetur incidunt doloribus!
            Eos, atque ipsa? Lorem, ipsum dolor sit amet consectetur adipisicing
            elit. Tempore sunt natus voluptate dolorem recusandae esse repellat.
            Suscipit id ipsum, eveniet eligendi magnam quam fugit, ad magni
            quisquam laborum doloremque quo. A autem tenetur quam dolorem
            dignissimos iure assumenda cupiditate quisquam officiis rerum! Harum
            maiores eum voluptatum, reprehenderit provident quam ducimus qui,
            iusto placeat cupiditate quos laboriosam nobis numquam vero
            molestiae? Quibusdam temporibus unde esse? Hic maxime, dolore
            tempore nobis autem animi illum illo voluptatum quas, possimus est
            veritatis necessitatibus labore ex quod earum tempora iste.
            Assumenda tempore mollitia ut repellat. Pariatur asperiores odio
            unde sint? Autem neque quas et mollitia aspernatur voluptatibus odit
            hic voluptatum laudantium error, distinctio odio molestias
            perspiciatis assumenda eaque obcaecati est. Vel aut id tempora
            deleniti? Laboriosam sed dolor officiis incidunt inventore neque
            recusandae, eum consequuntur possimus assumenda error itaque
            explicabo tenetur repellendus accusamus voluptatum eaque hic non
            magnam. Quidem facilis voluptatum, mollitia eaque quam officia.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate
            aut vel eum eveniet cum ea voluptas iusto eius, aliquam libero ipsum
            in voluptate modi provident! Optio similique sunt dolor voluptate?
            Incidunt nam nihil doloremque aliquam maiores. Totam cumque
            exercitationem natus saepe libero inventore at dignissimos facilis
            adipisci delectus, sed voluptas temporibus in maxime voluptate?
            Quisquam, labore! Inventore, neque molestiae. Beatae! Architecto,
            est atque. Minus eaque vero officiis doloremque laudantium eveniet
            nihil omnis exercitationem quos voluptates quasi, facilis optio nemo
            numquam ullam. Doloribus ipsam aspernatur iste dolore ad quod
            quaerat ab. Labore itaque nisi blanditiis ducimus dolor ullam, quae
            fugiat, laboriosam molestias omnis deleniti? Dignissimos
            consequuntur, numquam laudantium voluptate nesciunt, necessitatibus
            sequi suscipit distinctio ut, fugit quidem minima unde aut eum.
            Architecto illum quod, sed obcaecati omnis sint fugiat odit a
            consectetur similique officiis laudantium odio velit doloremque cum
            debitis. Ab cum nemo velit similique officia eveniet, obcaecati
            molestiae maiores blanditiis? Ad ea eligendi repellendus culpa
            fugit, soluta maiores deserunt, corporis illum possimus voluptas
            neque quas repellat. Sed deleniti minus quod, neque officiis
            veritatis vero itaque reiciendis dolorem libero reprehenderit
            aliquam! Sapiente nemo animi velit debitis commodi voluptatibus
            nulla deserunt illo, eos qui, suscipit laborum assumenda? Eligendi
            nulla ratione autem rerum harum aperiam impedit, quasi, voluptas
            nesciunt maxime necessitatibus excepturi porro. Id ullam aut
            voluptatum ducimus optio vero rem, eum explicabo earum debitis
            numquam quo animi voluptates iste reprehenderit. Quae doloribus
            totam odit officia ipsam eveniet corrupti sunt impedit hic facere?
            Animi minus repellat accusamus, rem aliquid ea, veniam error, iste
            qui molestias voluptatibus numquam officia placeat! Corporis,
            corrupti dolorum, asperiores architecto atque labore veritatis quos
            explicabo impedit ex expedita velit! Est libero fugiat quisquam. Quo
            cumque voluptates fugit ipsa! Harum porro necessitatibus voluptates
            suscipit illum, atque labore, fuga non rem vel fugit accusantium
            dignissimos consectetur incidunt doloribus! Eos, atque ipsa?
          </section>
        </div>
      </div> */}
    </Wrapper>
  )
}

export default SettingsPage
