import {
  SOCKET_INIT,
  SOCKET_CLOSE,
  SOCKET_DESTROY,
  SOCKET_SEND,
  SOCKET_SUBSCRIBE,
  SOCKET_SUBSCRIBEONCE,
  SOCKET_TYPES,
  SOCKET_UNSUBSCRIBE,
} from "./socketActions"

export default function socketMiddleware(socket) {
  // Socket param is the client. We'll show how to set this up later.
  return ({ dispatch, getState }) =>
    next =>
    action => {
      if (typeof action === "function") {
        return action(dispatch, getState)
      }

      /*
       * Socket middleware usage.
       * promise: (socket) => socket.emit('MESSAGE', 'hello world!')
       * type: always 'socket'
       * types: [REQUEST, SUCCESS, FAILURE]
       */
      const { type, payload } = action
      const isSocket = type.split("/")[0] === "socket"
      if (!isSocket) return next(action)
      if (!SOCKET_TYPES.includes(type)) {
        console.log("socket 参数错误")
        return
      }
      switch (type) {
        case SOCKET_INIT:
          const { url, openCb } = payload
          if (!socket.url) {
            // 第一次创建连接
            socket.changeSocketOpts(url, openCb)
            socket.initSocket()
          } else if (!!socket.url && socket.url !== url) {
            // 切换账户
            socket.destroy()
            socket.changeSocketOpts(url)
            socket.initSocket()
          } else {
            // 恢复页面，自动重连
            // message.error("页面连接被断开，正在重新连接...")
            socket.start()
          }
          break
        case SOCKET_SEND:
          socket.send(payload)
          break
        // case SOCKET_START:
        //   socket.start()
        //   break
        case SOCKET_CLOSE:
          socket.close()
          break
        case SOCKET_DESTROY:
          socket.destroy()
          break
        case SOCKET_SUBSCRIBE:
          socket.subscribe(payload.evtName, payload.cb)
          break
        case SOCKET_UNSUBSCRIBE:
          socket.unsubscribe(payload.evtName, payload.cb)
          break
        case SOCKET_SUBSCRIBEONCE:
          socket.subscribeOnce(payload.evtName, payload.cb)
          break
        default:
          break
      }
    }
}
