import { Button, Dropdown, Menu } from "antd"
import { useDispatch, useSelector } from "react-redux"
import config from "../../config/config"
import strings from "../../constants/strings"
import { selectCustomItem, setCustomItem } from "../../store/customSlice"
import { IconFont } from "./IconFont"

const { locales } = config

// NOTE: 语言切换组件（用于 主界面 和 登录页 的语言环境切换）
export const LocaleChange = ({ onChange = null }) => {
  const dispatch = useDispatch()
  const locale = useSelector(state =>
    selectCustomItem(state, strings.customConfigKeyLocale)
  )
  const localeArr = Object.entries(locales.entities).map(([key, val]) => ({
    key,
    abbr: val.abbr,
    name: val.name,
  }))
  // 语言切换菜单项
  const localeOverlay = (
    <Menu
      onClick={val => {
        onChange && onChange()
        dispatch(
          setCustomItem({
            key: strings.customConfigKeyLocale,
            value: val.key,
          })
        )
      }}
      selectedKeys={[locale]}
    >
      {localeArr.map(item => (
        <Menu.Item key={item.key}>
          <sub className="abbr">{item.abbr}</sub>
          {item.name}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={localeOverlay} overlayClassName="home-dropdown-locale">
      <Button className="widget-li btn-locale no-trigger initial">
        <IconFont className="icon-locale" />
        <span className="name">{locales.entities[locale]?.name}</span>
      </Button>
    </Dropdown>
  )
}
