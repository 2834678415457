const home = {
  home: {
    quotePane: {
      title: "品種報價",
      tabs: {
        favorite: "自選",
        inland: "國內期貨",
        intl: "國際市場",
      },
      table: {
        ths: {
          th1: "品種",
          th2: "漲跌幅",
          th3: "賣",
          th4: "買",
        },
        tr: {
          sell: "做空",
          buy: "做多",
          high: "最高價",
          low: "最低價",
        },
      },
      message: {
        error: {
          marginNull: "交易產品保證金信息獲取失敗，請重試",
          transactFailed: "下單失敗",
        },
        success: {
          transactSuccess: {
            title: "下單成功",
            desc: "成功交易 {desc}手",
          },
        },
      },
      tooltip: {
        favoriteTab: "僅顯示當前賬戶下能交易的商品",
      },
    },
    chartsPane: {
      title: "圖表",
    },
    calendarPane: {
      title: "財經日曆",
      dateRadios: {
        label1: "昨天",
        label2: "今天",
        label3: "明天",
      },
      ecoTable: {
        title: "經濟數據",
        ths: {
          th1: "時間",
          th2: "指標名稱",
          th3: "重要性",
          th4: "公佈值",
          th5: "預測值",
          th6: "前值",
          th7: "數據解讀",
        },
        detailBtn: "詳情",
      },
      eventTable: {
        title: "經濟事件",
        ths: {
          th1: "時間",
          th2: "國家",
          th3: "重要性",
          th4: "事件",
        },
        undetermined: "待定",
      },
      filterPopover: {
        btnText: "篩選",
        title: "國家/地區",
        countries: {
          all: "全部",
          America: "美國",
          Canada: "加拿大",
          Eurozone: "歐元區",
          Germany: "德國",
          England: "英國",
          France: "法國",
          Switzerland: "瑞士",
          Italy: "意大利",
          Spain: "西班牙",
          China: "中國",
          Japan: "日本",
          Korea: "韓國",
          India: "印度",
          HK: "中國香港",
          TW: "中國台灣",
          Singapore: "新加坡",
          Australia: "澳大利亞",
          NewZealand: "新西蘭",
        },
        submitBtnText: "確定",
      },
      modal: {
        infos: {
          item1: "最新發佈",
          item2: "公佈值",
          item3: "預測值",
          item4: "前值",
          item5: "下次公佈時間",
          item6: "重要性",
          noPub: "未公佈",
        },
        subTitle2: "歷史數據",
        subTitle3: "往期數據",
        table: {
          thead1: "日期",
          thead2: "前值",
          thead3: "預測值",
          thead4: "公佈值",
        },
        error: {
          getData: "獲取歷史數據失敗",
        },
      },
    },
    trWidgets: {
      menu: {
        settings: "設置",
        signOut: "退出登錄",
      },
    },
    orderPane: {
      tabs: {
        tab1: "持倉單",
        tab2: "掛單交易",
        tab3: "歷史訂單",
      },
      market: {
        ths: {
          th1: "品種",
          th2: "手數",
          th3: "方向",
          th4: "開倉價/即時價",
          th5: "開倉時間",
          th6: "止盈",
          th7: "止損",
          th8: "隔夜利息",
          th9: "盈利",
          th10: {
            btnText: "平倉",
            ddItem1: "對所有頭寸進行平倉",
            ddItem2: "對盈利頭寸進行平倉",
            ddItem3: "對虧損頭寸進行平倉",
          },
        },
        closeText: "平倉",
        message: {
          success: {
            closeSuccess: "{closeText}成功",
            closeSuccessDesc: "訂單{tickets}{closeText}成功",
          },
          errorPartially: {
            title: "訂單部分{closeText}失敗",
            desc: "失敗訂單編號：{failedTickets}，失敗原因依次為：{failedReason}",
          },
          error: {
            noTickets: "暫無相關訂單",
            closeFailed: "{closeText}失敗",
          },
        },
        confirm: {
          title: "確定{closeText}下列訂單號的訂單？",
          okText: "確定",
          cancelText: "取消",
        },
      },
      limit: {
        ths: {
          th4: "掛單價/即時價",
          th10: "全部撤銷",
        },
        closeText: "撤銷",
      },
      history: {
        picker: {
          placeholder: {
            startText: "開始日期",
            endText: "結束日期",
          },
        },
        ths: {
          th1: "品種",
          th2: "手數",
          th3: "方向",
          th4: "開倉價/平倉價",
          th5: "開倉時間",
          th6: "平倉時間",
          th7: "止盈",
          th8: "止損",
          th9: "隔夜利息",
          th10: "盈利",
          th11: "訂單號", // 仅用于导出 excel
        },
        exportBtn: "導出訂單數據",
      },
    },
    accountPane: {
      labels: {
        label1: "當前權益",
        label2: "可用資金",
        label3: "風險度",
        label4: {
          market: "持倉盈虧",
          history: "歷史盈虧",
        },
      },
    },
  },
}
export default home
