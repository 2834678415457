import { Modal } from "antd"
import styled from "styled-components"

const GlobalModalWrapper = styled(Modal)`
  .ant-modal-content {
    min-height: 60px;
    border-radius: 4px;
    overflow: hidden; /* TEMP: */
    background-color: ${props => props.theme.modalBg};
    .ant-modal-close {
      margin: 16px;
      width: 28px;
      height: 28px;
      color: ${props => props.theme.btnText};
      display: ${props => (props.noBtnClose ? "none" : "block")};
      &:hover {
        color: ${props => props.theme.theme};
      }
      .ant-modal-close-x {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .ant-modal-header {
      height: 60px;
      border: 0;
      background-color: transparent;
      .ant-modal-title {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        color: ${props => props.theme.title};
      }
    }
    .ant-modal-body {
      padding: 16px;
      padding-top: 0;
    }
    .ant-modal-footer {
      text-align: center;
      border-color: ${props => props.theme.border};
      .ant-btn {
        height: 30px;
        padding: 0 12px;
        background-color: transparent;
        border-color: ${props => props.theme.border};
        color: ${props => props.theme.secondary};
        &:not(.ant-btn-primary):hover {
          border-color: ${props => props.theme.theme};
          color: ${props => props.theme.theme};
        }
      }
      .ant-btn-primary {
        margin-left: 16px;
        background-color: ${props => props.theme.theme};
        color: ${props => props.theme.white};
        border-color: ${props => props.theme.theme};
      }
    }
  }
`

/** 全局通用弹窗
 * 默认允许 键盘esc / 点击蒙层 关闭弹窗
 * @param {Bollean} visible modal显示状态
 * @param {String} title modal 标题
 * @param {Number} width modal 宽度
 * @param {Function} onCancel modal 关闭方法
 */
const GlobalModal = ({
  title = "",
  width = 520,
  visible = false,
  children,
  onCancel = null,
  ...props
}) => {
  return (
    <GlobalModalWrapper
      visible={visible}
      title={title}
      onCancel={onCancel}
      width={width}
      centered={true} // 居中展示弹窗
      {...props}
    >
      {children}
    </GlobalModalWrapper>
  )
}

export { GlobalModal as default, GlobalModalWrapper }
