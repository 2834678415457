import strings from "../constants/strings"

const config = {
  baseUrl: {
    1: "https://demotrade.alphazone-data.cn",
    2: "https://livetrade.alphazone-data.cn",
  },
  wsUrl: {
    1: "wss://demotrade.alphazone-data.cn/ws",
    2: "wss://livetrade.alphazone-data.cn/ws",
  },
  hotSignResourceHost: "https://www.huosign.com",
  // hotSignHost: "https://demotrade.alphazone-data.cn", // 猛哥测试服
  hotSignHost: "https://www.huosign.com", // 正式服
  // hotSignHost: "http://localhost:3000", // 本地
  defaultString: "--",
  // klineDefaultData: {
  //   EURUSD: {
  //     symbol: "EURUSD",
  //     cnName: "欧元兑美元",
  //     resolution: "1D",
  //   },
  //   GBPUSD: {
  //     symbol: "GBPUSD",
  //     cnName: "英镑兑美元",
  //     resolution: "1D",
  //   },
  // },
  fetchStatus: ["idle", "loading", "succeeded", "failed"],
  cmdConfig: {
    buyIds: [0, 2, 4],
    sellIds: [1, 3, 5],
    positionIds: [0, 1],
    limitIds: [2, 3, 4, 5],
    entities: {
      0: "Buy",
      1: "Sell",
      2: "Buy Limit",
      3: "Sell Limit",
      4: "Buy Stop",
      5: "Sell Stop",
    },
  },
  customDefaultConfig: {
    [strings.customConfigKeyTheme]: "light",
    [strings.customConfigKeyLocale]: "zh-CN",
    [strings.customConfigKeyCloseOrderConfirm]: true,
    [strings.customConfigKeyExternalSymbol]: "",
  },
  locales: {
    // WARNING: 不要更改 ids 和 entities 内部数据结构的顺序
    ids: ["zh-CN", "zh-HK", "en-US"],
    entities: {
      "zh-CN": {
        abbr: "CN",
        name: "简体中文",
      },
      "zh-HK": {
        abbr: "HK",
        name: "繁體中文",
      },
      "en-US": {
        abbr: "US",
        name: "English",
      },
    },
  },
  thirdPartProjectNames: {
    hotsignPro: "hotsignPro",
  },
}
export default config
