import { FormattedMessage } from "react-intl"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { selectFavorites } from "../../../store/symbolSlice"
import { Empty } from "../../common/Empty"
import SymbolItem from "./SymbolItem"

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .title-ul {
    display: flex;
    .title-li {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 24px;
      color: ${props => props.theme.secondary};
      &:nth-child(1) {
        width: 36%;
        padding-left: 12px;
        justify-content: flex-start;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        flex: 1;
      }
      &:nth-child(4) {
        flex: 1;
      }
      &:nth-child(n + 2) {
        position: relative;
        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          content: "";
          width: 1px;
          height: 60%;
          background-color: ${props => props.theme.border};
        }
      }
    }
  }
  .content-x {
    padding-top: 8px;
    flex: 1 0 auto;
    height: 0;
    overflow-y: auto;
    & > .loading {
      height: 100%;
      margin: 0;
    }
    /* 关闭滚动条 */
    &::-webkit-scrollbar {
      display: none;
    }
    /* 折叠面板样式覆盖 */
    .ant-collapse {
      background-color: unset;
      & > .ant-collapse-item {
        border-bottom: 0;
        .ant-collapse-header {
          padding: 8px;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: ${props => props.theme.primary};
          background-color: ${props => props.theme.themeLight};
          .ant-collapse-arrow > svg {
            width: 10px;
            fill: currentColor;
          }
        }
        .ant-collapse-content-box {
          padding: unset;
        }
        &-active {
          .ant-collapse-header {
            background-color: unset;
          }
        }
      }
    }
    &.favorite > .ant-collapse .ant-collapse-header {
      background-color: unset;
    }
    /* 报价下第一级 Collapse header 样式 */
    /* & > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      color: ${props => props.theme.theme};
    } */
  }
`
// NOTE: 【自选】报价版块
export const FavoriteList = () => {
  const symbolNames = useSelector(selectFavorites)
  return (
    <Wrapper>
      {symbolNames.length ? (
        <>
          <ul className="title-ul">
            <li className="title-li">
              <FormattedMessage id="home.quotePane.table.ths.th1" />
            </li>
            <li className="title-li">
              <FormattedMessage id="home.quotePane.table.ths.th2" />
            </li>
            <li className="title-li">
              <FormattedMessage id="home.quotePane.table.ths.th3" />
            </li>
            <li className="title-li">
              <FormattedMessage id="home.quotePane.table.ths.th4" />
            </li>
          </ul>
          <div className="content-x favorite">
            {symbolNames.map(name => (
              <SymbolItem key={name} name={name} />
            ))}
          </div>
        </>
      ) : (
        <Empty />
      )}
    </Wrapper>
  )
}

export default FavoriteList
