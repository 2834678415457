import { useState, useEffect, useCallback } from "react"
import { Button, Image } from "antd"
import styled from "styled-components"
import LOGO from "../../../assets/images/logo_fff.png"
import LOGO_HOTSIGN from "../../../assets/images/logo_for_hotsign.png"
import { IconFont } from "../../common/IconFont"
import OrderPane from "../OrderPane"
import { useSelector } from "react-redux"
import config from "../../../config/config"
import { post } from "../../../services/request"
import apis from "../../../services/apis"
import { toDecimal } from "../../../utils/util"
import { useGlobalModal } from "../../../utils/hooks"
import strings from "../../../constants/strings"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { getStorage } from "../../../utils/storageHelper"
import { selectOrderIds } from "../../../store/orderSlice"
import { getInfoByAccount } from "../../../utils/userHelper"

const { defaultString: DEFAULT_STRING, thirdPartProjectNames } = config
const Wrapper = styled.div`
  height: "100%";
`
const AccountPaneWrapper = styled.div`
  width: 100%;
  height: 45px;
  flex: none;
  margin-top: 8px;
  display: flex;
  align-items: center;
  background-color: ${props => props.theme.cardBg};
  border-top-left-radius: ${props => (!!props.isHotSign ? "6px" : "unset")};
  border-bottom-left-radius: ${props => (!!props.isHotSign ? "6px" : "unset")};
  .acp-logo-x {
    flex: none;
    width: 144px;
    padding: 0 4px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.theme};
    &.hotsign {
      width: 45px;
      height: 45px;
      padding: 0;
      background-color: unset;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .acp-info-ul {
    flex: 1;
    margin: 0 24px;
    display: flex;
    font-size: 12px;
    .acp-info-li {
      & + .acp-info-li {
        margin-left: 64px;
      }
      .acp-info-row1 {
        display: flex;
        align-items: center;
        color: ${props => props.theme.title};
      }
      .acp-info-row2 {
        font-weight: 700;
        color: ${props => props.theme.themeRed};
        &.negative {
          color: ${props => props.theme.themeGreen};
        }
      }
      .acp-btn-showTip {
        height: auto;
        margin-left: 6px;
        padding: 0;
        display: flex;
        align-items: center;
        line-height: inherit;
        .icon-question {
          width: 14px;
          height: 14px;
          margin-top: -2px;
          fill: ${props => props.theme.starLow};
        }
        /* &:hover .icon-question {
          fill: ${props => props.theme.theme};
        } */
      }
      // -- 最后一项 盈利值
      &:last-child {
        margin-left: auto;
        display: flex;
        align-items: center;
        .acp-btn-showTip {
          display: inline-flex;
        }
        .label,
        .unit {
          color: ${props => props.theme.title};
        }
        .val {
          margin: 0 4px 0 8px;
          font-weight: 500;
          color: ${props => props.theme.themeRed};
          &.negative {
            color: ${props => props.theme.themeGreen};
          }
        }
      }
    }
  }
`

/** NOTE: 账户版块
 * @param {Number} activeKey 订单版块当前选中的 tab 的 key
 */
const AccountPane = ({ activeKey }) => {
  const isDemoAccount = getInfoByAccount()?.type === 1
  const orderIds = useSelector(selectOrderIds)
  const showAccInfoTipModal = useGlobalModal(strings.modalKeyAccInfoTip)
  const isHistory = activeKey > 1 // !cmdConfig.positionIds.includes(activeKey)
  const profit = useSelector(state => state.orders.totalProfits[activeKey])
  const [info, setInfo] = useState(null)
  const thirdPartProjectName = getStorage(
    strings.storageKeyThirdPartProjectName
  )
  const isHotSign = thirdPartProjectName === thirdPartProjectNames?.hotsignPro

  useEffect(() => {
    // NOTE: 订单列表数量变化时（开仓、平仓）会触发账户数据的更新
    const getAccountInfo = async () => {
      const { error, data } = await post(apis.getAccountInfo)
      if (error) return
      setInfo(data)
    }
    getAccountInfo()
  }, [JSON.stringify(orderIds)])

  return (
    <AccountPaneWrapper isHotSign={isHotSign}>
      <div className={classNames("acp-logo-x", { hotsign: isHotSign })}>
        <Image preview={false} src={isHotSign ? LOGO_HOTSIGN : LOGO} />
      </div>
      <div className="acp-info-ul">
        <div className="acp-info-li">
          <div className="acp-info-row1">
            <span>
              <FormattedMessage id="home.accountPane.labels.label1" />
            </span>
            <Button
              className="initial no-trigger acp-btn-showTip"
              onClick={showAccInfoTipModal}
            >
              <IconFont className="icon-question" />
            </Button>
          </div>
          <div
            className={classNames("acp-info-row2", {
              negative: !!info && Number(info.equity) < 0,
            })}
          >
            {isDemoAccount ? "￥" : "$"}{" "}
            {!!info ? toDecimal(info.equity, 2) : DEFAULT_STRING}
          </div>
        </div>
        <div className="acp-info-li">
          <div className="acp-info-row1">
            <span>
              <FormattedMessage id="home.accountPane.labels.label2" />
            </span>
            <Button
              className="initial no-trigger acp-btn-showTip"
              onClick={showAccInfoTipModal}
            >
              <IconFont className="icon-question" />
            </Button>
          </div>
          <div
            className={classNames("acp-info-row2", {
              negative: !!info && Number(info.margin_free) < 0,
            })}
          >
            {isDemoAccount ? "￥" : "$"}{" "}
            {!!info ? toDecimal(info.margin_free, 2) : DEFAULT_STRING}
          </div>
        </div>
        <div className="acp-info-li">
          <div className="acp-info-row1">
            <span>
              <FormattedMessage id="home.accountPane.labels.label3" />
            </span>
            <Button
              className="initial no-trigger acp-btn-showTip"
              onClick={showAccInfoTipModal}
            >
              <IconFont className="icon-question" />
            </Button>
          </div>
          <div
            className={classNames("acp-info-row2", {
              negative: !!info && info.margin / info.equity < 0,
            })}
          >
            {`${
              !!info
                ? toDecimal((info.margin / info.equity) * 100, 2)
                : DEFAULT_STRING
            }%`}
          </div>
        </div>
        <div className="acp-info-li">
          <span className="label">
            {!isHistory ? (
              <FormattedMessage id="home.accountPane.labels.label4.market" />
            ) : (
              <FormattedMessage id="home.accountPane.labels.label4.history" />
            )}
          </span>
          <span className={classNames("val", { negative: Number(profit) < 0 })}>
            {profit}
          </span>
          {/* <span className="unit">CNY</span> */}
          <Button
            className="initial no-trigger acp-btn-showTip"
            onClick={showAccInfoTipModal}
          >
            <IconFont className="icon-question" />
          </Button>
        </div>
      </div>
    </AccountPaneWrapper>
  )
}

const BottomPane = ({ ...props }) => {
  const [activeKey, setActiveKey] = useState(0)

  return (
    <Wrapper {...props}>
      <OrderPane onChangeTab={setActiveKey} />
      <AccountPane activeKey={activeKey} />
    </Wrapper>
  )
}

export default BottomPane
