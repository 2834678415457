const strings = {
  storageKeyAccInfo: "storageKeyAccInfo",
  storageKeyCurrAcc: "storageKeyCurrAcc",
  storageKeyKLineData: "storageKeyKLineData",
  storageKeyKLineSymbol: "storageKeyKLineSymbol",
  storageKeySymbolGroups: "storageKeySymbolGroups",
  storageKeyIsSuspension: "storageKeyIsSuspension",
  // 为第三方定制化时记住第三方名称
  storageKeyThirdPartProjectName: "storageKeyThirdPartProjectName",
  modalKeySymbolInfo: "modalKeySymbolInfo",
  modalKeyAccInfoTip: "modalKeyAccInfoTip",
  modalKeyOrderEdit: "modalKeyOrderEdit",
  modalKeyOrderTrade: "modalKeyOrderTrade",
  modalKeySettings: "modalKeySettings",
  modalKeyFinanceCalendarDetail: "modalKeyFinanceCalendarDetail",
  // modalKeyConfirm: "modalKeyConfirm",
  cacheDataKeyKLine: "cacheDataKeyKLine",
  customConfigKeyTheme: "appearance.theme",
  customConfigKeyLocale: "appearance.locale",
  customConfigKeyCloseOrderConfirm: "trade.closeOrderConfirm",
  // -- new
  storageKeyHistoryKlineInfo: "storageKeyHistoryKlineInfo",
  customConfigKeyExternalSymbol: "customConfigKeyExternalSymbol",
}
export default strings
