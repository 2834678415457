import login from "./login"
import home from "./home"
import modal from "./modal"
import component from "./component"

const enus = {
  ...login,
  ...home,
  ...modal,
  ...component,
}

export default enus
