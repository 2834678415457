import login from "./login"
import home from "./home"
import modal from "./modal"
import component from "./component"

const zhcn = {
  ...login,
  ...home,
  ...modal,
  ...component,
}
export default zhcn
