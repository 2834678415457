import styled from "styled-components"
import { Tabs, Tooltip } from "antd"
import SymbolListByGroups from "./SymbolListByGroups"
import { useSelector } from "react-redux"
import { selectGroupInfo } from "../../../store/symbolSlice"
import { Empty } from "../../common/Empty"
import { getInfoByAccount } from "../../../utils/userHelper"
import { FormattedMessage, useIntl } from "react-intl"
import { FavoriteList } from "./FavoriteList"
import { IconFont } from "../../common/IconFont"
import { isSafari } from "../../../utils/util"
import classNames from "classnames"

const { TabPane } = Tabs
const Wrapper = styled.div`
  /* max-width: 420px; */
  flex: 1 0 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  .pane-title {
    width: 110px;
    height: 32px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.cardBg};
    font-size: 13px;
    font-weight: 700;
    color: ${props => props.theme.theme};
  }
  .pane-content {
    flex: 1;
    background-color: ${props => props.theme.cardBg};
    .ant-tabs-top {
      height: 100%;
      & > .ant-tabs-nav {
        margin-bottom: unset;
        padding: 10px;
        border-bottom: 1px solid ${props => props.theme.border};
        &::before {
          content: unset;
        }
        .ant-tabs-tab {
          padding: unset;
          font-size: 13px;
          font-weight: 500;
          color: ${props => props.theme.secondary};
        }
        .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
        .ant-tabs-tab:hover,
        .ant-tabs-tab-btn:focus,
        .ant-tabs-tab-btn:active {
          color: ${props => props.theme.theme};
          /* text-shadow: 0 0 0.65px currentColor; */
        }
        /* tab 下方的选中横线 */
        .ant-tabs-ink-bar {
          display: none;
        }
      }
      & .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane {
          display: flex;
          /* flex-direction: column; */
          height: 100%;
          padding: 8px;
        }
      }
    }
  }
  .ant-empty {
    width: 100%;
    height: 100%;
    margin: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ant-tabs-tab-btn .favorite {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    .icon-wrapper {
      margin-left: 4px;
      display: flex;
      .icon-question {
        width: 14px;
        height: 14px;
        margin-top: 1px;
        fill: ${props => props.theme.starLow};
      }
    }
  }
  &.isSafari .ant-tabs-content-holder {
    display: flex;
    flex-direction: column;
    & > .ant-tabs-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      & > .ant-tabs-tabpane {
        flex: 1;
      }
    }
  }
`

// NOTE: 报价
const QuotePane = () => {
  const intl = useIntl()
  const { inlandGroups = [], intlGroups = [] } =
    useSelector(selectGroupInfo) || {}
  // console.log("==QuotePane", inlandGroups, intlGroups)
  const { type } = getInfoByAccount()
  const tabs = [
    {
      key: "自选",
      tab: (
        <div className="favorite">
          <FormattedMessage id="home.quotePane.tabs.favorite" />
          <Tooltip
            title={<FormattedMessage id="home.quotePane.tooltip.favoriteTab" />}
            placement="right"
          >
            <span className="icon-wrapper">
              <IconFont className="icon-question" />
            </span>
          </Tooltip>
        </div>
      ),
      // tab: intl.formatMessage({ id: "home.quotePane.tabs.favorite" }),
      tabPane: <FavoriteList />,
    },
    {
      key: "国内期货", // NOTE: key 值不要使用 intl 内容，否则会导致无意义的 render
      tab: intl.formatMessage({ id: "home.quotePane.tabs.inland" }),
      tabPane: inlandGroups.length ? (
        <SymbolListByGroups groups={inlandGroups} />
      ) : (
        <Empty />
      ),
    },
    {
      key: "国际市场",
      tab: intl.formatMessage({ id: "home.quotePane.tabs.intl" }),
      tabPane: <SymbolListByGroups groups={intlGroups} />,
    },
  ]
  return (
    <Wrapper className={classNames({ isSafari: isSafari() })}>
      <div className="pane-title">
        <FormattedMessage id="home.quotePane.title" />
      </div>
      <div className="pane-content">
        <Tabs
          defaultActiveKey={
            type === 1
              ? intl.formatMessage({ id: "home.quotePane.tabs.inland" })
              : intl.formatMessage({ id: "home.quotePane.tabs.intl" })
          }
        >
          {tabs.map(item => (
            <TabPane tab={item.tab} key={item?.key || item.tab}>
              {item.tabPane || <></>}
            </TabPane>
          ))}
        </Tabs>
      </div>
    </Wrapper>
  )
}

export default QuotePane
