import { Line } from "@ant-design/charts"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"
import COLORS from "../../../constants/colors"
import strings from "../../../constants/strings"
import { selectCustomItem } from "../../../store/customSlice"
import { getStorage } from "../../../utils/storageHelper"
import { getChartSliderCfg, getChartTooltipCfg } from "../../../utils/util"
import { Empty } from "../../common/Empty"
import Skeleton from "../../common/Skeleton"

// NOTE: 资讯 - 日历 - 详情弹窗 - 历史数据折线图
export default ({ data, ...props }) => {
  const intl = useIntl()
  const thirdPartProjectName =
    getStorage(strings.storageKeyThirdPartProjectName) || "default"
  const theme = useSelector(state =>
    selectCustomItem(state, strings.customConfigKeyTheme)
  )
  const colors = COLORS[thirdPartProjectName][theme]
  const axisLabelConfig = {
    label: {
      style: {
        fill: colors.secondary,
        fontSize: 10,
        fontFamily: "DINAlternate",
      },
    },
  }
  const tooltipCfg = getChartTooltipCfg(colors)
  const sliderCfg = getChartSliderCfg(colors)
  const config = {
    data,
    xField: "date",
    yField: "value",
    seriesField: "type",
    autoFit: true,
    appendPadding: [8, 8, 0, 0],
    meta: {
      date: {
        range: [0.01, 0.99],
      },
      type: {
        formatter: item =>
          item === "actual"
            ? intl.formatMessage({ id: "home.calendarPane.modal.table.thead4" })
            : intl.formatMessage({
                id: "home.calendarPane.modal.table.thead3",
              }),
      },
    },
    color: [colors.theme],
    xAxis: {
      nice: true,
      label: {
        ...axisLabelConfig,
        formatter: (val, _, idx) => (!(idx % 2) ? "" : val),
      },
      line: null,
      tickLine: null,
      tickCount: 10,
      grid: null,
    },
    yAxis: {
      ...axisLabelConfig,
      grid: {
        line: {
          style: {
            stroke: colors.border,
            lineWidth: 1,
          },
        },
      },
    },
    legend: {
      position: "top-right",
    },
    slider:
      thirdPartProjectName === "default"
        ? {
            backgroundStyle: {
              fill: colors.background,
            },
            foregroundStyle: {
              fill: colors.theme,
            },
            handlerStyle: {
              fill: colors.chartSliderHandlerFill,
              highLightFill: colors.chartSliderHandlerFill,
              stroke: colors.chartSliderHandlerStroke,
            },
            textStyle: {
              fill: colors.primary,
            },
          }
        : sliderCfg,
    smooth: true,
    tooltip: { ...tooltipCfg },
  }
  return (
    <Skeleton active loading={!data?.length}>
      {data?.length ? <Line {...config} {...props} /> : <Empty center />}
    </Skeleton>
  )
}
