import { useState, useEffect } from "react"
import { Button, Form, InputNumber, message, Switch } from "antd"
import config from "../../../config/config"
import classNames from "classnames"
import { FormattedMessage, useIntl } from "react-intl"

const { defaultString: DEFAULT_STRING } = config

export const MarketPane = ({
  extraData,
  data,
  symbolInfo,
  updateMarginValue,
  updateProfit,
  handleSubmit,
}) => {
  const intl = useIntl()
  const { price, isBuy, tpLimitPrice, slLimitPrice } = extraData
  const { digits } = data
  const {
    volume_step: volumeStep,
    volume_min: volumeMin,
    volume_max: volumeMax,
  } = symbolInfo
  const priceStep = (10 ** (-1 * digits)).toFixed(digits)
  const [margin, setMargin] = useState(0)
  const [isSwitchChecked, setIsSwitchChecked] = useState([false, false])
  const [profits, setProfits] = useState(new Array(2).fill(DEFAULT_STRING))
  const [isLoading, setIsLoading] = useState(false)
  const handleValuesChange = (changeValues, allValues) => {
    const { volume, tp, sl } = changeValues
    const { volume: currVolume, tp: currTp, sl: currSl } = allValues
    if (!!volume) {
      setMargin(updateMarginValue(currVolume))
    }
    if ((volume || tp) && isSwitchChecked[0]) {
      setProfits(profits => [updateProfit(currVolume, currTp, 0), profits[1]])
    }
    if ((volume || sl) && isSwitchChecked[1]) {
      setProfits(profits => [profits[0], updateProfit(currVolume, currSl, 1)])
    }
  }
  const validateParams = values => {
    const { volume, tp, sl } = values
    let params = {
      lots: volume,
      cmd: isBuy ? 0 : 1,
      tp: 0,
      sl: 0,
      open_price: 0,
    }
    // 检查 手数 volume 值
    if (!volume || volume < volumeMin || volume > volumeMax) {
      message.error(
        intl.formatMessage({
          id: "modal.orderTrade.market.message.error.volumeInvalid",
        })
      )
      return
    }
    // 检验 止盈 tp 的值
    if (isSwitchChecked[0]) {
      if (!tp || isNaN(Number(profits[0]))) {
        message.error(
          intl.formatMessage({
            id: "modal.orderTrade.market.message.error.tpInvalid",
          })
        )
        return
      }
      params.tp = tp
    }
    // 检验 止盈 tp 的值
    if (isSwitchChecked[1]) {
      if (!sl || isNaN(Number(profits[1]))) {
        message.error(
          intl.formatMessage({
            id: "modal.orderTrade.market.message.error.slInvalid",
          })
        )
        return
      }
      params.sl = sl
    }
    return params
  }
  const onFinish = values => {
    setIsLoading(true)
    const params = validateParams(values)
    if (params) {
      // 验证通过
      handleSubmit(params, () => setIsLoading(false))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setMargin(updateMarginValue())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="otm-desc-ul">
        <div className="otm-desc-li">
          <span className="label">
            <FormattedMessage id="modal.orderTrade.market.descItem1" />
          </span>
          <span className="val">{price}</span>
        </div>
        <div className="otm-desc-li">
          <span className="label">
            <FormattedMessage id="modal.orderTrade.market.descItem2" />
          </span>
          <span className="val">{margin || DEFAULT_STRING}</span>
        </div>
      </div>
      <Form
        name="market"
        className="otm-form market"
        colon={false}
        initialValues={{
          volume: volumeMin,
        }}
        onValuesChange={handleValuesChange}
        onFinish={onFinish}
      >
        <Form.Item
          label={<FormattedMessage id="modal.orderTrade.market.formItem1" />}
          name="volume"
        >
          <InputNumber min={volumeMin} max={volumeMax} step={volumeStep} />
        </Form.Item>
        <Form.Item
          label={
            <>
              <span className="otm-limit-label">
                <FormattedMessage id="modal.orderTrade.market.formItem2.label" />
              </span>
              <Switch
                className="no-trigger"
                size="small"
                checked={isSwitchChecked[0]}
                onChange={() => setIsSwitchChecked(val => [!val[0], val[1]])}
              />
              <span
                className={classNames("otm-limit-tip", {
                  show: isSwitchChecked[0],
                })}
              >
                {isBuy ? ">" : "<"} {tpLimitPrice}
              </span>
            </>
          }
          name="tp"
        >
          <InputNumber
            step={priceStep}
            min={0}
            placeholder={DEFAULT_STRING}
            disabled={!isSwitchChecked[0]}
            stringMode
          />
        </Form.Item>
        <div
          className={classNames("otm-profit-x", {
            active: isSwitchChecked[0],
          })}
        >
          <span>
            <FormattedMessage id="modal.orderTrade.market.formItem2.profitText" />
          </span>
          <span
            className={classNames("otm-profit", {
              green: !!Number(profits[0]),
            })}
          >
            {profits[0]}
          </span>
        </div>
        <Form.Item
          label={
            <>
              <span className="otm-limit-label">
                <FormattedMessage id="modal.orderTrade.market.formItem3.label" />
              </span>
              <Switch
                className="no-trigger"
                size="small"
                checked={isSwitchChecked[1]}
                onChange={() => setIsSwitchChecked(val => [val[0], !val[1]])}
              />
              <span
                className={classNames("otm-limit-tip", {
                  show: isSwitchChecked[1],
                })}
              >
                {isBuy ? "<" : ">"} {slLimitPrice}
              </span>
            </>
          }
          name="sl"
        >
          <InputNumber
            step={priceStep}
            min={0}
            placeholder={DEFAULT_STRING}
            disabled={!isSwitchChecked[1]}
          />
        </Form.Item>
        <div
          className={classNames("otm-profit-x", {
            active: isSwitchChecked[1],
          })}
        >
          <span>
            <FormattedMessage id="modal.orderTrade.market.formItem3.profitText" />
          </span>
          <span
            className={classNames("otm-profit", {
              red: !!Number(profits[1]),
            })}
          >
            {profits[1]}
          </span>
        </div>
        <Form.Item>
          <Button
            className={classNames("initial no-trigger otm-submit-btn", {
              sell: !isBuy,
            })}
            htmlType="submit"
            loading={isLoading}
          >
            {isBuy ? (
              <FormattedMessage id="modal.orderTrade.market.submitBtnText.buy" />
            ) : (
              <FormattedMessage id="modal.orderTrade.market.submitBtnText.sell" />
            )}
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}
