import styled, { useTheme } from "styled-components"
import { IconFont } from "./IconFont"

// NOTE: 星星组件
const Wrapper = styled.div`
  .icon-star-full {
    width: 12px;
    height: 12px;
    /* fill: ${props => props.len}; */
    & + .icon-star-full {
      margin-left: 2px;
    }
  }
`
const Star = ({ star, ...props }) => {
  const themeContext = useTheme()
  star = Number(star)
  return (
    <Wrapper len={star} {...props}>
      {[...Array(5).keys()].map((item, idx) => (
        <IconFont
          key={idx}
          className="icon-star-full"
          style={{
            fill:
              idx < star
                ? star <= 2
                  ? themeContext.starLow
                  : themeContext.starHigh
                : themeContext.starDefault,
          }}
        ></IconFont>
      ))}
    </Wrapper>
  )
}

export default Star

// NOTE: 星星组件
// const StarWrapper = styled.div`
//   .icon-star-full {
//     width: 12px;
//     height: 12px;
//     fill: ${props => props.theme.border};
//     & + .icon-star-full {
//       margin-left: 2px;
//     }
//     &.active {
//       fill: ${({ len, theme }) => (len <= 2 ? theme.starLow : theme.starHigh)};
//     }
//   }
// `
// const Star = ({ star }) => {
//   const arr = new Array(star).fill(1).concat(new Array(5 - star).fill(0)) || []

//   return (
//     <StarWrapper len={star}>
//       {arr.map((item, index) => (
//         <IconFont
//           key={index}
//           className="icon-star-full"
//           extraClassName={item === 1 && "active"}
//         />
//       ))}
//     </StarWrapper>
//   )
// }
