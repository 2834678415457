const modal = {
  modal: {
    symbolInfo: {
      mTitle1: "基础信息",
      mTitle2: "交易时间",
      weeks: {
        sun: "星期日",
        mon: "星期一",
        tues: "星期二",
        wed: "星期三",
        thurs: "星期四",
        fri: "星期五",
        sat: "星期六",
      },
    },
    accInfoTip: {
      title: "账户信息计算规则",
      item1: {
        label: "当前权益",
        formula: "当前权益 = 余额 ± 浮动盈亏",
      },
      item2: {
        label: "可用资金",
        formula: "可用资金 = 当前权益 - 已用保证金",
      },
      item3: {
        label: "风险度",
        formula: "风险度 = 已用保证金 / 当前权益",
      },
      item4: {
        label: "持仓盈亏",
        formula: "即浮动盈亏。持仓盈亏 = 浮动盈亏 = 净值 - 余额",
      },
    },
    orderEdit: {
      formItem1: {
        label: "止盈",
        profitText: "预计收益：",
      },
      formItem2: {
        label: "止损",
        profitText: "预计亏损：",
      },
      profitError: "价格设置错误，请重试",
      descUl: {
        item1: "订单编号",
        item2: "开仓时间",
        item3: "手续费",
        item4: "库存费",
      },
      message: {
        error: {
          valNull: "请填写止盈或止损价格",
          tpInvalid: "止盈价格有误",
          slInvalid: "止损价格有误",
          editFailed: {
            title: "订单修改失败",
            desc: "订单 {ticket} 止盈/止损值修改失败{error}",
          },
        },
        success: {
          editSuccess: {
            title: "订单修改成功",
            desc: "订单 {ticket} 止盈/止损值修改成功",
          },
        },
      },
    },
    orderTrade: {
      tabs: {
        tab1: "市场",
        tab2: "挂单",
      },
      market: {
        descItem1: "现价",
        descItem2: "保证金",
        formItem1: "交易量",
        formItem2: {
          label: "止盈",
          profitText: "预计收益：",
        },
        formItem3: {
          label: "止损",
          profitText: "预计亏损：",
        },
        submitBtnText: {
          buy: "做多",
          sell: "做空",
        },
        message: {
          error: {
            volumeInvalid: "交易量有误",
            tpInvalid: "止盈价格有误",
            slInvalid: "止损价格有误",
          },
        },
      },
      limit: {
        formItemPrice: "成交价格",
        submitBtnText: {
          buyLimit: "限价买入",
          sellLimit: "限价卖出",
          buyStop: "买入止损",
          sellStop: "卖出止损",
        },
        message: {
          error: {
            priceNull: "请填写成交价格",
            volumeInvalid: "交易量有误",
            tpInvalid: "止盈价格有误",
            slInvalid: "止损价格有误",
          },
        },
      },
      message: {
        success: {
          createOrder: {
            title: "订单创建成功",
            desc: "订单编号为： {ticket}",
          },
        },
        error: {
          marginNull: "商品保证金获取失败请重试",
          profitInvalid: "价格设置错误，请重试",
          createOrder: {
            title: "订单创建失败",
          },
        },
      },
    },
    settings: {
      title: "设置",
      item1: {
        label: "平仓确认",
        tooltip: "如打开选项，关闭未平仓仓位之前需要用户确认。",
      },
      more: "静候更多自定义配置 ...",
    },
  },
}
export default modal
