const login = {
  login: {
    left: {
      title: "欢迎访问 webTrader !",
      desc: "下载 火象交易APP ，在手机上体验同样优质的交易服务",
      downloadBtnText: "火象交易APP",
      electron: {
        tip: "或者下载本应用桌面端",
        mac: "Mac 桌面端",
        win: "Windows 桌面端",
      },
    },
    form: {
      title: "登录",
      userName: {
        label: "账号",
        placeholder: "请输入用户名",
      },
      pwd: {
        label: "密码",
        placeholder: "请输入密码",
      },
      createAccount: "没有账号？<a>创建一个 >></a>",
      btns: {
        submit: "登录",
        reset: "重置",
      },
      message: {
        success: {
          signIn: "登录成功",
          autoSignIn: "已为您自动登入账号",
        },
        error: {
          valueInvalid: "账号密码错误",
          autoSignIn: "自动登录失败，请手动登入",
        },
      },
    },
    message: {
      error: {
        network: "网络错误",
      },
      success: {
        copyDownloadUrl: "下载页地址已保存至剪贴板，请打开浏览器粘贴访问",
      },
    },
  },
}

export default login
