import { useEffect, useState } from "react"
import { Button, DatePicker, Table } from "antd"
import styled from "styled-components"
import config from "../../../config/config"
import { toDecimal } from "../../../utils/util"
import { useDispatch, useSelector } from "react-redux"
import { selectSymbolByNames } from "../../../store/symbolSlice"
import {
  fetchHistoryOrders,
  updateTotalProfits,
} from "../../../store/orderSlice"
import moment from "moment"
import { useIntl } from "react-intl"
import ExportJsonExcel from "js-export-excel"

const {
  cmdConfig,
  defaultString: DEFAULT_STRING,
  fetchStatus,
  locales,
} = config
const [, , enus] = locales.ids
const [, PENDING] = fetchStatus
const { RangePicker } = DatePicker
const Wrapper = styled.div`
  position: relative;
  height: 100%;
  .ht-top-x {
    position: absolute;
    top: -8px;
    right: -8px;
    height: 32px;
    display: flex;
    justify-content: center;
    transform: translateY(-100%);
    z-index: 1;
    .ant-picker-range {
      height: 26px;
      border: 0;
      background-color: ${props => props.theme.cardBg};
      &.ant-picker-focused {
        box-shadow: 0 0 0 2px ${props => props.theme.themeLight};
      }
      .ant-picker-active-bar {
        background-color: ${props => props.theme.theme};
      }
      .ant-picker-input {
        & > input {
          font-size: 12px;
          font-weight: 500;
          color: ${props => props.theme.theme};
          &::placeholder {
            color: ${props => props.theme.secondary};
          }
        }
      }
    }
    .ant-picker-clear {
      color: ${props => props.theme.solidIcon};
      background-color: ${props => props.theme.cardBg};
    }
    .ht-btn-export {
      height: 26px;
      margin-left: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${props => props.theme.theme};
      color: white; //${props => props.theme.theme};
      /* &:hover {
        background-color: ${props => props.theme.theme};
        color: white;
      } */
    }
  }
  .ant-spin-nested-loading {
    .ant-spin-dot-item {
      background-color: ${props => props.theme.theme};
    }
    .ant-spin-container::after {
      background-color: ${props => props.theme.themeLight};
    }
  }
`
// tableScrollHeight

const defaultRangeDate = [moment().subtract(3, "days"), moment().endOf("day")]
const getApiRangeParams = (from, to) => {
  const fromStr = from.startOf("day").format("X")
  const toStr = to.endOf("day").format("X")
  return { from: fromStr, to: toStr }
}
export const HistoryTable = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const data = useSelector(state => state.orders.historyOrders.list)
  const dataStatus = useSelector(state => state.orders.historyOrders.status)
  const nameSet = new Set()
  data.map(item => nameSet.add(item.symbol))
  const names = [...nameSet]
  const infos = useSelector(
    state => {
      let _infos = selectSymbolByNames(state, names)
      for (var key in _infos) {
        const { cn_name, digits, name } = _infos[key] || {}
        _infos[key] = { cnName: cn_name, digits, name }
      }
      return _infos
    },
    (prev, next) => {
      return JSON.stringify(prev) === JSON.stringify(next)
    }
  )
  const columns = [
    {
      title: intl.formatMessage({ id: "home.orderPane.history.ths.th1" }),
      dataIndex: "symbol",
      width: "15%",
      className: "op-cell-ticket",
      render: (val, record) => {
        const nameKey = intl.locale !== enus ? "cnName" : "name"
        // NOTE: 名称显示加上 val 是为了保证某些产品下架后找不到对应信息而出现显示问题
        return (
          <>
            <span>{infos?.[val]?.[nameKey] || val || DEFAULT_STRING}</span>
            <span>{record.ticket}</span>
          </>
        )
      },
    },
    {
      title: intl.formatMessage({ id: "home.orderPane.history.ths.th2" }),
      dataIndex: "volume",
      align: "center",
      width: "6.94%",
      sorter: (a, b) => a.volume - b.volume,
    },
    {
      title: intl.formatMessage({ id: "home.orderPane.history.ths.th3" }),
      dataIndex: "cmd",
      width: "9.46%",
      align: "center",
      render: val => cmdConfig.entities[val],
      filters: Object.entries(cmdConfig.entities).map(([key, val]) => ({
        text: val,
        value: key,
      })),
      onFilter: (val, record) => Number(record.cmd) === Number(val),
    },
    {
      title: intl.formatMessage({ id: "home.orderPane.history.ths.th4" }),
      dataIndex: "open_price",
      width: "13.12%",
      className: "op-cell-price",
      render: (val, record) => (
        <>
          <span>{toDecimal(val, infos?.[record.symbol]?.digits)}</span>
          <span>
            {record?.close_price
              ? toDecimal(record.close_price, infos?.[record.symbol]?.digits)
              : DEFAULT_STRING}
          </span>
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: "home.orderPane.history.ths.th5" }),
      dataIndex: "open_time",
      width: "12.06%",
      align: "center",
    },
    {
      title: intl.formatMessage({ id: "home.orderPane.history.ths.th6" }),
      dataIndex: "close_time",
      width: "12.06%",
      align: "center",
      render: val => <>{val || DEFAULT_STRING}</>,
    },
    {
      title: intl.formatMessage({ id: "home.orderPane.history.ths.th7" }),
      dataIndex: "tp",
      align: "center",
      width: "7.98%",
    },
    {
      title: intl.formatMessage({ id: "home.orderPane.history.ths.th8" }),
      dataIndex: "sl",
      align: "center",
      width: "7.98%",
    },
    {
      title: intl.formatMessage({ id: "home.orderPane.history.ths.th9" }),
      dataIndex: "storage",
      align: "center",
      width: "7.95%",
      render: (val, record) => (
        <>
          {toDecimal(
            cmdConfig.limitIds.includes(record.cmd) ? record.swap : val,
            2
          )}
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: "home.orderPane.history.ths.th10" }),
      dataIndex: "profit",
      align: "center",
      className: "op-cell-profit",
      render: val => (
        <span className={Number(val) >= 0 ? "positive" : "negative"}>
          {!!val ? toDecimal(val, 2) : DEFAULT_STRING}
        </span>
      ),
      sorter: (a, b) => {
        let ap = a?.profit || Number.MIN_SAFE_INTEGER,
          bp = b?.profit || Number.MIN_SAFE_INTEGER
        return ap - bp
      },
    },
  ]

  const [limitRange, setLimitRange] = useState(null)
  const handleCalendarChange = (dates, dateStrings, { range }) => {
    const [from, to] = dateStrings
    switch (range) {
      case "start":
        setLimitRange([moment(from), moment(from).add(2, "days").endOf("day")])
        break
      case "end":
        setLimitRange([moment(to).subtract(2, "days"), moment(to).endOf("day")])
        break
      default:
        setLimitRange(null)
        break
    }
  }
  const handleDisabledDate = currentDate => {
    if (!limitRange) {
      return false
    }
    if (
      currentDate.isBefore(limitRange[0]) ||
      currentDate.isAfter(limitRange[1])
    ) {
      return true
    }
  }
  const handleRangeChange = dates => {
    if (!dates) {
      setLimitRange(null)
      const params = getApiRangeParams(defaultRangeDate[0], defaultRangeDate[1])
      dispatch(fetchHistoryOrders(params))
      return
    }
    const params = getApiRangeParams(dates[0], dates[1])
    dispatch(fetchHistoryOrders(params))
  }

  // 导出当前订单数据为 excel
  const handleExport = () => {
    let option = {}

    let _columns = [...columns]
    _columns.unshift({
      title: intl.formatMessage({
        id: "home.orderPane.history.ths.th11",
      }),
      dataIndex: "ticket",
    })
    option.fileName = "历史订单数据"

    option.datas = [
      {
        sheetData: data.map(item => ({
          ...item,
          open_price: `${item.open_price}/${item.close_price}`,
          cmd: cmdConfig.entities[item.cmd],
          storage: item?.storage || item.swap,
          profit: item.profit || DEFAULT_STRING,
        })),
        sheetName: "sheet",
        sheetHeader: _columns.map(item => item.title),
        sheetFilter: _columns.map(item => item.dataIndex),
        columnWidths: _columns.map(item => parseInt(item.width) || ""),
      },
    ]

    var toExcel = new ExportJsonExcel(option) //new
    toExcel.saveExcel() //保存
  }
  useEffect(() => {
    const from = moment().subtract(1, "months").format("X")
    const to = moment().endOf("day").format("X")
    dispatch(fetchHistoryOrders({ from, to }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    let totalProfits = 0
    data.map(item => (totalProfits += Number(item.profit) || 0))
    dispatch(updateTotalProfits({ idx: 2, value: toDecimal(totalProfits, 2) }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)])

  return (
    <Wrapper>
      <div className="ht-top-x">
        <RangePicker
          placeholder={[
            intl.formatMessage({
              id: "home.orderPane.history.picker.placeholder.startText",
            }),
            intl.formatMessage({
              id: "home.orderPane.history.picker.placeholder.endText",
            }),
          ]}
          defaultValue={defaultRangeDate}
          onChange={handleRangeChange}
          onCalendarChange={handleCalendarChange}
          disabledDate={handleDisabledDate}
        />
        <Button
          className="initial no-trigger ht-btn-export"
          onClick={handleExport}
        >
          {intl.formatMessage({ id: "home.orderPane.history.exportBtn" })}
        </Button>
      </div>
      <Table
        className="scroll-auto"
        rowKey={record => record.ticket} // 必须！！
        dataSource={data}
        columns={columns}
        pagination={false}
        // scroll={!!tableScrollHeight && { y: tableScrollHeight }}
        scroll={{ y: "calc(100% - 30px)" }}
        loading={dataStatus === PENDING}
        showSorterTooltip={false} // 关闭排序的 tooltip 提示框
      />
    </Wrapper>
  )
}
