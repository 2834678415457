import { useState, useEffect } from "react"
import { Divider, message, Table } from "antd"
import styled from "styled-components"
import moment from "moment"
import Star from "../common/Star"
// import LineChartForFCDetail from "./LineChartForFCDetail"
import { post } from "../../services/request"
import apis from "../../services/apis"
import config from "../../config/config"
import { Loading } from "../common/Loading"
import GlobalModal from "./GlobalModal"
import LineChartForFCDetail from "../home/TopRightPane/LineChartForFCDetail"
import { FormattedMessage, useIntl } from "react-intl"

const { defaultString: DEFAULT_STRING } = config
const Wrapper = styled(GlobalModal)`
  .ant-table-container {
    border: 0 !important;
  }
  .ant-modal-content {
    & > table {
      border: 0;
    }
    .ant-modal-body {
      padding: 0 32px;
    }
    .ant-modal-body {
      max-height: 544px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${props => props.theme.scrollBarThumb};
        border-radius: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: ${props => props.theme.scrollBarTrack};
      }
    }
  }
  .fcd-module {
    .fcd-module-title {
      font-size: 16px;
      font-weight: 500;
      color: ${props => props.theme.title};
      line-height: 24px;
    }
    & + .fcd-module {
      margin-top: 32px;
    }
  }
  .fcd-module.module-1 {
    margin-top: 12px;
    padding: 20px;
    background-color: ${props => props.theme.descBg};
    border-radius: 4px;
    font-size: 14px;
    .fcd-info-item {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      .fcd-info-key {
        color: ${props => props.theme.secondary};
        line-height: 1em;
      }
      .fcd-info-val {
        margin-top: 11px;
        color: ${props => props.theme.title};
        line-height: 1em;
      }
    }
    .ant-divider {
      margin: unset;
      height: 46px;
      background-color: ${props => props.theme.secondary};
    }
  }
  .fcd-module.module-2 {
    .fcd-history-chart {
      margin-top: -22px;
    }
  }
  .fcd-module.module-3 {
    .fcd-table-x {
      margin-top: 16px;
      margin-bottom: 28px;
      font-size: 14px;
      .ant-table {
        background-color: unset;
      }
      .ant-table-thead > tr > th {
        background-color: ${props => props.theme.descBg};
        color: ${props => props.theme.primary};
        border: 0;
      }
      .ant-table-tbody > tr > td {
        border-right: 0;
        border-color: ${props => props.theme.border};
        color: ${props => props.theme.title};
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        padding: 0;
        height: 48px;
      }
    }
  }
`

const COLUMNS = [
  {
    title: <FormattedMessage id="home.calendarPane.modal.table.thead1" />,
    dataIndex: "date",
    align: "center",
    width: "179px",
  },
  {
    title: <FormattedMessage id="home.calendarPane.modal.table.thead2" />,
    dataIndex: "previous",
    align: "center",
  },
  {
    title: <FormattedMessage id="home.calendarPane.modal.table.thead3" />,
    dataIndex: "consensus",
    align: "center",
  },
  {
    title: <FormattedMessage id="home.calendarPane.modal.table.thead4" />,
    dataIndex: "actual",
    align: "center",
  },
]
export default ({ data, handleEcoUnitVal, ...props }) => {
  // console.log(data)
  const intl = useIntl()
  const [detailData, setDetailData] = useState(null)
  const [nextAnnouncementTime, setNextAnnouncementTime] = useState(null)
  const convertDataForChart = data => {
    if (!Array.isArray(data)) return data
    let chartData = []
    data.map(item => {
      chartData = chartData.concat([
        {
          date: `${item.pub_time.split("-")[0]}年 ${item.time_period}`,
          value: Number(item.actual),
          type: "actual",
        },
        // {
        //   date: `${item.pub_time.split("-")[0]} ${item.time_period}`,
        //   value: Number(item.consensus),
        //   type: "consensus",
        // },
      ])
    })
    return chartData
  }
  const convertDataForTable = data => {
    return (
      Array.isArray(data) &&
      data.map(item => ({
        date: `${item.pub_time.split("-")[0]}年 ${item.time_period}`,
        actual: item.actual || DEFAULT_STRING,
        consensus: item.consensus || DEFAULT_STRING,
        previous: item.previous || DEFAULT_STRING,
      }))
    )
  }
  useEffect(() => {
    const getData = async () => {
      const { error, data: _detailData } = await post(apis.getEcoModalData, {
        name: data.name,
        country: data.country,
      })
      if (error)
        return message.error(
          intl.formatMessage({ id: "home.calendarPane.modal.error.getData" })
        )
      const { EconomicList, NextAnnouncementTime } = _detailData
      setDetailData(EconomicList)
      setNextAnnouncementTime(NextAnnouncementTime)
    }
    getData()
  }, [])

  return (
    <Wrapper
      title={data.title}
      width={771}
      height={620}
      footer={null}
      {...props}
    >
      <div className="fcd-module module-1 flex-jsb flex-ac">
        <div className="fcd-info-item">
          <span className="fcd-info-key">
            <FormattedMessage id="home.calendarPane.modal.infos.item1" />
          </span>
          <span className="fcd-info-val ff-din-bold">
            {data.pub_time.split(" ")[0]}
          </span>
        </div>
        <Divider type="vertical" />
        <div className="fcd-info-item">
          <span className="fcd-info-key">
            <FormattedMessage id="home.calendarPane.modal.infos.item2" />
          </span>
          <span className="fcd-info-val ff-din-bold">
            {moment(data.pub_time).isAfter(moment()) ? (
              <FormattedMessage id="home.calendarPane.modal.infos.noPub" />
            ) : (
              handleEcoUnitVal(data.actual, data.unit)
            )}
          </span>
        </div>
        <div className="fcd-info-item">
          <span className="fcd-info-key">
            <FormattedMessage id="home.calendarPane.modal.infos.item3" />
          </span>
          <span className="fcd-info-val ff-din-bold">
            {handleEcoUnitVal(data.consensus, data.unit)}
          </span>
        </div>
        <div className="fcd-info-item">
          <span className="fcd-info-key">
            <FormattedMessage id="home.calendarPane.modal.infos.item4" />
          </span>
          <span className="fcd-info-val ff-din-bold">
            {data.revised || handleEcoUnitVal(data.previous, data.unit)}
          </span>
        </div>
        <Divider type="vertical" />
        <div className="fcd-info-item">
          <span className="fcd-info-key">
            <FormattedMessage id="home.calendarPane.modal.infos.item5" />
          </span>
          <span className="fcd-info-val ff-din-bold">
            {nextAnnouncementTime || DEFAULT_STRING}
          </span>
        </div>
        <div className="fcd-info-item">
          <span className="fcd-info-key">
            <FormattedMessage id="home.calendarPane.modal.infos.item6" />
          </span>
          <span className="fcd-info-val">
            <Star star={data.star} />
          </span>
        </div>
      </div>
      <div className="fcd-module module-2">
        <p className="fcd-module-title">
          <FormattedMessage id="home.calendarPane.modal.subTitle2" />
        </p>
        <LineChartForFCDetail
          className="fcd-history-chart"
          data={convertDataForChart(detailData)}
        />
      </div>
      <div className="fcd-module module-3">
        <p className="fcd-module-title">
          <FormattedMessage id="home.calendarPane.modal.subTitle3" />
        </p>
        <Table
          className="fcd-table-x"
          rowKey={record => record.date}
          dataSource={convertDataForTable(detailData)}
          columns={COLUMNS}
          pagination={false}
          // bordered={true}
          loading={{
            spinning: detailData === null,
            indicator: <Loading />,
          }}
        />
      </div>
    </Wrapper>
  )
}
