// NOTE: 数据类型判断
function letterConvert(letter) {
  return letter.match(/\[object (\S*)\]/)[1].toLowerCase()
}
export const typeJudge = val => {
  // NOTE: undefined == null 返回 true
  if (val == null) return val + ""
  return typeof val === "object" || typeof val === "function"
    ? letterConvert(Object.prototype.toString.call(val))
    : typeof val
}
// -- 判断浏览器类型
export const isSafari = () => {
  return (
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]"
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && window?.safari.pushNotification)
    )
  )
}

// NOTE: 对象类
// --- 对象深层取值
export const deepGet = (obj, keys) =>
  keys.reduce(
    (xs, x) => (xs && xs[x] !== null && xs[x] !== undefined ? xs[x] : null),
    obj
  )
// -- 删除对象上某个属性
export const deleteObjProp = (obj, prop) => {
  if (obj[prop] && !obj[prop].length) {
    delete obj[prop]
  }
}

// NOTE:  数组类
// --- 交换数组两个值
export const exchangeArrayItem = (arr, index1, index2) => {
  arr.splice(index1, 1, ...arr.splice(index2, 1, arr[index1]))
  return arr
}
/** 给指定数组加上指定的 key 值
 * @param {Array} arr 需要遍历的数组
 * @param {Array} keyArr 指定 item 中哪一项值作为 key 值
 * @returns 加上 key 后的新数组
 */
export const handleKey = (arr, keyArr = [], callback = null) => {
  return arr.map((item, index) => ({
    ...item,
    key: !!callback
      ? callback(index)
      : (!!keyArr.length && deepGet(item, keyArr)) || index + 1,
  }))
}

// NOTE: 工具类
// --- 数字格式化：保留指定位数小数
export const toDecimal = (num, digits = 2) => {
  if (!Number(num)) return num
  num = num + ""
  digits = Number(digits)
  if (isNaN(num)) return "0." + "0".repeat(digits)
  // 取整数
  if (digits === 0) {
    return num.split(".")[0]
  }
  // 截取小数点后digits位
  if (num.indexOf(".") !== -1) {
    num = num.substring(0, num.indexOf(".") + digits + 1)
  } else {
    num += "."
  }
  while (digits - num.split(".")[1].length > 0) {
    num += "0"
  }
  return num
}
/** 获取字符串内的中文
 * @param {String} str 源数据
 * @returns 源数据中的中文
 */
export const getChOfString = str => {
  return str.match(/[\u4e00-\u9fa5]/g)
}
// 获取两位数的时分秒值
export const addZeroInFront = num => {
  return ("00" + num).slice(-2)
}
// 判断字符串是否以汉字开头
export const isChinese = str => {
  var reg = new RegExp(/^[\u4e00-\u9fa5]+/)
  return reg.test(str)
}

// NOTE: 网络路径类
// --- 判断是否是 url 路径
const reg = new RegExp(
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/
)
export const isUrl = path => reg.test(path)
export const getQueryString = name => {
  const reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i"),
    href = window.location.href,
    r = href.substr(href.indexOf("?") + 1).match(reg)
  // console.log(href, r)
  if (r != null) return r[2]
  return null
}

// DOM 操作类
// -- 页面跳转
export const scrollToAnchor = (anchorName, offset) => {
  if (!!anchorName) {
    let anchorElement = document.getElementById(anchorName)
    if (anchorElement) {
      window.scrollTo({
        left: 0,
        top: anchorElement.offsetTop - offset,
        behavior: "smooth",
      })
    }
  }
  // 没有的话，滚动到头部
  else {
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }
}

/* 防抖函数
 * fn [function] 需要防抖的函数
 * delay [number] 毫秒，防抖期限值
 */
export const debounce = (fn, delay) => {
  let timer = null //借助闭包
  return function () {
    if (timer) {
      clearTimeout(timer) //进入该分支语句，说明当前正在一个计时过程中，并且又触发了相同事件。所以要取消当前的计时，重新开始计时
      timer = setTimeout(fn, delay)
    } else {
      timer = setTimeout(fn, delay) // 进入该分支说明当前并没有在计时，那么就开始一个计时
    }
  }
}

/* 业务类 */
// -- 接口和图表库之间转换 resolution
export const convertResolution = (val, convertToPort = true) => {
  let res
  const supportedResolutions = ["M1", "M5", "M15", "M30", "H1", "H4", "D1"]
  const isNumber = !!Number(val)
  if (convertToPort) {
    // tv 的 resolution 转为接口的周期格式
    if (isNumber) {
      // 分钟数
      val = Number(val)
      res = val < 60 ? "M" + val : "H" + Math.floor(val / 60)
    } else {
      res = val.match(/[A-Z]/gi)[0] + parseInt(val)
    }
    if (!supportedResolutions.includes(res)) {
      console.log("当前 resolution 不被接口所支持")
      return
    }
  } else {
    // 接口的周期格式转为 tv 的 resolution
    if (!supportedResolutions.includes(val)) {
      console.log("当前 resolution 不被接口所支持")
      return
    }
    if (val.indexOf("M") !== -1) {
      res = val.match(/[0-9]+/gi)[0]
    } else if (val.indexOf("H") !== -1) {
      res = val.match(/[0-9]+/gi)[0] * 60
    } else {
      res = val.match(/[0-9]+/gi)[0] + val.match(/[A-Z]/gi)[0]
    }
  }
  return res
}
// -- 比较 redux 中的初始值配置 和 用户本地 redux-persist 缓存下来的值，并按照一定逻辑进行更新
export const updateInitialState = (
  sliceInitialState,
  userCacheInitialState
) => {
  /**
   * 更新 redux-persist 字段值。更新逻辑：
   * 1. 将 sliceInitialState 中新增的配置项添加至 userCacheInitialState 中
   * 2. 将 sliceInitialState 中删除的配置项同步从 userCacheInitialState 中删除
   * 3. 当前版本存在的配置项（以 sliceInitialState 为准），其值以用户本地为准 */
  for (let key of Object.keys(userCacheInitialState)) {
    if (!Object.keys(sliceInitialState).includes(key)) {
      delete userCacheInitialState[key]
    }
    delete sliceInitialState[key]
  }
  if (Object.keys(sliceInitialState).length) {
    userCacheInitialState = {
      ...userCacheInitialState,
      ...sliceInitialState,
    }
  }
  return userCacheInitialState
}
// -- 获取图表 tooltip 统一配置
export const setColorByTheme = (theme, lightVal, darkVal) => {
  return theme === "light" ? lightVal : darkVal
}
export const getChartTooltipCfg = themeContext => ({
  domStyles: {
    "g2-tooltip": {
      backgroundColor: themeContext.cardBg,
      boxShadow: `1px 2px 10px 0px ${setColorByTheme(
        themeContext.key,
        "rgba(0,0,0,0.1)",
        "rgba(0,0,0,0.3)"
      )}`,
      borderRadius: "4px",
      border: `1px solid ${themeContext.tooltipBorder}`,
    },
    "g2-tooltip-title": {
      color: themeContext.secondary,
    },
    "g2-tooltip-name": {
      color: themeContext.secondary,
    },
    "g2-tooltip-value": {
      fontFamily: "DINAlternate-Bold",
      color: themeContext.title,
    },
  },
})
// -- 获取图表 slider 统一配置
export const getChartSliderCfg = themeContext => ({
  backgroundStyle: {
    fill: themeContext.cardTitleBg,
    // fillOpacity: 0.4,
    // opacity: 0.8,
  },
  foregroundStyle: {
    fill: themeContext.sliderForeground,
    fillOpacity: 0.55,
    // opacity: 0.8,
  },
  handlerStyle: {
    width: 10,
    height: 24,
    fill: "transparent",
    stroke: themeContext.secondary,
    highLightFill: "transparent",
  },
  textStyle: {
    fontSize: 10,
    fill: themeContext.primary,
  },
  trendCfg: {
    smooth: true,
    isArea: false,
    lineStyle: {
      stroke: themeContext.secondary,
    },
  },
})
