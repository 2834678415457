import { Fragment } from "react"
import { Descriptions } from "antd"
import styled from "styled-components"
import GlobalModal from "./GlobalModal"
import symbolInfoJSON from "../../constants/symbolInfo.json"
import { addZeroInFront } from "../../utils/util"
import { FormattedMessage, useIntl } from "react-intl"
import config from "../../config/config"

const [, , enus] = config.locales.ids
// 处理本地 json 文件中保存的货币对详细信息【除交易时间外的所有字段来源】
const symbolInfo = {}
symbolInfoJSON.map(item => {
  symbolInfo[item.name.toUpperCase()] = { ...item }
  return item
})

const Wrapper = styled(GlobalModal)`
  .desc-x {
    .desc-title {
      margin: 16px 0;
      font-size: 14px;
      font-weight: 500;
      color: ${props => props.theme.title};
    }
    &.sessions .ant-descriptions-item-label {
      width: 6em;
    }
  }
`

export const SymbolInfoModal = ({ visible, onCancel, data }) => {
  const intl = useIntl()
  const WEEK = [
    intl.formatMessage({ id: "modal.symbolInfo.weeks.sun" }),
    intl.formatMessage({ id: "modal.symbolInfo.weeks.mon" }),
    intl.formatMessage({ id: "modal.symbolInfo.weeks.tues" }),
    intl.formatMessage({ id: "modal.symbolInfo.weeks.wed" }),
    intl.formatMessage({ id: "modal.symbolInfo.weeks.thurs" }),
    intl.formatMessage({ id: "modal.symbolInfo.weeks.fri" }),
    intl.formatMessage({ id: "modal.symbolInfo.weeks.sat" }),
  ]
  const infoData = Object.assign({}, symbolInfo[data.name.toUpperCase()], {
    sessions: data?.symbolInfo?.sessions || [],
  })
  const { field_list: fieldList, sessions } = infoData
  return (
    <Wrapper
      width={600}
      title={intl.locale !== enus ? data.cn_name : data.name}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      {!!fieldList?.length && (
        <div className="desc-x">
          <p className="desc-title">
            <FormattedMessage id="modal.symbolInfo.mTitle1" />
          </p>
          <Descriptions column={3} colon={false} layout="vertical" bordered>
            {fieldList.map(item => (
              <Descriptions.Item key={item.name} label={item.name}>
                {item.value}
              </Descriptions.Item>
            ))}
          </Descriptions>
        </div>
      )}
      {!!sessions?.length && (
        <div className="desc-x sessions">
          <p className="desc-title">
            <FormattedMessage id="modal.symbolInfo.mTitle2" />
          </p>
          <Descriptions column={1} colon={false} bordered>
            {sessions.map((item, idx) => {
              const { trade, week } = item
              return (
                <Descriptions.Item key={idx.toString()} label={WEEK[week]}>
                  {!!trade &&
                    trade.map((item, idx) => {
                      let {
                        open_hour: openHour,
                        open_min: openMin,
                        close_hour: closeHour,
                        close_min: closeMin,
                      } = item
                      return (
                        <Fragment key={idx.toString()}>
                          {addZeroInFront(openHour)}:{addZeroInFront(openMin)} -{" "}
                          {addZeroInFront(closeHour)}:{addZeroInFront(closeMin)}
                          ；
                        </Fragment>
                      )
                    })}
                </Descriptions.Item>
              )
            })}
          </Descriptions>
        </div>
      )}
    </Wrapper>
  )
}
