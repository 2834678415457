const login = {
  login: {
    left: {
      title: "歡迎訪問 webTrader !",
      desc: "下載 火象交易APP ，在手機上體驗同樣優質的交易服務",
      downloadBtnText: "火象交易APP",
      electron: {
        tip: "或者下載本應用桌面端",
        mac: "Mac 桌面端",
        win: "Windows 桌面端",
      },
    },
    form: {
      title: "登錄",
      userName: {
        label: "賬號",
        placeholder: "請輸入用戶名",
      },
      pwd: {
        label: "密碼",
        placeholder: "請輸入密碼",
      },
      createAccount: "沒有賬號？<a>創建一個 >></a>",
      btns: {
        submit: "登錄",
        reset: "重置",
      },
      message: {
        success: {
          signIn: "登錄成功",
          autoSignIn: "已為您自動登入賬號",
        },
        error: {
          valueInvalid: "賬號密碼錯誤",
          autoSignIn: "自動登錄失敗，請手動輸入",
        },
      },
    },
    message: {
      error: {
        network: "網絡錯誤",
      },
      success: {
        copyDownloadUrl: "下載頁地址已保存至剪貼板，請打開瀏覽器粘貼訪問",
      },
    },
  },
}
export default login
