import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import WRAPPER_IMG from "../assets/images/login_bg.jpg"

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: ${props => props.theme.background}; */
  background: url(${WRAPPER_IMG}) center center / auto 100% no-repeat;
  &::before {
    content: "";
    position: absolute;
    top: 16px;
    bottom: 16px;
    left: 16px;
    right: 16px;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 4px;
  }
  .nfp-title {
    font-size: 256px;
    font-weight: 500;
    color: ${props => props.theme.primary};
    text-shadow: 0 0 0.65px ${props => props.theme.cardBg};
  }
  .nfp-btn-back.nfp-btn-back {
    width: 128px;
    background-color: ${props => props.theme.theme};
    color: #ffffff;
    font-weight: 500;
  }
`

const NotFoundPage = () => {
  const navigate = useNavigate()
  return (
    <Wrapper>
      <p className="nfp-title">404</p>
      <Button
        className="initial no-trigger nfp-btn-back"
        onClick={() => navigate("/")}
      >
        回首页
      </Button>
    </Wrapper>
  )
}

export default NotFoundPage
