import { configureStore } from "@reduxjs/toolkit"
import SocketClient from "../services/SocketClient"
import socketMiddleware from "./socketMiddleware"
// 数据持久化的引入
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import rootReducer from "./reducer"
import config from "../config/config"
import { initialState as symbolInitialState } from "./symbolSlice"
import { initialState as klineInitialState } from "./klineSlice"
import { updateInitialState } from "../utils/util"

const { customDefaultConfig } = config
const initialState = {
  custom: { ...customDefaultConfig },
  symbols: { ...symbolInitialState },
  kline: { ...klineInitialState },
}

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["orders"], // 黑名单。orders will not be persisted
  // version: "0",
  migrate: state => {
    // console.log("==> state", state)
    if (!(state instanceof Object)) return Promise.resolve(state)
    const _state = Object.fromEntries(
      Object.entries(state).map(([key, value]) => {
        let newInitialState
        if (Object.keys(initialState).includes(key)) {
          newInitialState = updateInitialState(initialState[key], value)
        }
        return [key, newInitialState || value]
      })
    )
    return Promise.resolve(_state)
  },
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const socket = new SocketClient()
const middlewares = [socketMiddleware(socket)]
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(middlewares),
  // preloadedState: undefined,
})
if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./reducer", () => {
    // This fetch the new state of the above reducers.
    const nextRootReducer = require("./reducer").default
    store.replaceReducer(persistReducer(persistConfig, nextRootReducer))
  })
}
export const persistor = persistStore(store)

// export const configStore = preloadedState => {
//   const middlewares = [socketMiddleware]
//   const middlewareEnhancer = applyMiddleware(...middlewares)

//   const store = createStore(reducers, preloadedState, middlewareEnhancer)

//   // 热加载 reducer
//   if (process.env.NODE_ENV !== "production" && module.hot) {
//     module.hot.accept("./reducers", () => store.replaceReducer(rootReducer))
//   }

//   return store
// }
