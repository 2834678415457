import { Empty as _Empty } from "antd"
import { FormattedMessage } from "react-intl"
import styled from "styled-components"
import { IconFont } from "./IconFont"

const Wrapper = styled(_Empty)`
  /* height: ${props => (props.center ? "100%" : "unset")}; */
  ${props =>
    !!props.center &&
    `
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
  .ant-empty-image {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-empty {
      width: 32px;
      height: 32px;
      fill: ${props => props.theme.btnTextDisabled};
    }
  }
  .ant-empty-description {
    font-size: 12px;
    color: ${props => props.theme.btnTextDisabled};
  }
`

export const Empty = ({ center = false }) => {
  return (
    <Wrapper
      description={<FormattedMessage id="component.empty.description" />}
      image={<IconFont className="icon-empty" />}
      center={center ? 1 : 0} // NOTE: styled-components v5.1 开始
    ></Wrapper>
  )
}
