import { message } from "antd"
import qs from "querystring"
import config from "../config/config"
import { getInfoByAccount } from "../utils/userHelper"

const { baseUrl } = config
const STATUS_MSG = {
  200: "服务器成功返回请求的数据。",
  201: "新建或修改数据成功。",
  202: "一个请求已经进入后台排队（异步任务）。",
  // wt 接口专享
  204: "登录令牌为空",
  205: "登录令牌解析失败",
  206: "交易账户不存在",
  207: "登录令牌过期",
  400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
  // alpha 接口专享
  401: "用户没有权限（令牌、用户名、密码错误）。",
  403: "用户得到授权，但是访问是被禁止的。",
  404: "发出的请求针对的是不存在的记录，服务器没有进行操作。",
  406: "请求的格式不可得。",
  410: "请求的资源被永久删除，且不会再得到的。",
  422: "当创建一个对象时，发生一个验证错误。",
  500: "服务器发生错误，请检查服务器。",
  502: "网关错误。",
  503: "服务不可用，服务器暂时过载或维护。",
  504: "网关超时。",
}
const defaultOpts = {
  params: null,
  body: null,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
  // timeout: TIME_OUT,  // fetch 不支持超时判断？
  mode: "cors",
  cache: "no-cache",
}
// token 过期相关提示仅使当页第一个请求显示
let hadShowTokenTip = false

/**
 * @param {Boolean} isDemo 是否是模拟盘
 */
const getUrlPrefix = accType => {
  return baseUrl[accType]
}

const request = async (url, params, opts) => {
  try {
    let {
      method,
      headers,
      type, // 用于判断 headers 中的 Content-Type 类型，请求时需删除此参数
      body = {},
      isPrepareUrl = false,
    } = opts

    const isWTPort = !/^http(s?):\/\//i.test(url)
    const { type: accType, token } = getInfoByAccount()
    // 1. 处理 url
    if (!isPrepareUrl) {
      // 已在 apis.js 文件或其他地方预处理过的 url 直接使用
      if (typeof url !== "string") throw new TypeError("请求地址错误")
      // -- WT 专用接口需要加上前缀
      if (isWTPort) url = getUrlPrefix(accType) + url
      // -- 处理 GET 请求的 url
      if (method === "GET") {
        url = url + url.includes("?") ? "&" : "?" + qs.stringify(params)
      }
    }
    // 2. 处理 opts 中的 数据体 / 请求头 等
    if (/^(POST|PUT)$/i.test(method)) {
      type = !type ? "urlencoded" : null
      if (type === "urlencoded") {
        headers["Content-Type"] = "application/x-www-form-urlencoded"
        body = qs.stringify(params)
      }
      if (type === "json") {
        headers["Content-Type"] = "application/json"
        body = JSON.stringify(params)
      }
    }

    if (isWTPort) {
      headers["token"] = token
    }
    opts = Object.assign({}, defaultOpts, {
      ...opts,
      headers,
      body,
    })
    delete opts.type
    delete opts.isPrepareUrl
    method === "GET" && delete opts.body

    // 发起请求并进行相关验证
    const response = await fetch(url, opts)
    // console.log("===response", response)
    const { status, statusText } = response
    // 1. 响应状态码非 [201,499] 区间的直接判定请求错误，根据 STATUS_MSG 抛出错误原因
    if (status < 200 || status > 499) {
      throw new Error(statusText || STATUS_MSG[status] || "未知错误")
    }
    const json = await response.json()
    // console.log("===json", json)
    // 2. 返回数据的处理（对不同来源接口进行针对化处理）
    // 2.1 金十数据相关接口直接返回 json
    if (url.indexOf("jin10") !== -1) return { data: json, error: null }
    // 2.2 区分 alpha 的接口 和 trader 的专用接口（前缀和返回数据结构不一致）
    const { data, msg, status: alphaStatus, code: wtStatus } = json
    // 2.2.1 token 错误（token 出错一律返回到登录页，抛出错误原因，统一在 catch 中返回给组件）
    if (
      (!isWTPort && alphaStatus === 401) ||
      (isWTPort && wtStatus >= 204 && wtStatus <= 207)
    ) {
      const tokenWrongMsg = "登录过期" // msg || STATUS_MSG?.[wtStatus] ||
      if (hadShowTokenTip) {
        return { error: tokenWrongMsg }
      }
      hadShowTokenTip = true
      // NOTE: 登录页有自己的提示文本，所以这里将其排除
      const { pathname } = window.location
      if (pathname.indexOf("login") === -1) {
        message.error(tokenWrongMsg)
      }
      setTimeout(() => {
        window.location.href = "#/login"
      }, 1000)
      // alphaStatus 为 401 时约定会有 msg 信息
      throw new Error(tokenWrongMsg)
    }
    // 2.2.2 除 token 相关错误外其余接口均以 0 -> success; 1 -> failed 表示结果
    // console.log(url, alphaStatus, wtStatus)
    const jsonStatus = alphaStatus || wtStatus
    if (!jsonStatus) throw new Error(msg || "网络请求失败")
    return { data, error: null }
  } catch (e) {
    // console.log("===fetch error", e.message)
    return { error: e.message }
  }
}

export async function get(url, params, opts = { headers: {} }) {
  return await request(url, params, Object.assign({}, opts, { method: "GET" }))
}
export async function post(url, params, opts = { headers: {}, body: {} }) {
  return await request(url, params, Object.assign({}, opts, { method: "POST" }))
}

// const HttpUtil = {

//   getHeaders: () => {
//     return {
//       headers: {
//         'Content-Type': 'application/x-www-form-urlencoded'
//       }
//     }
//   },
//   /**
//    * @param {Boolean} isDemo 是否是模拟盘
//    */
//   getUrlPrefix: (isDemo = true) => {
//     return baseUrl[!!isDemo ? 'demo' : 'live']
//   },
//   // 对请求业务进行封装
//   handleFetchData: async (url, opts) => {

//     try {
//       const response = await fetch(url, opts)
//       console.log("handleFetchData response: ", response);
//       const json = await response.json()
//       console.log("handleFetchData json: ", json);
//     } catch (e) {
//       console.log("handleFetchData error: ", e);
//     }

//   },

//   // 对请求方法进行封装
//   get: (url, params = {}) => {
//     const method = 'GET'
//     const fetchUrl = HttpUtil.getUrlPrefix() + url + qs.stringify(params)
//     const fetchParams = Object.assign({}, { method }, HttpUtil.getHeaders())
//     return HttpUtil.handleFetchData(fetchUrl, fetchParams)
//   },
//   post: (url, params = {}) => {
//     const method = 'POST'

//     const body = JSON.stringify(params)
//     const fetchUrl = HttpUtil.getUrlPrefix() + url
//     const fetchParams = Object.assign({}, { method, body }, HttpUtil.getHeaders())
//     return HttpUtil.handleFetchData(fetchUrl, fetchParams)
//   },
// }

// export default HttpUtil

// const fetchAction = async (url, params, opts) => {

//   opts = Object.assign({}, defaultOpts, opts)
//   let { method } = opts
//   // 检查 method && 根据 method 做数据体的处理
//   if(!CAN_SEND_METHOD.includes(method)) {
//     throw new Error('请求方法错误！')
//   }
//   if(method.toUpperCase() === 'GET') {
//     opts.body = qs.stringify(params)
//   } else if(method.toUpperCase() === 'POST') {
//     opts.body = JSON.stringify(params)
//   }

//   try {
//     const response = await fetch(url, opts)
//     // 判断请求状态码
//     // const { status, statusText } = response
//     console.log("===response", response);
//     const json = await response.json()
//     console.log("===json", json);
//   } catch (e) {
//     console.log("===fetch error:", e);
//   }

// }

// export default fetchAction
