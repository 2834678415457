// import { logInDev } from "../../../helper/envHelper"
import { send, subscribe, unsubscribe } from "../../store/socketActions"
import { store } from "../../store/store"
import { convertResolution } from "../../utils/util"

const channelToSubscription = new Map()

const { dispatch } = store
export function subscribeOnStream(
  symbolInfo,
  resolution,
  onTick,
  listenerGuid,
  onResetCacheNeededCallback
) {
  // console.log("==> subscribeOnStream", wsIns)
  try {
    const { name } = symbolInfo
    // 作为监听ID使用，需要保证唯一性
    const channelID = `${name}-${resolution}`
    const handler = {
      id: listenerGuid,
      callback: onTick,
    }
    let subscribeItem = channelToSubscription.get(channelID)
    if (subscribeItem) {
      subscribeItem.handlers.push(handler)
      return
    }
    subscribeItem = {
      listenerGuid, // TODO: 不确定官方DEMO里 listenerGuid 和 resolution 放在这里的作用
      name,
      resolution,
      handlers: [handler],
    }
    channelToSubscription.set(channelID, subscribeItem)
    // console.log("[subscribeBars]: Subscribe to streaming. Channel: ", channelID)
    // 向后台发起该商品指定周期的订阅
    dispatch(
      send({
        cmd: "sub",
        args: [`candle.${convertResolution(resolution)}.${name}`],
      })
    )
    // 将数据绑定到回调函数 updateBars
    dispatch(subscribe({ evtName: "update", cb: updateBars }))
  } catch (e) {
    console.log(`[subscribeBars]: something wrong with subscribeBars: ${e}`)
  }
}
export function unsubscribeFromStream(listenerGuid, wsIns) {
  try {
    for (let channelID of channelToSubscription.keys()) {
      const subscribeItem = channelToSubscription.get(channelID)

      const handlers = subscribeItem.handlers
      const handlerIdx = handlers.findIndex(
        handler => handler.id === listenerGuid
      )
      if (handlerIdx !== -1) {
        handlers.splice(handlerIdx, 1)
        if (!handlers.length) {
          // 取消当前商品对应周期的订阅信息
          // NOTE: 接口原因仅会推送最新一次订阅的数据（自动取消上一次订阅），所以这里不用额外取消订阅
          // wsIns.send({
          //   cmd: "unsub",
          //   args: [
          //     `candle.${convertResolution(subscribeItem.resolution)}.${
          //       subscribeItem.name
          //     }`,
          //   ],
          // })
          // 将当前 channelID 对应的监听删掉
          channelToSubscription.delete(channelID)
          break
        }
      }
    }
    // TODO: 根据接口特殊性（新的订阅直接覆盖之前的订阅前文就没有取消 websocket 订阅），这里需要额外判断当 channelToSubscription 内没有任何监听事件时需要取消 websocket 的监听处理事件
    // if (!channelToSubscription.size) {
    // dispatch(
    //   send({
    //     cmd: "unsub",
    //     args: [`candle.M1.ALIBABA`],
    //   })
    // )
    //   dispatch(unsubscribe({ evtName: "update", cb: updateBars }))
    // }
  } catch (e) {
    console.error(
      "[unsubscribeBars]: something wrong with unsubscribeFromStream: ",
      e
    )
  }
}
function updateBars(data) {
  // console.log(
  //   `[updateBars]: Receiving new bars: ${JSON.stringify(data, null, 2)}`
  // )
  // bar 的数据格式是图表库定死的，请务必按照此格式处理返回数据

  const [, portResolution, name] = data.type.split(".")
  // 从返回数据中获取当前收到的这条数据所属商品的 channelID
  const channelID = `${name}-${convertResolution(portResolution, false)}`
  // 从本地 Map 数据中通过唯一 channelID 获取到对应订阅记录对象 subscribeItem
  const subscribeItem = channelToSubscription.get(channelID)
  // const { lastDailyBar } = subscribeItem
  // const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time)
  // let newBar
  // const diffTime = data.id + 28800
  // if (diffTime >= nextDailyBarTime) {
  //   newBar = {
  //     time: nextDailyBarTime,
  //     open: data.open,
  //     high: data.high,
  //     low: data.low,
  //     close: data.close,
  //     volume: data.volume,
  //   }
  //   console.log("[socket] Generate new bar", newBar)
  // } else {
  //   newBar = {
  //     ...lastDailyBar,
  //     high: Math.max(lastDailyBar.high, data.high),
  //     low: Math.min(lastDailyBar.low, data.low),
  //     close: data.close,
  //   }
  //   console.log("[socket] Update the latest bar by price")
  // }

  const diffTime = (data.id + 28800) * 1000
  const newBar = {
    // ...lastDailyBar,
    time: diffTime, // data.id * 1000,
    close: data.close,
    high: data.high,
    low: data.low,
    open: data.open,
    volume: data.volume,
  }

  // subscribeItem.lastDailyBar = newBar
  if (Array.isArray(subscribeItem?.handlers)) {
    // 将收到的数据发送给当前订阅记录对象的 handlers 依次执行
    subscribeItem.handlers.forEach(handler => {
      // console.log("[updateBars]: Receive new data of bar: ", newBar, handler)
      handler.callback(newBar)
    })
  }
}
// function getNextDailyBarTime(barTime) {
//   const date = new Date(barTime * 1000)
//   date.setDate(date.getDate() + 1)
//   return date.getTime() / 1000
// }
