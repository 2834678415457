const login = {
  login: {
    left: {
      title: "We are glad to see you again!",
      desc: "Download APP to experience the same quality trading services on your mobile phone",
      downloadBtnText: "Download APP",
      electron: {
        tip: "Or download the application on the desktop",
        mac: "Desktop App for MacOs",
        win: "Desktop App for Windows",
      },
    },
    form: {
      title: "Sign in",
      userName: {
        label: "Account",
        placeholder: "please enter your account",
      },
      pwd: {
        label: "Password",
        placeholder: "please enter your password",
      },
      createAccount: "No account? <a>Let's create it >></a>",
      btns: {
        submit: "Sign in",
        reset: "Reset",
      },
      message: {
        success: {
          signIn: "Sign in successfully",
          autoSignIn: "Automatic sign in successfully",
        },
        error: {
          valueInvalid: "Wrong username or password",
          autoSignIn: "Automatic sign in failed, please sign in manually",
        },
      },
    },
    message: {
      error: {
        network: "Network Error",
      },
      success: {
        copyDownloadUrl:
          "Download page address has been saved to the clipboard, please open your browser paste access",
      },
    },
  },
}

export default login
