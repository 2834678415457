import EventCenter from "./socketEventCenter"
import { message } from "antd"

export default class SocketClient {
  // 要连接的URL
  url
  // 一个协议字符串或一个协议字符串数组。
  // 这些字符串用来指定子协议，这样一个服务器就可以实现多个WebSocket子协议
  protocols
  openCb = null
  // WebSocket 实例
  socket
  // 是否在重连中
  isReconnectionLoading = false
  // 延时重连的 id
  timeId = null
  // 是否是用户手动关闭连接
  isCustomClose = false
  // 错误消息队列
  errorStack = []
  // 消息管理中心
  eventCenter = new EventCenter()

  // constructor(url, protocols) {
  //   this.url = url
  //   this.protocols = protocols
  //   this.initSocket()
  // }
  // constructor() {}

  // 更新实例的连接配置项
  changeSocketOpts(url, openCb, protocols) {
    this.url = url
    openCb && (this.openCb = openCb)
    protocols && (this.protocols = protocols)
  }
  initSocket() {
    if ("WebSocket" in window || "MozWebSocket" in window) {
      const BrowserWebSocket = window.WebSocket || window.MozWebSocket
      // 实例化
      this.socket = new BrowserWebSocket(this.url, this.protocols)
      !this.eventCenter && (this.eventCenter = new EventCenter())
      // 监听事件
      this.onopen(this.openCb)
      this.onerror()
      this.onclose()
      this.onmessage()
    } else {
      // console.log("你的浏览器不支持 WebSocket")
      message.error("您的浏览器不支持 WebSocket，请更换浏览器重试")
    }
  }

  // 监听成功
  onopen(cb) {
    this.socket.onopen = () => {
      // 发送成功连接之前所发送失败的消息
      console.log("socket 连接成功")
      cb && cb()
      this.errorStack.forEach(message => {
        this.send(message)
      })
      this.errorStack = []
      this.isReconnectionLoading = false
    }
  }

  // 监听错误
  onerror() {
    this.socket.onerror = err => {
      console.log(err, "onerror")
      this.reconnection()
      this.isReconnectionLoading = false
    }
  }

  // 监听关闭
  onclose() {
    this.socket.onclose = () => {
      console.log("onclose")
      // 用户手动关闭的不重连
      if (this.isCustomClose) return

      this.reconnection()
      this.isReconnectionLoading = false
    }
  }

  // 接收 WebSocket 消息
  async onmessage() {
    this.socket.onmessage = event => {
      try {
        const data = JSON.parse(event.data)
        this.eventCenter.emit(data.type, data.data)
      } catch (error) {
        console.log("error", error)
      }
    }
  }

  // 重连
  reconnection() {
    // 防止重复
    if (this.isReconnectionLoading) return
    console.log("==> socket reconnection")
    this.isReconnectionLoading = true
    clearTimeout(this.timeId)
    this.timeId = setTimeout(() => {
      this.initSocket()
    }, 3000)
  }

  // 发送消息
  send(message) {
    // 连接失败时的处理
    if (this.socket.readyState !== 1) {
      this.errorStack.push(message)
      return
    }
    // console.log(message)
    this.socket.send(JSON.stringify(message))
  }

  // 手动关闭
  close() {
    this.isCustomClose = true
    this.socket && this.socket.close()
  }

  // 手动开启
  start() {
    this.isCustomClose = false
    this.reconnection()
  }

  // 订阅
  subscribe(eventName, cb) {
    this.eventCenter.on(eventName, cb)
  }
  // 仅订阅一次的事件
  subscribeOnce(eventName, cb) {
    this.eventCenter.once(eventName, cb)
  }

  // 取消订阅
  unsubscribe(eventName, cb) {
    this.eventCenter.off(eventName, cb)
  }

  // 销毁
  destroy() {
    console.log("==> 销毁 websocket")
    this.close()
    this.socket = null
    this.errorStack = null
    this.eventCenter = null
  }
}
