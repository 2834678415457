import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit"
// import config from "../constants/config"
import apis from "../services/apis"
import { post } from "../services/request"
import { deepGet, toDecimal } from "../utils/util"

// const [IDLE, PENDING, FULFILLED, REJECTED] = config.fetchStatus
// NOTE: 适配器创建
const symbolsAdapter = createEntityAdapter({
  selectId: symbols => symbols.name,
})
// NOTE: async actions
export const fetchSymbolInfo = createAsyncThunk(
  "symbols/fetchSymbolInfo",
  async name => {
    const { error, data } = await post(apis.getSymbolInfo, { name })
    if (error) return Promise.reject(error)
    return data
  }
)

export const initialState = symbolsAdapter.getInitialState({
  favoriteIds: [],
  groupInfo: {
    inlandGroups: [],
    intlGroups: [],
    groupsEntities: {}, // 分组信息
  },
})
// NOTE: slice 主体
const symbolSlice = createSlice({
  name: "symbols",
  initialState,
  reducers: {
    // 初始化币种数据
    initSymbols: (state, action) => {
      let symbols = action.payload
      // 固定分组，详见 config.js 的 symbolGroups 字段
      const inlandGroups = new Set(),
        intlGroups = new Set(),
        groupsEntities = {}
      // 根据应用的分类依据重新更新币种的 group 字段
      symbols = symbols.map(item => {
        let { group, name } = item
        // 期货的处理建立在 [国际交易-债券和国际期货] 类别下没有期货数据
        if (group.indexOf("期货") !== -1) {
          group = group.split("|")[1] || "其他"
          group = group === "INE" ? "其他" : group
          inlandGroups.add(group)
        } else if (group === "债券") {
          group = "债券和国际期货"
          intlGroups.add(group)
        } else if (group === "指数") {
          group = "指数和合约"
          intlGroups.add(group)
        } else if (group === "FOREX") {
          group = "货币"
          intlGroups.add(group)
        } else if (group === "美股") {
          group = "股票"
          intlGroups.add(group)
        }

        if (group in groupsEntities) {
          groupsEntities[group].push(name)
        } else {
          groupsEntities[group] = [name]
        }
        return { ...item, group }
      })
      // 将本次获取到的货币信息分组数据存放在 state
      state.groupInfo.inlandGroups = [...inlandGroups]
      state.groupInfo.intlGroups = [...intlGroups]
      state.groupInfo.groupsEntities = groupsEntities
      // console.log(symbols)
      // TODO: 需要注意一下 initSymbol 和 updateSymbols 方法的调用时机
      // symbolsAdapter.updateMany(state, symbols) // NOTE: 更新为 updateMany 防止 updateSymbol 在 initSymbols 前执行时结果值被覆盖
      symbolsAdapter.setAll(state, symbols)
    },
    updateSymbol: (state, action) => {
      symbolsAdapter.upsertOne(state, { ...action.payload })
    },
    updateSymbols: (state, action) => {
      // NOTE: upsertXX: 存在则更新，不存在则添加； updateXX: 直接更新
      symbolsAdapter.upsertMany(state, { ...action.payload })
    },
    // 更新自选列表
    updateFavorites: (state, action) => {
      const name = action.payload
      if (!state.favoriteIds.includes(name)) {
        state.favoriteIds.push(name)
        return
      }
      const idx = state.favoriteIds.indexOf(name)
      state.favoriteIds.splice(idx, 1)
    },
  },
  extraReducers: {
    // 请求币种的 symbol_info 接口并更新相关数据
    // [fetchSymbolInfo.pending]: (state, action) => {
    //   console.log("==pending", state, action)
    //   const symbol = deepGet(action, ["meta", "arg"])
    //   symbolsAdapter.upsertOne(state, { name: symbol, status: PENDING })
    //   // state.symbolInfo[symbol] = {}
    //   // state.symbolInfo[symbol].status = PENDING
    // },
    [fetchSymbolInfo.fulfilled]: (state, action) => {
      const symbol = deepGet(action, ["meta", "arg"])
      symbolsAdapter.upsertOne(state, {
        name: action.payload.symbol,
        // status: FULFILLED,
        symbolInfo: action.payload,
      })

      // state.symbolInfo[symbol].status = FULFILLED
      // state.symbolInfo[symbol].data = action.payload
    },
    // [fetchSymbolInfo.rejected]: (state, action) => {
    //   console.log(state, action)
    //   const symbol = deepGet(action, ["meta", "arg"])
    //   symbolsAdapter.upsertOne(state, { name: symbol, status: REJECTED })

    //   // state.symbolInfo[symbol].status = REJECTED
    // },
  },
})
export const { initSymbols, updateSymbol, updateSymbols, updateFavorites } =
  symbolSlice.actions
export default symbolSlice.reducer

// NOTE: 手写的 async
// --- 在更新对应币种的报价相关数据前做一些额外的处理，如：计算 涨跌幅 等
// TEMP: 暂无用处
// export const updateSymbolByName = data => (dispatch, getState) => {
//   const { holc = {} } = selectSymbolByName(getState(), data.symbol)
//   data.name = data.symbol
//   delete data.symbol
//   data.spread =
//     holc?.open && toDecimal(((data.bid - holc?.open) * 100) / holc?.open, 2)
//   // 处理小数位显示
//   const digits = data.digits
//   data.bid = toDecimal(data.bid, digits)
//   data.ask = toDecimal(data.ask, digits)
//   dispatch(updateSymbol({ ...data })) // 必须传递一个新的 data 数据
// }
// 根据传递过来的 symbols 批量更新对应报价信息
export const updateSymbolsByNames = data => (dispatch, getState) => {
  // console.log("==========", data)
  data = data.map(item => {
    const res = selectSymbolByName(getState(), item?.name || item?.symbol)
    const { holc = {} } = res
    item.name = item.symbol
    item.spread =
      holc?.open && toDecimal(((item.bid - holc?.open) * 100) / holc?.open, 2)
    // 处理小数位显示
    const digits = item.digits
    item.bid = toDecimal(item.bid, digits)
    item.ask = toDecimal(item.ask, digits)
    return item
  })
  dispatch(updateSymbols({ ...data })) // 必须传递一个新的 data 数据
}

// NOTE: selectors
export const {
  selectAll: selectAllSymbols,
  selectById: selectSymbolByName,
  selectIds: selectSymbolNames,
} = symbolsAdapter.getSelectors(state => state.symbols)
export const selectSymbolByNames = (state, names) => {
  if (!names?.length) return []
  const symbols = {}
  names.map(item => {
    symbols[item] = selectSymbolByName(state, item)
    return item
  })
  return symbols
}

// -- 获取 groupsInfo 信息
export const selectGroupInfo = state => state.symbols.groupInfo
// -- 获取 groupInfo 数据实体中对应分组下的币种 name 列表
export const selectNamesByGroup = (state, group) =>
  state.symbols.groupInfo.groupsEntities[group]
// -- 获取自选币种信息列表（过滤掉当前账户不能交易的品种）
export const selectFavorites = createSelector(
  selectSymbolNames,
  state => state.symbols.favoriteIds,
  (symbolNames, favoriteNames) =>
    (favoriteNames || []).filter(item => symbolNames.includes(item))
)
// 获取 kline 处需要的数据（刨除报价相关数据字段）
export const selectSymbolForKline = (state, name) => {
  const { cn_name, contract_size, digits, group, size, symbol, symbolInfo } =
    selectSymbolByName(state, name)
  return {
    cn_name,
    contract_size,
    digits,
    group,
    size,
    symbol,
    symbolInfo,
  }
}
