import { useEffect } from "react"
import { HashRouter as Router, Route, Routes } from "react-router-dom"
import { ConfigProvider, notification, Image, message } from "antd"
import styled from "styled-components"
import HomePage from "./pages/home"
import LoginPage from "./pages/login"
import SettingsPage from "./pages/settings"
import NotFoundPage from "./pages/404"
import { Empty } from "./components/common/Empty"
import { ThemeProvider } from "styled-components"
import colors from "./constants/colors"
import strings from "./constants/strings"
import { ModalStore } from "react-modal-store"
import { GlobalStyle } from "./assets/styles/GlobalStyle"
import { SymbolInfoModal } from "./components/modals/SymbolInfoModal"
import { AccInfoTipModal } from "./components/modals/AccInfoTipModal"
import { OrderEditModal } from "./components/modals/OrderEditModal"
import { OrderTradeModal } from "./components/modals/OrderTradeModal"
import { useSelector } from "react-redux"
import { selectCustomItem } from "./store/customSlice"
import { IntlProvider } from "react-intl"
import enUS from "antd/lib/locale/en_US"
import zhCN from "antd/lib/locale/zh_CN"
import zhHK from "antd/lib/locale/zh_HK"
import "moment/locale/zh-cn"
import "moment/locale/zh-hk"
import zh_CN from "./assets/locales/zh-CN"
import zh_HK from "./assets/locales/zh-HK"
import en_US from "./assets/locales/en-US"
import flatten from "flat"
import config from "./config/config"
import { SettingsModal } from "./components/modals/SettingsModal"
import DOWNLOAD_APP from "./assets/images/download_app.png"
import { getQueryString } from "./utils/util"
import { getStorage, setStorage } from "./utils/storageHelper"
import FinanceCalendarDetailModal from "./components/modals/FinanceCalendarDetailModal"

const { locales } = config
const [zhcn, zhhk, enus] = locales.ids
// 全局弹窗配置
const modalMap = {
  // eslint-disable-next-line react/display-name
  [strings.modalKeySymbolInfo]: props => <SymbolInfoModal {...props} />,
  // eslint-disable-next-line react/display-name
  [strings.modalKeyAccInfoTip]: props => <AccInfoTipModal {...props} />,
  // eslint-disable-next-line react/display-name
  [strings.modalKeyOrderEdit]: props => <OrderEditModal {...props} />,
  // eslint-disable-next-line react/display-name
  [strings.modalKeyOrderTrade]: props => <OrderTradeModal {...props} />,
  // eslint-disable-next-line react/display-name
  [strings.modalKeySettings]: props => <SettingsModal {...props} />,
  // eslint-disable-next-line react/display-name
  [strings.modalKeyFinanceCalendarDetail]: props => (
    <FinanceCalendarDetailModal {...props} />
  ),
  // eslint-disable-next-line react/display-name
  // [strings.modalKeyConfirm]: props => <ConfirmModal {...props} />,
}
const localeData = {
  [zhcn]: {
    message: flatten(zh_CN),
    antd: zhCN,
  },
  [zhhk]: {
    message: flatten(zh_HK),
    antd: zhHK,
  },
  [enus]: {
    message: flatten(en_US),
    antd: enUS,
  },
}
const DeviceDetectionWrapper = styled.div`
  .dd-download-x {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .dd-download-tip {
      margin-top: 8px;
      line-height: 2em;
    }
  }
  .ant-image {
    width: 120px;
    height: 120px;
  }
`
const DeviceDetection = ({ deviceType }) => (
  <DeviceDetectionWrapper>
    {(deviceType > 0 || deviceType === -1) && (
      <p>
        检测到您当前的访问设备为移动端设备，不能获得本站的最佳浏览效果，如有需要请根据需求选择以下地址进行访问：
      </p>
    )}
    {deviceType === 0 && (
      <>
        <p>请保证当前访问窗口大小在 1200 像素以上，以获得本站最佳浏览效果。</p>
        <p>如有需要请根据需求选择以下地址进行访问：</p>
      </>
    )}
    <div className="dd-download-x">
      <Image src={DOWNLOAD_APP} />
      <span className="dd-download-tip">火象交易APP</span>
    </div>
  </DeviceDetectionWrapper>
)

function App() {
  const thirdPartProjectName =
    getQueryString("s") ||
    getStorage(strings.storageKeyThirdPartProjectName) ||
    "default"
  // console.log(thirdPartProjectName, colors[thirdPartProjectName])
  const theme = useSelector(state =>
    selectCustomItem(state, strings.customConfigKeyTheme)
  )
  const locale = useSelector(state =>
    selectCustomItem(state, strings.customConfigKeyLocale)
  )

  useEffect(() => {
    // 网络状态检测
    if (!navigator.onLine) {
      message.error(localeData[locale].message["login.message.error.network"])
    }
    // 友好度提示弹窗的设备&&PC窗口宽度检测
    const deviceDetection = () => {
      const UA = navigator.userAgent,
        isMobile =
          /Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini/i.test(
            UA
          ),
        isAndroid = UA.indexOf("Android") > -1 || UA.indexOf("Adr") > -1, //android终端
        isIOS = !!UA.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      const clientWidth = document.body.clientWidth
      let deviceType
      if (!UA) {
        // App
        deviceType = -1
      } else if (!isMobile) {
        // PC
        deviceType = 0
      } else if (isMobile && isAndroid) {
        // Android
        deviceType = 1
      } else if (isMobile && isIOS) {
        // IOS
        deviceType = 2
      }
      if (!deviceType && clientWidth >= 1200) {
        return {
          deviceType,
          showTip: false,
        }
      }
      return {
        deviceType,
        showTip: true,
      }
    }
    const { deviceType, showTip } = deviceDetection()
    if (showTip) {
      notification["warning"]({
        message: "访问友好度提示",
        description: <DeviceDetection deviceType={deviceType} />,
        duration: 100000,
      })
    }
  }, [])

  // TEMP: 暂时没有发现这个的作用
  // useEffect(() => {
  //   if (locale.indexOf("zh") !== -1) {
  //     moment.locale(locale.toLowerCase())
  //   } else {
  //     console.log("==> locale changed", locale)
  //     moment.locale("en")
  //   }
  // }, [locale])
  // useEffect(() => {
  //   document.onreadystatechange = () => {
  //     console.log(document.readyState)
  //   }
  // }, [])

  // console.log("==> App render", theme, locale)

  return (
    <div className="App">
      <ThemeProvider theme={colors[thirdPartProjectName][theme]}>
        <GlobalStyle />
        <IntlProvider
          defaultLocale={locales.ids[0]}
          locale={locale}
          messages={localeData[locale]?.message}
        >
          <ModalStore modalMap={modalMap} destroyOnClose="afterClose">
            <ConfigProvider
              renderEmpty={() => <Empty />}
              locale={localeData[locale]?.antd}
            >
              <Router>
                <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/settings" element={<SettingsPage />} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
              </Router>
            </ConfigProvider>
          </ModalStore>
        </IntlProvider>
      </ThemeProvider>
    </div>
  )
}

export default App
