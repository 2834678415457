import { useModal } from "react-modal-store"

// 弹出 ModalStore 中配置的某个弹窗
export const useGlobalModal = modalName => {
  const dispatchModal = useModal()

  const showModal = (opts = {}) => dispatchModal(modalName, opts)

  return showModal
}
