import { createSlice } from "@reduxjs/toolkit"
import config from "../config/config"

const { customDefaultConfig } = config

const customSlice = createSlice({
  name: "custom",
  initialState: customDefaultConfig,
  reducers: {
    setCustomItem: (state, action) => {
      const { key, value } = action.payload
      state[key] = value
    },
  },
})
export const { initCustom, setCustomItem } = customSlice.actions
export default customSlice.reducer

// NOTE: selectors
export const selectCustomItem = (state, key) => {
  return state.custom[key]
}
