import { Tabs } from "antd"
import styled from "styled-components"
import { IconFont } from "./IconFont"

const { TabPane } = Tabs

const Wrapper = styled(Tabs)`
  height: 100%;
  .ant-tabs-content-holder {
    background-color: ${props => props.theme.cardBg};
  }
  .ant-tabs-content {
    height: 100%;
    padding: 8px;
  }
  &.ant-tabs-card > .ant-tabs-nav {
    height: 32px;
    margin-bottom: 0;
    &::before {
      border-bottom: 0;
    }
    .ant-tabs-tab {
      width: 110px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: ${props => props.theme.theme};
      font-size: 13px;
      font-weight: 700;
      color: ${props => props.theme.white};
      border: 0;
      &.ant-tabs-tab-active {
        background-color: ${props => props.theme.cardBg};
        .ant-tabs-tab-btn {
          color: ${props => props.theme.theme};
        }
      }
      & + .ant-tabs-tab {
        margin-left: 0;
      }
    }
    /* 选中效果 */
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
    .ant-tabs-tab-btn:focus,
    .ant-tabs-tab-btn:active {
      color: ${props => props.theme.theme};
      text-shadow: initial;
    }
  }
  /* 目前仅 k线 部分用到了，故将样式暂时写到 TopRightPane 里 */
  &.ant-tabs-editable-card {
    .icon-delete {
      width: 12px;
      height: 100%;
      box-sizing: content-box;
      fill: ${props => props.theme.secondary};
      &:hover {
        fill: ${props => props.theme.primary};
      }
    }
  }
`

const CardTabs = ({ defaultActiveKey = null, tabs = [], ...props }) => {
  // console.log("==> CardTabs", tabs, props)
  defaultActiveKey = defaultActiveKey || tabs[0]?.key
  defaultActiveKey && (props.defaultActiveKey = defaultActiveKey)
  const isEditable = tabs[0]?.closable !== undefined

  return (
    <Wrapper
      // defaultActiveKey={defaultActiveKey}
      type={isEditable ? "editable-card" : "card"}
      {...props}
    >
      {tabs.map(item => (
        <TabPane
          tab={item.tab}
          key={item.key}
          closable={item?.closable}
          closeIcon={<IconFont className="icon-delete" />}
        >
          {item.content}
        </TabPane>
      ))}
    </Wrapper>
  )
}

export { CardTabs as default, Wrapper as CardTabsWrapper }
