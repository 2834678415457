import { combineReducers } from "@reduxjs/toolkit"
import symbolReducer from "./symbolSlice"
import klineReducer from "./klineSlice"
import orderReducer from "./orderSlice"
import customReducer from "./customSlice"

// NOTE: 为了配合热重载 && 更好的组织代码，将 reducer 单独配置
// export default combineReducers({
const appReducer = combineReducers({
  symbols: symbolReducer,
  kline: klineReducer,
  orders: orderReducer,
  custom: customReducer,
})
const rootReducer = (state, action) => {
  // action.type === "USER_LOGOUT" && console.log("==> rootReducer", state)
  // if (action.type === "USER_LOGOUT") {
  //   // return appReducer(initialRootState, action)
  //   state = {...state, orders: []}
  // }

  return appReducer(state, action)
}
export default rootReducer
