import { useState, useEffect } from "react"
import moment from "moment"
import styled from "styled-components"
import { IconFont } from "../common/IconFont"
import { Button, Dropdown, Menu, Select } from "antd"
import { getStorage, setStorage } from "../../utils/storageHelper"
import strings from "../../constants/strings"
import { useNavigate } from "react-router-dom"
import {
  getCurrAccount,
  getAccountList,
  getInfoByAccount,
} from "../../utils/userHelper"
import { useDispatch, useSelector } from "react-redux"
import { initOrders } from "../../store/orderSlice"
import { initKline } from "../../store/klineSlice"
import { setCustomItem, selectCustomItem } from "../../store/customSlice"
import config from "../../config/config"
import { LocaleChange } from "../common/LocaleChange"
import { FormattedMessage, useIntl } from "react-intl"
import { useGlobalModal } from "../../utils/hooks"

const [, , enus] = config.locales.ids
const { thirdPartProjectNames } = config
const { Option } = Select
const Wrapper = styled.div`
  position: absolute;
  top: -2px;
  right: 0;
  height: 32px;
  display: flex;
  align-items: center;
  color: ${props => props.theme.title};
  & > .widget-li + .widget-li {
    margin-left: 16px;
  }

  .time-x {
    font-size: 12px;
    font-weight: 700;
  }
  .btn-theme {
    width: 32px;
    height: 100%;
    padding: initial;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 0;
    color: inherit;
    .icon-dark,
    .icon-light {
      width: 14px;
      height: 14px;
      fill: currentColor;
    }
    .icon-dark {
      fill: ${props => props.theme.theme};
    }
    .icon-light {
      width: 16px;
      height: 16px;
      fill: ${props => props.theme.starLow};
    }
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      background: inherit;
      border-color: inherit;
    }
  }
  .btn-locale {
    min-width: 66px;
    padding: initial;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: inherit;
    .icon-locale {
      width: 14px;
      height: 14px;
      margin-right: 6px;
      fill: currentColor;
    }
  }
  .ant-select.account {
    color: ${props => props.theme.theme};
    min-width: 120px;
    font-size: 12px;
    font-weight: 700;
    .ant-select-selector {
      height: 26px;
      border-color: currentColor;
      background-color: ${props => props.theme.cardBg};
    }
    .ant-select-selection-item {
      font-weight: inherit;
      line-height: 24px;
    }
    .ant-select-arrow {
      width: 14px;
      height: 14px;
      color: inherit;
      .icon-arrow {
        width: 100%;
        height: 100%;
        fill: currentColor;
      }
    }
  }
  .btn-menu {
    padding: initial;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: inherit;
    .icon-menu {
      width: 16px;
      height: 16px;
      fill: currentColor;
    }
  }
`

// 时钟显示组件
const TimeWidget = ({ ...props }) => {
  const { locale } = useIntl()
  const [date, setDate] = useState(moment())
  useEffect(() => {
    const t = setInterval(() => {
      setDate(moment())
    }, 1000)

    return () => {
      clearInterval(t)
    }
  }, [])

  return (
    <p {...props}>
      {locale !== enus
        ? date.format("YYYY年MM月DD日 HH:mm:ss")
        : date.locale("en").format("MMMM Do YYYY, h:mm:ss A")}
      &nbsp;UTC+8
    </p>
  )
}
const THEME_CONVERT = {
  light: "dark",
  dark: "light",
}
// NOTE: 主页右上角功能集
const TopRightWidgets = props => {
  const thirdPartProjectName = getStorage(
    strings.storageKeyThirdPartProjectName
  )
  const showSettingsModal = useGlobalModal(strings.modalKeySettings)
  const theme = useSelector(state =>
    selectCustomItem(state, strings.customConfigKeyTheme)
  )
  const [canChangeTheme, setCanChangeTheme] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const currAcc = getCurrAccount()
  const accountList = getAccountList()
  const handleAccChange = val => {
    setStorage(strings.storageKeyCurrAcc, val)
    dispatch(initKline())
    setTimeout(() => {
      window.location.reload()
    }, 0)
  }
  const handleCustomConfigChange = (key, value) => {
    dispatch(
      setCustomItem({
        key,
        value,
      })
    )
  }
  const handleLogout = () => {
    dispatch(initOrders())
    navigate("/login")
  }
  // NOTE: 切换语言后需要将主题切换禁用一段时间（切换语言 tv 图表库需要重新加载图表，此时若再次切换主题会提示图表库的 changeTheme 方法找不到）
  const handleDelayTrigger = () => {
    setCanChangeTheme(false)
    setTimeout(() => {
      setCanChangeTheme(true)
    }, 1000)
  }
  const menuOverlay = (
    <Menu>
      <Menu.Item key="0" onClick={() => showSettingsModal()}>
        <IconFont className="icon-setting" />
        <FormattedMessage id="home.trWidgets.menu.settings" />
      </Menu.Item>
      {thirdPartProjectName !== thirdPartProjectNames.hotsignPro && (
        <Menu.Item key="1" onClick={handleLogout}>
          <IconFont className="icon-logout" />
          <FormattedMessage id="home.trWidgets.menu.signOut" />
        </Menu.Item>
      )}
    </Menu>
  )
  return (
    <Wrapper>
      {/* 时间显示 */}
      <TimeWidget className="widget-li time-x" />
      {/* 主题切换 */}
      <Button
        className="widget-li btn-theme no-trigger initial"
        onClick={() =>
          handleCustomConfigChange(
            strings.customConfigKeyTheme,
            THEME_CONVERT[theme]
          )
        }
        disabled={!canChangeTheme}
      >
        <IconFont className={`icon-${THEME_CONVERT[theme]}`} />
      </Button>
      {/* 语言切换 */}
      <LocaleChange onChange={handleDelayTrigger} />
      {/* 账号切换 */}
      <Select
        defaultValue={currAcc}
        // defaultValue={accountArr?.[0].account}
        className="widget-li account"
        suffixIcon={<IconFont className="icon-arrow" />}
        onChange={handleAccChange}
      >
        {accountList?.length &&
          accountList.map(item => {
            const { account, type } = getInfoByAccount(item)
            const txt = (Number(type) === 1 ? "DEMO" : "REAL") + account
            return (
              <Option key={account} value={account}>
                {txt}
              </Option>
            )
          })}
      </Select>
      {/* 菜单项 */}
      <Dropdown overlay={menuOverlay} overlayClassName="home-dropdown-menu">
        <Button className="widget-li btn-menu no-trigger initial">
          <IconFont className="icon-menu" />
        </Button>
      </Dropdown>
    </Wrapper>
  )
}

export default TopRightWidgets
