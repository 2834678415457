import { Spin } from "antd"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  height: ${props => (!!Number(props.height) ? `${props.height}px` : "auto")};
  margin: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin-dot-item {
    background-color: ${props => props.theme.theme};
  }
`

/**
 * NOTE: 全局加载状态组件
 * @param {*} size loading 图标的大小，可选值： small（文本内容级）/middle（组件容器级）/large（页面级）。
 * @param {'auto' | Number} height 容器高度，不传时默认为 auto
 * 【!注意】 className 中设置的 height 会覆盖 height 的设置
 * @returns
 */
export const Loading = ({ size = "middle", height, className = "" }) => {
  return (
    <Wrapper height={height} className={className}>
      <Spin size={size} />
    </Wrapper>
  )
}
