import { Skeleton as _Skeleton } from "antd"
import styled from "styled-components"

const Wrapper = styled(_Skeleton)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export default ({ ...props }) => {
  return (
    <Wrapper {...props} />
    //   {!loading && children}
    // </Wrapper>
  )
}
