import { useEffect, useRef } from "react"
import { useIntl } from "react-intl"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { widget } from "../../charting_library/charting_library.esm"
import config from "../../config/config"
import strings from "../../constants/strings"
import { selectCustomItem } from "../../store/customSlice"
import { useGlobalModal } from "../../utils/hooks"
// import Datafeed from "./Datafeed"
import colors from "../../constants/colors"
import { getStorage } from "../../utils/storageHelper"
import Datafeed1 from "./Datafeed1"

const { locales } = config
const [, , enus] = locales.ids

const Wrapper = styled.div`
  height: 100%;
  .tvc-btn-newTicket {
    cursor: pointer;
  }
`

const widgetDefaultOpts = {
  library_path: process.env.PUBLIC_URL + "/charting_library/", // 图表库在 public 文件夹中的路径
  timezone: "Asia/Shanghai", // 交易时区
  // 包含功能在默认情况下启用/禁用名称的数组（以下两个属性）
  disabled_features: [
    "compare_symbol",
    "header_symbol_search",
    "header_compare",
    // "go_to_date",
    "timeframes_toolbar",
    "save_chart_properties_to_local_storage",
  ],
  enabled_features: [],
  fullscreen: false, // 图表是否占用窗口中所有可用的空间
  autosize: true, // 图表是否应使用窗格中的所有可用空间，并在调整窗格本身大小时自动调整大小
  // studies_overrides: {},  // 自定义默认指标的样式及输入值
  // custom_css_url should be related to library_path
  // custom_css_url: "../theme.css",
  // time_frames: [],
  // TODO: 上线后请删除
  // debug: true,
}
const THEME_FOR_TV = {
  light: "Light",
  dark: "Dark",
}
// NOTE: 现行使用 TV 组件
const TVChartContainer = ({ symbol, interval }) => {
  // let tvWidget = null
  const { locale } = useIntl()
  const theme = useSelector(state =>
    selectCustomItem(state, strings.customConfigKeyTheme)
  )
  const thirdPartProjectName =
    getStorage(strings.storageKeyThirdPartProjectName) || "default"
  const ref = useRef(null)
  let tvWidget = useRef(null)
  const showTradeModal = useGlobalModal(strings.modalKeyOrderTrade)

  const initWidget = (_locale = null) => {
    const customCssUrl =
      thirdPartProjectName === "default"
        ? "../theme.css"
        : `../theme_${thirdPartProjectName}.css`
    const widgetOptions = {
      ...widgetDefaultOpts,
      symbol, // 交易品种
      interval, // 交易周期
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: new Datafeed1(), // 实现（JS API）接口以向图表提供数据的JavaScript对象。
      // TODO: 这里需要后续注意一下接口和图表库 interval 的格式问题
      container: ref.current, // 图表将被放置在这个 dom 中
      locale: (_locale || locale) === enus ? "en" : "zh", // 图表库的本地化处理
      theme: theme,
      custom_css_url: customCssUrl,
    }
    const _tvWidget = new widget(widgetOptions)
    _tvWidget.onChartReady(function () {
      tvWidget.current = _tvWidget
      _tvWidget.changeTheme(THEME_FOR_TV[theme]).then(() => {
        _tvWidget.applyOverrides(getOverrides(theme))
      })

      _tvWidget
        .headerReady()
        .then(function () {
          const buyBtn = _tvWidget.createButton()
          buyBtn.setAttribute("class", "tvc-btn-ticket buy")
          buyBtn.textContent = locale !== enus ? "做多" : "Buy"
          buyBtn.addEventListener("click", function () {
            showTradeModal({ symbol, type: 0 })
          })
          const sellBtn = _tvWidget.createButton()
          sellBtn.setAttribute("class", "tvc-btn-ticket sell")
          sellBtn.textContent = locale !== enus ? "做空" : "Sell"
          sellBtn.addEventListener("click", function () {
            showTradeModal({ symbol, type: 1 })
          })
        })
        .catch(e => console.log("==> something wrong with headerReady", e))
    })
  }
  const getOverrides = theme => {
    const thirdPartProjectName =
      getStorage(strings.storageKeyThirdPartProjectName) || "default"
    const color = colors[thirdPartProjectName][theme]
    const t = {
      border: color.border,
      background: color.cardBg,
      up: color.themeRed,
      down: color.themeGreen,
    }
    const themes = {
      "paneProperties.backgroundType": "solid",
      "paneProperties.background": t.background,
      "paneProperties.vertGridProperties.color": t.border,
      "paneProperties.horzGridProperties.color": t.border,
      "mainSeriesProperties.candleStyle.upColor": t.up,
      "mainSeriesProperties.candleStyle.downColor": t.down,
      "mainSeriesProperties.candleStyle.drawWick": !0, // 烛心：即蜡烛柱中间竖着的那根线
      "mainSeriesProperties.candleStyle.drawBorder": !0,
      // "mainSeriesProperties.candleStyle.borderColor": t.border,
      "mainSeriesProperties.candleStyle.borderUpColor": t.up,
      "mainSeriesProperties.candleStyle.borderDownColor": t.down,
      "mainSeriesProperties.candleStyle.wickUpColor": t.up,
      "mainSeriesProperties.candleStyle.wickDownColor": t.down,
      "mainSeriesProperties.candleStyle.barColorsOnPrevClose": !1,
    }
    return themes
  }

  useEffect(() => {
    if (!tvWidget.current) {
      initWidget()
    }
    return () => {
      if (tvWidget.current !== null) {
        tvWidget.current.remove()
        tvWidget.current = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // tvWidget.current && initWidget(locale)
    tvWidget.current = null
    initWidget(locale)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  useEffect(() => {
    if (tvWidget.current) {
      // NOTE: applyOverrides 一定要放在 changeTheme 的 then 回调中，否则不起作用
      tvWidget.current.changeTheme(THEME_FOR_TV[theme]).then(() => {
        tvWidget.current.applyOverrides(getOverrides(theme))
      })
    }
  }, [theme])

  return <Wrapper ref={ref} className="TVChartContainer" />
}

export default TVChartContainer
