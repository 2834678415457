import { Collapse } from "antd"
import { FormattedMessage, useIntl } from "react-intl"
import { useSelector } from "react-redux"
import styled from "styled-components"
import config from "../../../config/config"
import { selectNamesByGroup } from "../../../store/symbolSlice"
import { Loading } from "../../common/Loading"
import SymbolItem from "./SymbolItem"

const { locales } = config
const { Panel } = Collapse
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .title-ul {
    display: flex;
    .title-li {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      line-height: 24px;
      color: ${props => props.theme.secondary};
      &:nth-child(1) {
        width: 36%;
        padding-left: 12px;
        justify-content: flex-start;
      }
      &:nth-child(2) {
        width: 20%;
      }
      &:nth-child(3) {
        flex: 1;
      }
      &:nth-child(4) {
        flex: 1;
      }
      &:nth-child(n + 2) {
        position: relative;
        &::before {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          content: "";
          width: 1px;
          height: 60%;
          background-color: ${props => props.theme.border};
        }
      }
    }
  }
  .content-x {
    padding-top: 8px;
    flex: 1 0 auto;
    height: 0;
    overflow-y: auto;
    & > .loading {
      height: 100%;
      margin: 0;
    }
    /* 关闭滚动条 */
    &::-webkit-scrollbar {
      display: none;
    }
    /* 折叠面板样式覆盖 */
    .ant-collapse {
      background-color: unset;
      & > .ant-collapse-item {
        border-bottom: 0;
        .ant-collapse-header {
          padding: 8px;
          font-size: 12px;
          font-weight: 500;
          line-height: 16px;
          color: ${props => props.theme.primary};
          background-color: ${props => props.theme.themeLight};
          .ant-collapse-arrow > svg {
            width: 10px;
            fill: currentColor;
          }
        }
        .ant-collapse-content-box {
          padding: unset;
        }
        &-active {
          .ant-collapse-header {
            background-color: unset;
          }
        }
      }
    }
    /* 报价下第一级 Collapse header 样式 */
    & > .ant-collapse > .ant-collapse-item > .ant-collapse-header {
      color: ${props => props.theme.theme};
    }
  }
`
// NOTE: 渲染频率同本文件的根组件 SymbolListByGroups
const SymbolListByGroup = ({ group }) => {
  const symbolNames = useSelector(state => selectNamesByGroup(state, group))
  return (
    <>
      {symbolNames.map(name => (
        <SymbolItem key={name} name={name} />
      ))}
    </>
  )
}
const intlGroups = {
  大商所: {
    [locales.ids[0]]: "大商所", // zh-CN
    [locales.ids[1]]: "大商所", // zh-HK
    [locales.ids[2]]: "DCE", // en-US
  },
  上期所: {
    [locales.ids[0]]: "上期所", // zh-CN
    [locales.ids[1]]: "上期所",
    [locales.ids[2]]: "SHFE",
  },
  郑商所: {
    [locales.ids[0]]: "郑商所", // zh-CN
    [locales.ids[1]]: "鄭商所",
    [locales.ids[2]]: "CZCE",
  },
  中金所: {
    [locales.ids[0]]: "中金所",
    [locales.ids[1]]: "中金所",
    [locales.ids[2]]: "CFFEX",
  },
  其他: {
    [locales.ids[0]]: "其他",
    [locales.ids[1]]: "其他",
    [locales.ids[2]]: "OTHER",
  },
  债券和国际期货: {
    [locales.ids[0]]: "债券和国际期货",
    [locales.ids[1]]: "債券和國際期貨",
    [locales.ids[2]]: "BONDS && INTERNATIONAL FUTURES",
  },
  指数和合约: {
    [locales.ids[0]]: "指数和合约",
    [locales.ids[1]]: "指數和合約",
    [locales.ids[2]]: "INDICES && CONTRACTS",
  },
  货币: {
    [locales.ids[0]]: "货币",
    [locales.ids[1]]: "貨幣",
    [locales.ids[2]]: "CURRENCY",
  },
  股票: {
    [locales.ids[0]]: "股票",
    [locales.ids[1]]: "股票",
    [locales.ids[2]]: "STOCK",
  },
}
// NOTE: 【国内期货】和【国际交易】报价版块，仅初次选中显示时触发渲染
export const SymbolListByGroups = ({ groups }) => {
  // console.log("==SymbolListByGroups", groups)
  const intl = useIntl()
  const { locale } = intl
  return (
    <Wrapper>
      <ul className="title-ul">
        <li className="title-li">
          <FormattedMessage id="home.quotePane.table.ths.th1" />
        </li>
        <li className="title-li">
          <FormattedMessage id="home.quotePane.table.ths.th2" />
        </li>
        <li className="title-li">
          <FormattedMessage id="home.quotePane.table.ths.th3" />
        </li>
        <li className="title-li">
          <FormattedMessage id="home.quotePane.table.ths.th4" />
        </li>
      </ul>
      <div className="content-x">
        {!groups.length ? (
          <Loading className="loading" />
        ) : (
          <Collapse
            key={`${groups}`}
            bordered={false}
            defaultActiveKey={[groups[0]]}
          >
            {groups.map(group => (
              <Panel header={intlGroups[group][locale]} key={group}>
                <SymbolListByGroup key={group} group={group} />
              </Panel>
            ))}
          </Collapse>
        )}
      </div>
    </Wrapper>
  )
}

export default SymbolListByGroups
