import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit"
import apis from "../services/apis"
import { post } from "../services/request"
import config from "../config/config"
import moment from "moment"

const { cmdConfig } = config

const getCmdByType = cmdStr => {
  const cmdArr = Object.values(cmdConfig.entities).map(item =>
    item.replace(" ", "").toLowerCase()
  )
  const cmd = cmdArr.indexOf(cmdStr.toLowerCase())
  return cmd
}
const [IDLE, PENDING, FULFILLED, REJECTED] = config.fetchStatus
const ordersAdapter = createEntityAdapter({
  selectId: item => item.ticket,
  sortComparer: (a, b) =>
    moment(b.open_time).format("X") - moment(a.open_time).format("X"),
})
const initialState = ordersAdapter.getInitialState({
  status: IDLE,
  error: null,
  historyOrders: {
    list: [],
    status: IDLE,
    error: null,
  },
  totalProfits: new Array(3).fill(0),
})
// NOTE: async actions
export const fetchOrders = createAsyncThunk("orders/fetchOrders", async () => {
  const { error, data } = await post(apis.getPositionOrders)
  if (error) return Promise.reject(error)
  return data
})
export const fetchHistoryOrders = createAsyncThunk(
  "orders/fetchHistoryOrders",
  async args => {
    const { error: positionError, data: positionData = [] } = await post(
      apis.getHistoryOrders,
      args || undefined
    )
    let { error: limitError, data: limitData } = await post(
      apis.getHistoryLimitOrders,
      args || undefined
    )
    if (positionError || limitError) {
      return Promise.reject(positionError || limitError)
    }
    let data = (positionData || []).concat(limitData || [])
    if (!data?.length) return data
    data.sort(
      (a, b) =>
        moment(b.close_time).format("X") - moment(a.close_time).format("X")
    )
    // NOTE: 接口返回字段处理
    data = data.map(item => {
      if (Object.keys(item).includes("cmd")) return item
      return {
        ...item,
        cmd: getCmdByType(item.type),
      }
    })
    return data
  }
)
export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    initOrders: (state, action) => {
      return initialState
    },
    updateOrders: (state, action) => {
      const { payload } = action
      let { type, open_time, close_time, storage, cmd } = payload
      // 以下操作是为了兼容接口字段名称不一致问题
      typeof open_time !== "string" &&
        (payload.open_time = moment(open_time * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        ))
      typeof close_time !== "string" &&
        (payload.close_time = moment(close_time * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        ))
      if (cmdConfig.limitIds.includes(cmd)) {
        payload.swap = storage
      }
      switch (type) {
        case 0: // 开仓
          ordersAdapter.addOne(state, payload)
          break
        case 1: // 修改
          ordersAdapter.upsertOne(state, payload)
          break
        case 2: // 平仓
          ordersAdapter.removeOne(state, payload.ticket)
          // 将这个订单加入到历史订单列表里去（TEMP: volume 的判断是为了兼容接口BUG，后期修复后需去掉）
          !!payload.volume && state.historyOrders.list.unshift(payload)
          break
        default:
          break
      }
    },
    updateTotalProfits: (state, action) => {
      const { idx, value } = action.payload
      state.totalProfits[idx] = value
    },
  },
  extraReducers: {
    [fetchOrders.pending]: (state, action) => {
      state.status = PENDING
    },
    [fetchOrders.fulfilled]: (state, action) => {
      state.status = FULFILLED
      const { payload } = action
      if (Array.isArray(payload) && payload.length > 0) {
        // TEMP: volume 的判断是为了兼容接口BUG，后期修复后需去掉
        const _payload = payload.filter(item => !!item.volume)
        ordersAdapter.setAll(state, _payload)
      } else {
        ordersAdapter.setAll(state, [])
      }
    },
    [fetchOrders.rejected]: (state, action) => {
      state.status = REJECTED
      state.error = action.error // TEMP: 返回 action 数据存疑
    },
    [fetchHistoryOrders.pending]: (state, action) => {
      state.historyOrders.status = PENDING
    },
    [fetchHistoryOrders.fulfilled]: (state, action) => {
      state.historyOrders.status = FULFILLED
      const { payload } = action
      if (Array.isArray(payload) && payload.length > 0) {
        // TEMP: volume 的判断是为了兼容接口BUG，后期修复后需去掉
        const _payload = payload.filter(item => !!item.volume)
        state.historyOrders.list = _payload
      } else {
        state.historyOrders.list = []
      }
    },
    [fetchHistoryOrders.rejected]: (state, action) => {
      state.historyOrders.status = REJECTED
      state.historyOrders.error = action.error // TEMP: 返回 action 数据存疑
    },
  },
})
export const { initOrders, updateOrders, updateTotalProfits } =
  orderSlice.actions
export default orderSlice.reducer
// NOTE: Selectors
export const { selectIds: selectOrderIds, selectAll: selectAllOrders } =
  ordersAdapter.getSelectors(state => state.orders)
export const selectOrdersByCmd = createSelector(
  selectAllOrders,
  (_, cmdIds) => cmdIds,
  (orders, cmdIds) => orders.filter(item => cmdIds.includes(item.cmd))
)
