import { createAction } from "@reduxjs/toolkit"

export const SOCKET_INIT = "socket/init"
export const SOCKET_SEND = "socket/send"
export const SOCKET_CLOSE = "socket/close"
export const SOCKET_DESTROY = "socket/destroy"
export const SOCKET_SUBSCRIBE = "socket/subscribe"
export const SOCKET_UNSUBSCRIBE = "socket/unsubscribe"
export const SOCKET_SUBSCRIBEONCE = "socket/subscribeOnce"

export const init = createAction(SOCKET_INIT)
export const send = createAction(SOCKET_SEND)
export const close = createAction(SOCKET_CLOSE)
export const destroy = createAction(SOCKET_DESTROY)
export const subscribe = createAction(SOCKET_SUBSCRIBE)
export const unsubscribe = createAction(SOCKET_UNSUBSCRIBE)
export const subscribeOnce = createAction(SOCKET_SUBSCRIBEONCE)

export const SOCKET_TYPES = [
  SOCKET_INIT,
  SOCKET_SEND,
  SOCKET_CLOSE,
  SOCKET_DESTROY,
  SOCKET_SUBSCRIBE,
  SOCKET_UNSUBSCRIBE,
  SOCKET_SUBSCRIBEONCE,
]
