import {
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit"
import { selectSymbolNames } from "./symbolSlice"

// NOTE: 适配器
const klineAdapter = createEntityAdapter({
  selectId: item => item.name,
})
export const initialState = klineAdapter.getInitialState({
  ids: ["EURUSD", "GBPUSD"],
  entities: {
    EURUSD: { name: "EURUSD", cnName: "欧元兑美元", resolution: "1D" },
    GBPUSD: { name: "GBPUSD", cnName: "英镑兑美元", resolution: "1D" },
  },
  activeName: "EURUSD",
})
const klineSlice = createSlice({
  name: "kline",
  initialState,
  reducers: {
    initKline: (state, action) => {
      return initialState
    },
    addKline: (state, action) => {
      klineAdapter.upsertOne(state, { resolution: "1D", ...action.payload })
    },
    deleteKline: (state, action) => {
      // WARNING: 仅在本 slice 文件中使用。所有的 [删除] 操作都应该通过下文的 conditionalDeleteKline 执行
      klineAdapter.removeOne(state, action.payload)
    },
    changeKline2Active: (state, action) => {
      state.activeName = action.payload
    },
    // updateBarsDataByResolution: (state, action) => {
    //   const { symbol, resolution } = action.payload
    //   klineAdapter.upsertOne(state, { name: symbol })
    // },
  },
})
export const { initKline, addKline, changeKline2Active, deleteKline } =
  klineSlice.actions
export default klineSlice.reducer

// NOTE: selectors
export const {
  selectAll: selectAllKlines,
  selectById: selectKlineByName,
  selectIds: selectKlineNames,
} = klineAdapter.getSelectors(state => state.kline)
export const selectKlineActiveName = state => state.kline.activeName
// -- 获取 K线列表前线筛选（筛掉已被下架的产品<symbolList中没有的>）
export const selectKlineOnLine = createSelector(
  selectAllKlines,
  selectSymbolNames,
  (klineList, symbolNames) =>
    klineList.filter(item => symbolNames.includes(item.name))
)
// -- 【条件性的添加】 kline 实体数据。在不清楚 被添加的symbol 是否存在于 kline 的 ids 集合里时使用
export const conditionalAddKline = (name, kline) => (dispatch, getState) => {
  const state = getState()
  const klineNames = selectKlineNames(state)
  const activeName = selectKlineActiveName(state)

  if (name === activeName) return

  dispatch(changeKline2Active(name))
  if (!klineNames.includes(name)) {
    dispatch(addKline({ ...kline }))
  }
}
// -- 【条件性的删除】 kline 实体数据，并及时更新 [ 下一个 ] 被选中的 kline
export const conditionalDeleteKline = name => (dispatch, getState) => {
  const state = getState()
  const activeName = selectKlineActiveName(state)
  const klineNames = selectKlineNames(state)
  // kline ids 长度为0时不能删除【组件中触发时做了限制，这里再上个双保险】
  if (klineNames.length === 1) return

  // 下一个被选中的 activeName 的筛选规则
  const idx = klineNames.indexOf(name)
  const nextActiveName = klineNames[idx + 1] || klineNames[idx - 1]
  // 获取到所有需要的前置数据后执行相关 action 操作
  dispatch(deleteKline(name))
  // 当被删除项为当前选中项时的额外处理：更新下一个将被选中的项
  name === activeName && dispatch(changeKline2Active(nextActiveName))
}
