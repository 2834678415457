import { useState, useRef } from "react"
import { Button, Collapse, InputNumber, message, notification } from "antd"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import config from "../../../config/config"
import {
  fetchSymbolInfo,
  selectFavorites,
  selectSymbolByName,
  updateFavorites,
} from "../../../store/symbolSlice"
import classNames from "classnames"
import { IconFont } from "../../common/IconFont"
import { useGlobalModal } from "../../../utils/hooks"
import strings from "../../../constants/strings"
// import { conditionalAddKline } from "../../../store/klineSlice"
import { post } from "../../../services/request"
import apis from "../../../services/apis"
import { toDecimal } from "../../../utils/util"
import { FormattedMessage, useIntl } from "react-intl"
import { setCustomItem } from "../../../store/customSlice"

const { defaultString: DEFAULT_STRING, locales } = config
const [, , enus] = locales.ids
const { Panel } = Collapse
const CollapseWrapper = styled(Collapse)`
  width: 100%;
  &:nth-child(2n + 1) {
    background-color: ${props => props.theme.tableRowActive} !important;
  }
  &:hover {
    background-color: ${props => props.theme.themeLight} !important;
  }
  & > .ant-collapse-item {
    .panel-header-ul {
      width: 100%;
      display: flex;
      & > .panel-header-li {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        &:nth-child(1) {
          width: 36%;
          justify-content: flex-start;
        }
        &:nth-child(2) {
          width: 20%;
        }
        &:nth-child(3) {
          flex: 1;
        }
        &:nth-child(4) {
          flex: 1;
        }
        &.showColor {
          color: ${props =>
            props.spread > 0
              ? props.theme.themeRed
              : props.spread < 0
              ? props.theme.themeGreen
              : props.theme.primary};
        }
      }
      & > .panel-header-btns {
        flex: 1;
        display: inline-flex;
        justify-content: flex-end;
        .panel-header-btn {
          height: 100%;
          padding: 0 8px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          .icon-star,
          .icon-star-full {
            width: 12px;
            height: 12px;
            fill: currentColor;
          }
          .icon-star-full {
            fill: ${props => props.theme.starLow};
          }
          .icon-info {
            width: 12px;
            height: 12px;
            fill: currentColor;
          }
          .icon-kline {
            width: 16px;
            height: 16px;
            fill: currentColor;
          }
          &:hover .icon-info,
          &:hover .icon-kline {
            fill: ${props => props.theme.theme};
          }
        }
      }
    }
    & > .ant-collapse-content {
      /* 折叠区域第一行 */
      .panel-content-row.row-1 {
        display: flex;
        & > .ant-btn {
          position: relative;
          height: 36px;
          flex: 1 1 38%;
          font-size: 14px;
          font-weight: 500;
          color: ${props => props.theme.white};
          .price {
            margin-top: 4px;
          }
          .label {
            position: absolute;
            top: 8px;
            font-size: 12px;
            line-height: 12px;
          }
          &.btn-sell {
            text-align: left;
            background-color: ${props => props.theme.themeGreen};
            .label {
              right: 12px;
            }
            &:hover,
            &:active,
            &-active {
              background-color: ${props => props.theme.themeGreenActive};
            }
          }
          &.btn-buy {
            text-align: right;
            background-color: ${props => props.theme.themeRed};
            .label {
              left: 12px;
            }
            &:hover,
            &:active,
            &-active {
              background-color: ${props => props.theme.themeRedActive};
            }
          }
        }
        & > .ant-input-number {
          flex: 1 1 24%;
          margin: 0 4px;
          .ant-input-number-input-wrap,
          input {
            height: 100%;
          }
          &.error {
            border-color: ${props => props.theme.error};
          }
        }
      }
      .panel-content-row.row-2 {
        padding: 4px;
        display: flex;
        flex-wrap: nowrap;
        .holc-li {
          flex: 1 1 38%;
          font-size: 12px;
          font-weight: 700;
          color: ${props => props.theme.primary};
          &:first-child {
            color: ${props => props.theme.themeGreen};
          }
          &:last-child {
            text-align: right;
            color: ${props => props.theme.themeRed};
          }
          &:nth-child(2) {
            flex: 1 1 28%;
            text-align: center;
          }
        }
      }
    }
  }
`

const PanelHeader = ({ data, isCollapse }) => {
  const { locale } = useIntl()
  const dispatch = useDispatch()
  const showSymbolInfoModal = useGlobalModal(strings.modalKeySymbolInfo)
  const favoriteIds = useSelector(selectFavorites)
  const isFavorite = favoriteIds.includes(data?.name)

  const handleClick = (e, type) => {
    e.stopPropagation()
    switch (type) {
      case 0:
        dispatch(updateFavorites(data?.name))
        break
      case 1:
        showSymbolInfoModal({ data })
        break
      case 2:
        dispatch(
          setCustomItem({
            key: strings.customConfigKeyExternalSymbol,
            value: data.name,
          })
        )
        // dispatch(
        //   conditionalAddKline(data.name, {
        //     name: data.name,
        //     cnName: data.cn_name,
        //   })
        // )
        break
      default:
        break
    }
  }
  return (
    <div className={classNames("panel-header-ul", { active: !isCollapse })}>
      <span className="panel-header-li">
        {locale === enus ? data?.name : data?.cn_name || DEFAULT_STRING}
      </span>
      {!!isCollapse ? (
        <>
          <span className="panel-header-li showColor">
            {data?.spread || DEFAULT_STRING}%
          </span>
          <span className="panel-header-li showColor">
            {data?.bid || DEFAULT_STRING}
          </span>
          <span className="panel-header-li showColor">
            {data?.ask || DEFAULT_STRING}
          </span>
        </>
      ) : (
        <div className="panel-header-btns">
          <Button
            className="initial no-trigger panel-header-btn"
            onClick={e => handleClick(e, 0)}
          >
            <IconFont
              className={!isFavorite ? "icon-star" : "icon-star-full"}
            />
          </Button>
          <Button
            className="initial no-trigger panel-header-btn"
            onClick={e => handleClick(e, 1)}
          >
            <IconFont className="icon-info" />
          </Button>
          <Button
            className="initial no-trigger panel-header-btn"
            onClick={e => handleClick(e, 2)}
          >
            <IconFont className="icon-kline" />
          </Button>
        </div>
      )}
    </div>
  )
}
const SymbolItem = ({ name }) => {
  const intl = useIntl()
  const { locale } = intl
  const volumeRef = useRef(null)
  const data = useSelector(state => selectSymbolByName(state, name))
  const { bid, ask, holc, cn_name, digits } = data
  const {
    margin_currency_rate: marginRate, // 保证金率
    volume_max: volumeMax,
    volume_min: volumeMin,
    volume_step: volumeStep,
  } = data?.symbolInfo || {}
  const dispatch = useDispatch()
  const [isCollapse, setIsCollapse] = useState(true)
  const [isLoadings, setIsLoadings] = useState([false, false])
  const changeLoadings = (idx, bool) => {
    setIsLoadings(loadings => {
      loadings[idx] = bool
      return loadings
    })
  }
  // 币种的展开收起处理
  const handleChange = arr => {
    if (!arr.length) {
      // 收起
      setIsCollapse(true)
      return
    }
    // 展开
    setIsCollapse(false)
    if (!("symbolInfo" in data)) {
      dispatch(fetchSymbolInfo(name))
    }
  }
  // 快捷下单交易
  const handleTransaction = async cmd => {
    changeLoadings(cmd, true)
    // -- 检查保证金数据是否正常获取到
    if (!marginRate) {
      message.error(
        intl.formatMessage({
          id: "home.quotePane.message.error.marginNull",
        })
      )
      changeLoadings(cmd, false)
      return
    }
    // TEMP: -- 检查是否处于停盘期间【暂时由接口判断】
    // TEMP:-- 检查各字段是否符合要求
    // -- 下单请求
    const volume = volumeRef.current.value
    const { error } = await post(apis.openOrder, {
      lots: volume,
      cmd,
      open_price: 0,
      tp: 0,
      sl: 0,
      symbol: name,
      comment: "",
    })
    changeLoadings(cmd, false)
    if (error) {
      notification["error"]({
        message: intl.formatMessage({
          id: "home.quotePane.message.error.transactFailed",
        }),
        description: error,
      })
      return
    }
    notification["success"]({
      message: intl.formatMessage({
        id: "home.quotePane.message.success.transactSuccess.title",
      }),
      description: intl.formatMessage(
        {
          id: "home.quotePane.message.success.transactSuccess.desc",
        },
        {
          desc: `${locale !== enus ? cn_name : name} ${volume}`,
        }
      ),
    })
  }

  return (
    <CollapseWrapper
      bordered={false}
      onChange={handleChange}
      spread={Number(data?.spread || 0)}
    >
      <Panel
        showArrow={false}
        header={<PanelHeader data={data} isCollapse={isCollapse} />}
      >
        <div className="panel-content-row row-1">
          <Button
            className="initial no-trigger btn-sell"
            disabled={!bid}
            onClick={() => handleTransaction(1)}
            loading={isLoadings[1]}
          >
            <span className="price">{bid || DEFAULT_STRING}</span>
            <span className="label">
              <FormattedMessage id="home.quotePane.table.tr.sell" />
            </span>
          </Button>
          <InputNumber
            ref={volumeRef} // 非受控模式下通过 ref 获取表单值
            // 设置 key 值可以保证 defaultValue 值能被正确更新
            key={volumeMin}
            defaultValue={volumeMin || DEFAULT_STRING}
            step={volumeStep || DEFAULT_STRING}
            min={volumeMin || DEFAULT_STRING}
            max={volumeMax || DEFAULT_STRING}
          />
          <Button
            className="initial no-trigger btn-buy"
            disabled={!ask}
            onClick={() => handleTransaction(0)}
            loading={isLoadings[0]}
          >
            <span className="price">{ask || DEFAULT_STRING}</span>
            <span className="label">
              <FormattedMessage id="home.quotePane.table.tr.buy" />
            </span>
          </Button>
        </div>
        <div className="panel-content-row row-2">
          <span className="holc-li">
            <FormattedMessage id="home.quotePane.table.tr.high" />: &nbsp;
            {toDecimal(holc?.high, digits) || DEFAULT_STRING}
          </span>
          <span className="holc-li">
            {holc?.high
              ? Math.floor(((holc.high - holc.low) / holc.low) * 10000) / 100 +
                "%"
              : DEFAULT_STRING}
          </span>
          <span className="holc-li">
            <FormattedMessage id="home.quotePane.table.tr.low" />: &nbsp;
            {toDecimal(holc?.low, 2) || DEFAULT_STRING}
          </span>
        </div>
      </Panel>
    </CollapseWrapper>
  )
}

export default SymbolItem
