import strings from "../constants/strings"
import { getStorage } from "./storageHelper"

// 检查是否登录
export const checkLogin = () => {
  // const info = getStorage(strings.storageKeyAccInfo)
  // const account = getStorage(strings.storageKeyCurrAcc)
  // // console.log(info, account)
  // return !!info.entities[account]?.token
  return !!getStorage(strings.storageKeyCurrAcc)
}
// 获取当前登录的账户
export const getCurrAccount = () => {
  return getStorage(strings.storageKeyCurrAcc)
}
// 根据传入的 账户 获取对应的账户信息
export const getInfoByAccount = account => {
  account = account || getStorage(strings.storageKeyCurrAcc)
  const info = getStorage(strings.storageKeyAccInfo) || {}
  return info?.entities?.[account] || {}
}
// 获取当前用户的账户列表
export const getAccountList = () => {
  return getStorage(strings.storageKeyAccInfo)?.ids || []
}
