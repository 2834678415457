import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import classNames from "classnames"
import {
  changeKline2Active,
  conditionalDeleteKline,
  selectAllKlines,
  selectKlineActiveName,
  selectKlineOnLine,
} from "../../../store/klineSlice"
import CardTabs from "../../common/CardTabs"
import { FinanceCalendar } from "./FinanceCalendar"
import TVChartContainer from "../../TVChartContainer"
import { useIntl } from "react-intl"
import config from "../../../config/config"
import { isSafari } from "../../../utils/util"
import TradingView from "./TradingView"
// import { TradingViewContainer } from "./TradingViewContainer"

const [, , enus] = config.locales.ids
const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden; // 设置 symbol-x 超出宽度时滚动显示
  height: 100%;
  margin-left: 8px;
  flex: 1;
  .ant-tabs-content.ant-tabs-content-top {
    padding: 0;
  }
  /* NOTE: Safari 下 height: 100%; 失效。用 flex || 定位 来解决 */
  &.isSafari {
    .ant-tabs-tab-remove {
      position: relative;
      .icon-delete {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    .ant-tabs-content-holder {
      display: flex;
      flex-direction: column;
      & > .ant-tabs-content {
        flex: 1;
        overflow: hidden;
      }
    }
    .ant-table-thead .ant-table-cell {
      height: 30px;
    }
  }
`
const ChartKLineWrapper = styled(CardTabs)`
  &.ant-tabs-editable-card {
    .ant-tabs-nav-wrap {
      background-color: ${props => props.theme.background};
      .ant-tabs-nav-list {
        .ant-tabs-tab {
          width: initial;
          margin-top: 2px;
          padding: 0 12px;
          display: inline-flex;
          justify-content: space-between;
          background-color: ${props => props.theme.btnBg};
          font-size: 12px;
          font-weight: 700;
          color: ${props => props.theme.primary};
          &:not(.ant-tabs-tab-with-remove) {
            // 仅剩一个元素且不能删除时，文本居中
            justify-content: center;
          }
          & + .ant-tabs-tab {
            margin-left: 2px;
          }
          .ant-tabs-tab-remove {
            height: 100%;
            .icon-delete {
              width: 12px;
              height: 100%;
              box-sizing: content-box;
              fill: ${props => props.theme.secondary};
              &:hover {
                fill: ${props => props.theme.primary};
              }
            }
          }
          &.ant-tabs-tab-active {
            margin-top: -2px;
            padding-top: 2px;
            background-color: ${props => props.theme.cardBg};
          }
        }
      }
    }
    & > .ant-tabs-nav {
      margin-top: 0;
      .ant-tabs-nav-operations {
        background-color: ${props => props.theme.white};
        border-radius: 2px;
      }
    }
    .ant-tabs-content {
      padding: 0;
    }
  }
`

const ChartKLine = () => {
  const { locale } = useIntl()
  const dispatch = useDispatch()
  const klineList = useSelector(selectKlineOnLine) // 仅显示 symbols 中有的列表
  // const klineList = useSelector(selectAllKlines)
  const activeName = useSelector(selectKlineActiveName)
  // const showTradeModal = useGlobalModal(strings.modalKeyOrderTrade)
  const tabs = useMemo(
    () =>
      klineList.map(item => ({
        tab: locale !== enus ? item.cnName : item.name,
        key: item.name,
        content: (
          <TVChartContainer
            symbol={item.name}
            interval={item.resolution}
            // onShowTradeModal={showTradeModal}
          />
        ),
        closable: klineList.length === 1 ? false : true, // 只有一个币种的时候不可关闭
      })),
    [klineList, locale]
  )
  const handleDelete = (name, action) => {
    if (action !== "remove") return
    dispatch(conditionalDeleteKline(name))
  }

  return (
    <ChartKLineWrapper
      len={klineList.length}
      tabs={tabs}
      tabPosition="bottom"
      activeKey={activeName}
      onEdit={handleDelete}
      onChange={name => dispatch(changeKline2Active(name))}
      hideAdd
    />
  )
}

const TopRightPane = () => {
  const intl = useIntl()
  const TABS = [
    {
      tab: intl.formatMessage({ id: "home.chartsPane.title" }),
      key: "图表",
      content: <TradingView />,
      // content: <ChartKLine />,
    },
    {
      tab: intl.formatMessage({ id: "home.calendarPane.title" }),
      key: "财经日历",
      content: <FinanceCalendar />,
    },
  ]
  return (
    <Wrapper className={classNames({ isSafari: isSafari() })}>
      <CardTabs tabs={TABS} />
    </Wrapper>
  )
}

export default TopRightPane
