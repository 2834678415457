import { useCallback, useEffect, useRef } from "react"

import styled from "styled-components"

import colors from "../../../constants/colors"

import { useIntl } from "react-intl"
import { useSelector } from "react-redux"
import strings from "../../../constants/strings"
import { selectCustomItem, setCustomItem } from "../../../store/customSlice"
import config from "../../../config/config"
import Datafeed1 from "../../TVChartContainer/Datafeed1"
import { useGlobalModal } from "../../../utils/hooks"
import { getStorage } from "../../../utils/storageHelper"
import { store } from "../../../store/store"

const { dispatch } = store
const Wrapper = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.cardBg};
`

const getOverrides = theme => {
  const thirdPartProjectName =
    getStorage(strings.storageKeyThirdPartProjectName) || "default"
  const color = colors[thirdPartProjectName][theme]
  const t = {
    border: color.border,
    background: color.cardBg,
    up: color.themeRed,
    down: color.themeGreen,
  }
  const themes = {
    "paneProperties.backgroundType": "solid",
    "paneProperties.background": t.background,
    "paneProperties.vertGridProperties.color": t.border,
    "paneProperties.horzGridProperties.color": t.border,
    "mainSeriesProperties.candleStyle.upColor": t.up,
    "mainSeriesProperties.candleStyle.downColor": t.down,
    "mainSeriesProperties.candleStyle.drawWick": !0, // 烛心：即蜡烛柱中间竖着的那根线
    "mainSeriesProperties.candleStyle.drawBorder": !0,
    // "mainSeriesProperties.candleStyle.borderColor": t.border,
    "mainSeriesProperties.candleStyle.borderUpColor": t.up,
    "mainSeriesProperties.candleStyle.borderDownColor": t.down,
    "mainSeriesProperties.candleStyle.wickUpColor": t.up,
    "mainSeriesProperties.candleStyle.wickDownColor": t.down,
    "mainSeriesProperties.candleStyle.barColorsOnPrevClose": !1,
  }
  return themes
}

const TV_DEFAULT_CONFIG = {
  timezone: "Asia/Shanghai",
  container: "tv_chart_container", // NOTE: tv版本差异，这个地方要写成 container_id
  width: "100%",
  height: "100%",
  library_path: "/charting_library/", // static文件夹的路径
  symbol_search_request_delay: 1000, // 触发搜索API searchSymbols 的延迟设置（单位：ms）
  disabled_features: [
    "header_compare",
    "timeframes_toolbar",
    "disable_resolution_rebuild",
    "tick_resolution",
    "items_favoriting",
  ], // adaptive_logo
  // enabled_features: [],
  custom_css_url: "../theme_hotsignPro.css",
  debug: false,
  // has_intraday: false,
}

// Locale Settings.
const { locales } = config
const [ZHCN, ZHHK, ENUS] = locales.ids
const THEMES_SETTINGS = ["light", "dark"]
const LOCALE_FOR_TV = {
  [ZHCN]: "zh",
  [ZHHK]: "zh_TW",
  [ENUS]: "en",
}
// Symbol Settings.
const DEFAULT_SYMBOL = "EURUSD"
const DEFAULT_INTERVAL = "1D"
const DEFAULT_KLINE_INFO = {
  symbol: DEFAULT_SYMBOL,
  interval: DEFAULT_INTERVAL,
}

let isFirstEntry = true
export default function TradingView({ ...props }) {
  const tvRef = useRef(null)
  const { locale } = useIntl()
  const theme = useSelector(state =>
    selectCustomItem(state, strings.customConfigKeyTheme)
  )
  const externalSymbol = useSelector(state =>
    selectCustomItem(state, strings.customConfigKeyExternalSymbol)
  )
  const showTradeModal = useGlobalModal(strings.modalKeyOrderTrade)

  const changeTVTheme = useCallback(theme => {
    // NOTE: applyOverrides 一定要放在 changeTheme 的 then 回调中，否则不起作用
    tvRef.current.changeTheme(theme).then(() => {
      tvRef.current.applyOverrides(getOverrides(theme))
    })
  }, [])

  // 初始化 && 响应语言locale更改
  useEffect(() => {
    if (!isFirstEntry) return
    initTvIns()

    function initTvIns() {
      const { symbol, interval } =
        getStorage(strings.storageKeyHistoryKlineInfo, 1) ?? DEFAULT_KLINE_INFO
      const tvIns = new window.TradingView.widget({
        ...TV_DEFAULT_CONFIG,
        symbol, // AAPL
        interval,
        locale: LOCALE_FOR_TV[locale], //"zh",
        datafeed: new Datafeed1(),
        theme, // NOTE: 仅在初始化时触发 TradingView 图表库的初始化，故不放在 dependencies 中
      })
      tvIns.onChartReady(function () {
        isFirstEntry = false
        tvRef.current = tvIns
        changeTVTheme(theme)
        createButton(tvIns)
      })
    }
    function createButton(tvIns) {
      const handleButtonClick = type => {
        const { symbol } = tvIns.symbolInterval()
        showTradeModal({ symbol, type })
      }
      tvIns
        .headerReady()
        .then(function () {
          const buyBtn = tvIns.createButton()
          buyBtn.setAttribute("class", "tvc-btn-ticket buy")
          buyBtn.textContent = locale !== ENUS ? "做多" : "Buy"
          buyBtn.addEventListener("click", () => handleButtonClick(0))
          const sellBtn = tvIns.createButton()
          sellBtn.setAttribute("class", "tvc-btn-ticket sell")
          sellBtn.textContent = locale !== ENUS ? "做空" : "Sell"
          sellBtn.addEventListener("click", () => handleButtonClick(1))
        })
        .catch(e =>
          console.error(
            "[TradingView createButton]: something wrong with headerReady",
            e
          )
        )
    }
  }, [locale, theme, changeTVTheme, showTradeModal])

  useEffect(() => {
    return () => {
      tvRef.current?.remove() // 调用图表库 API 销毁图表
      tvRef.current = null // 将本地 ref 清空
    }
  }, [])

  // 响应外部模块触发的 symbol 更改
  useEffect(() => {
    // 图表未初始化完成 或 外部模块没有提供 symbol 时不做任何处理
    if (!tvRef.current || !externalSymbol) return

    tvRef.current.setSymbol(externalSymbol, DEFAULT_INTERVAL) // interval 必传
    dispatch(
      setCustomItem({
        key: strings.customConfigKeyExternalSymbol,
        value: "",
      })
    )
  }, [externalSymbol])

  // 响应主题色更改
  useEffect(() => {
    if (tvRef.current && THEMES_SETTINGS.includes(theme)) {
      changeTVTheme(theme)
    }
  }, [theme, changeTVTheme])

  return <Wrapper id="tv_chart_container" {...props} />
}
