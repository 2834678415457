import styled from "styled-components"
import GlobalModal from "./GlobalModal"
import { FormattedMessage } from "react-intl"
import { Switch, Tooltip } from "antd"
import { IconFont } from "../common/IconFont"
import strings from "../../constants/strings"
import { useDispatch, useSelector } from "react-redux"
import { selectCustomItem, setCustomItem } from "../../store/customSlice"

const Wrapper = styled(GlobalModal)`
  .ant-modal-body {
    min-height: 60px;
  }
  .settings-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .label {
      font-weight: 500;
      color: ${props => props.theme.primary};
    }
    .tooltip-wrapper {
      margin: 0 auto 0 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      .icon-question {
        width: 14px;
        height: 14px;
        fill: ${props => props.theme.starLow};
      }
    }
  }
  .settings-tip {
    margin-top: 24px;
    text-align: center;
    font-size: 12px;
    color: ${props => props.theme.secondary};
  }
`

export const SettingsModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch()
  const isNeedCloseConfirm = useSelector(state =>
    selectCustomItem(state, strings.customConfigKeyCloseOrderConfirm)
  )
  const handleCustomItemChange = (key, value) => {
    dispatch(
      setCustomItem({
        key,
        value,
      })
    )
  }

  return (
    <Wrapper
      width={360}
      title={<FormattedMessage id="modal.settings.title" />}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <div className="settings-item">
        <span className="label">
          <FormattedMessage id="modal.settings.item1.label" />
        </span>
        <Tooltip
          title={<FormattedMessage id="modal.settings.item1.tooltip" />}
          placement="right"
        >
          <span className="tooltip-wrapper">
            <IconFont className="icon-question" />
          </span>
        </Tooltip>
        <Switch
          className="no-trigger"
          size="small"
          checked={isNeedCloseConfirm}
          onChange={value =>
            handleCustomItemChange(
              strings.customConfigKeyCloseOrderConfirm,
              value
            )
          }
        />
      </div>
      <p className="settings-tip">
        <FormattedMessage id="modal.settings.more" />
      </p>
    </Wrapper>
  )
}
