import { useState, useEffect, useMemo, Fragment } from "react"
import styled from "styled-components"
import { Button, DatePicker, Divider, Image, Popover, Radio, Table } from "antd"
import { IconFont } from "../../common/IconFont"
import classNames from "classnames"
import { get, post } from "../../../services/request"
import apis from "../../../services/apis"
import moment from "moment"
import { addZeroInFront, isChinese } from "../../../utils/util"
import config from "../../../config/config"
import { useIntl, FormattedMessage } from "react-intl"
import { Loading } from "../../common/Loading"
import Star from "../../common/Star"
import strings from "../../../constants/strings"
import { useGlobalModal } from "../../../utils/hooks"

const { defaultString: DEFAULT_STRING, locales, hotSignResourceHost } = config
const [, , enus] = locales.ids
const Wrapper = styled.div`
  height: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  .fc-filter-x {
    flex: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* 日期单选按钮组 */
    .ant-radio-group {
      // -- normal
      .ant-radio-button-wrapper {
        height: 30px;
        font-size: 12px;
        font-weight: 500;
        color: ${props => props.theme.btnText};
        border-color: ${props => props.theme.border};
        background-color: transparent;
        &:hover {
          color: ${props => props.theme.theme};
        }
        &:not(:first-child)::before {
          background-color: ${props => props.theme.border};
        }
      }
      // -- checked
      .ant-radio-button-wrapper-checked:not(
          .ant-radio-button-wrapper-disabled
        ) {
        background-color: transparent;
        color: ${props => props.theme.theme};
        border-color: currentColor;
        &:focus-within {
          box-shadow: unset;
        }
        &::before {
          background-color: currentColor;
        }
      }
    }
    .ant-picker {
      width: 140px;
      height: 30px;
      margin-left: 16px;
      border-color: ${props => props.theme.border};
      color: ${props => props.theme.btnText};
      background-color: transparent;
      &:hover,
      &-focused {
        border-color: ${props => props.theme.theme};
        box-shadow: unset;
      }
      .ant-picker-input > input {
        font-size: 12px;
        font-weight: 500;
        color: inherit;
      }
      .ant-picker-suffix {
        color: ${props => props.theme.border};
      }
    }
    .fc-btn-filter {
      margin-left: auto;
      height: 30px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: ${props => props.theme.btnText};
      font-weight: 500;
      &:hover,
      &.ant-popover-open,
      &.active {
        color: ${props => props.theme.theme};
      }
      .icon-filter {
        width: 14px;
        height: 14px;
        margin-top: 1px;
        margin-right: 4px;
        fill: currentColor;
      }
    }
  }
  .fc-table-ul {
    margin-top: 16px;
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    .ant-table {
      background-color: transparent;
      &-title {
        margin-bottom: 12px;
        padding: unset;
        font-size: 14px;
        font-weight: 500;
        color: ${props => props.theme.title};
      }
      &-container {
        .ant-table-thead .ant-table-cell {
          text-align: center !important;
        }
        .ant-table-tbody .ant-table-row {
          .ant-table-cell {
            // -- 时间列
            &.fc-cell-time {
              position: relative;
              padding: 0;
              border: 0;
              & > span {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 100%;
                padding: 8px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.show-border {
                  border-bottom: 1px solid ${props => props.theme.border};
                }
              }
            }
            // -- 指标名称 && 国家列
            &.fc-cell-name,
            &.fc-cell-country {
              padding-left: 8px;
              .ant-image {
                width: 30px;
                margin-right: 12px;
                flex: none;
              }
              & .ant-image-error {
                height: 20px;
                background-color: ${props => props.theme.border};
                img {
                  display: none;
                }
              }
            }
            &.fc-cell-event {
              padding-left: 0;
              .ant-image {
                width: 28px;
                margin-right: 12px;
                flex: none;
              }
            }
            &.fc-cell-actual {
              .no-time {
                color: ${props => props.theme.secondary};
              }
            }
            .fc-btn-showEcoDetail {
              width: 100%;
              height: 100%;
              padding: 0;
              font: inherit;
            }
          }
        }
      }
    }
    .ant-table-wrapper + .ant-table-wrapper {
      margin-top: 24px;
      .ant-table-tbody .fc-cell-time {
        border-bottom: 1px solid ${props => props.theme.border} !important;
      }
    }
    .fc-table-loading {
      width: unset;
      height: unset;
    }
  }
`

const FilterPopoverContentWrapper = styled.div`
  max-width: 300px;
  border-radius: 2px;
  .fpc-title {
    margin-bottom: 12px;
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.theme.title};
  }
  .fpc-btn-li.fpc-btn-li {
    width: 60px;
    height: 24px;
    padding: unset;
    text-align: center;
    line-height: 24px;
    background-color: ${props => props.theme.btnBg};
    border-radius: 2px;
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.theme.secondary};
    &.checked {
      background-color: ${props => props.theme.theme};
      color: ${props => props.theme.white};
    }
  }
  .fpc-checkbox-subUl {
    & > .fpc-btn-li {
      &:not(:nth-child(4n + 1)) {
        margin-left: 8px;
      }
      &:nth-child(n + 5) {
        margin-top: 8px;
      }
    }
  }
  .fpc-btn-submit.fpc-btn-submit {
    width: 100%;
    margin-top: 16px;
    background-color: ${props => props.theme.theme};
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.theme.white};
  }
  &.en {
    .fpc-btn-li.fpc-btn-li {
      width: unset;
      padding: 0 8px;
    }
    .fpc-checkbox-subUl:nth-of-type(5) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .fpc-btn-li.fpc-btn-li {
        margin-top: 0;
        margin-left: 0;
      }
      .fpc-btn-li:nth-last-child(-n + 2) {
        margin-top: 8px;
      }
      .fpc-btn-li:last-child {
        margin-left: 8px;
        margin-right: auto;
      }
    }
  }
`
const countryOpts = [
  [
    {
      key: "美国",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.America" />
      ),
    },
    {
      key: "加拿大",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.Canada" />
      ),
    },
    ,
  ],
  [
    {
      key: "欧元区",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.Eurozone" />
      ),
    },
    {
      key: "德国",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.Germany" />
      ),
    },
    {
      key: "英国",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.England" />
      ),
    },
    {
      key: "法国",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.France" />
      ),
    },
    {
      key: "瑞士",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.Switzerland" />
      ),
    },
    {
      key: "意大利",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.Italy" />
      ),
    },
    {
      key: "西班牙",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.Spain" />
      ),
    },
  ],
  [
    {
      key: "中国",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.China" />
      ),
    },
    {
      key: "日本",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.Japan" />
      ),
    },
    {
      key: "韩国",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.Korea" />
      ),
    },
    {
      key: "印度",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.India" />
      ),
    },
    {
      key: "新加坡",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.Singapore" />
      ),
    },
    {
      key: "中国香港",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.HK" />
      ),
    },
    {
      key: "中国台湾",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.TW" />
      ),
    },
  ],
  [
    {
      key: "澳大利亚",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.Australia" />
      ),
    },
    {
      key: "新西兰",
      label: (
        <FormattedMessage id="home.calendarPane.filterPopover.countries.NewZealand" />
      ),
    },
  ],
]
// NOTE: 【国家/地区 筛选】弹出层组件
const FilterPopoverContent = ({ onClose, handleFilter }) => {
  const { locale } = useIntl()
  const [checkedList, setCheckedList] = useState([])
  const [isCheckedAll, setIsCheckedAll] = useState(false)
  const handleCheck = val => {
    setCheckedList(checkedList => {
      const set = new Set(checkedList)
      !!set.has(val) ? set.delete(val) : set.add(val)
      const _checkedList = [...set]
      !!_checkedList?.length && setIsCheckedAll(false)
      return _checkedList
    })
  }
  const handleCheckAll = () => {
    if (!!isCheckedAll) return

    setCheckedList([])
    setIsCheckedAll(true)
  }

  return (
    <FilterPopoverContentWrapper className={locale === enus ? "en" : ""}>
      <p className="fpc-title">
        <FormattedMessage id="home.calendarPane.filterPopover.title" />
      </p>
      <Button
        className={classNames("initial", "no-trigger", "fpc-btn-li", {
          checked: isCheckedAll,
        })}
        onClick={handleCheckAll}
      >
        <FormattedMessage id="home.calendarPane.filterPopover.countries.all" />
      </Button>
      <Divider />
      <div className="fpc-checkbox-ul">
        {countryOpts.map((item, idx) => (
          <Fragment key={idx.toString()}>
            <div className="fpc-checkbox-subUl">
              {item.map(subItem => (
                <Button
                  key={subItem.key}
                  className={classNames("initial", "no-trigger", "fpc-btn-li", {
                    checked: checkedList.includes(subItem.key),
                  })}
                  onClick={() => handleCheck(subItem.key)}
                >
                  {subItem.label}
                </Button>
              ))}
            </div>
            {idx !== countryOpts.length - 1 && <Divider />}
          </Fragment>
        ))}
      </div>
      <Button
        className="initial no-trigger fpc-btn-submit"
        onClick={() => {
          onClose()
          handleFilter(checkedList)
        }}
      >
        <FormattedMessage id="home.calendarPane.filterPopover.submitBtnText" />
      </Button>
    </FilterPopoverContentWrapper>
  )
}
const FilterPopover = ({ filterKeys, handleFilter }) => {
  const [visible, setVisible] = useState(false)
  return (
    <Popover
      content={
        <FilterPopoverContent
          onClose={() => setVisible(false)}
          handleFilter={handleFilter}
        />
      }
      placement="bottomRight"
      trigger="click"
      visible={visible}
      onVisibleChange={setVisible}
    >
      <Button
        className={classNames("initial", "no-trigger", "fc-btn-filter", {
          active: !!filterKeys?.length,
        })}
      >
        <IconFont className="icon-filter" />
        <FormattedMessage id="home.calendarPane.filterPopover.btnText" />
      </Button>
    </Popover>
  )
}

// NOTE: 财经日历组件
// WARNING: 此项目如需商用，此组件内的金十接口数据需要与金十方沟通取得权限
export const FinanceCalendar = () => {
  const intl = useIntl()
  const dateOpts = [
    {
      value: moment().subtract(1, "d").format("YYYY-MM-DD"),
      label: intl.formatMessage({ id: "home.calendarPane.dateRadios.label1" }),
    },
    {
      value: moment().format("YYYY-MM-DD"),
      label: intl.formatMessage({ id: "home.calendarPane.dateRadios.label2" }),
    },
    {
      value: moment().add(1, "d").format("YYYY-MM-DD"),
      label: intl.formatMessage({ id: "home.calendarPane.dateRadios.label3" }),
    },
  ]
  const dateOptsDateStrs = dateOpts.map(item => item.value)

  const showFCDetailModal = useGlobalModal(
    strings.modalKeyFinanceCalendarDetail
  )
  const [currDateStr, setCurrDateStr] = useState(moment().format("YYYY-MM-DD"))
  const [ecoData, setEcoData] = useState([])
  const [ecoEventData, setEcoEventData] = useState([])
  const [filterKeys, setFilterKeys] = useState([])
  const [isEcoDataLoading, setIsEcoDataLoading] = useState(false)
  const [isEcoEventLoading, setIsEcoEventLoading] = useState(false)

  const shouldCellUpdate = (record, prevRecord) =>
    JSON.stringify(record) !== JSON.stringify(prevRecord)
  const handleEcoUnitVal = (index, unit) => {
    return index ? (unit === "%" ? index + "%" : index) : DEFAULT_STRING
  }
  const onFilterData = (data, filterKeys) => {
    if (!filterKeys?.length) return data
    return data.filter(item => filterKeys.includes(item.country))
  }
  const filterEcoData = useMemo(
    () => onFilterData(ecoData, filterKeys),
    [ecoData, filterKeys]
  )
  const ecoColumns = [
    {
      title: <FormattedMessage id="home.calendarPane.ecoTable.ths.th1" />,
      dataIndex: "pub_time",
      width: "120px",
      align: "center",
      className: "fc-cell-time",
      // shouldCellUpdate,
      render: (val, record, idx) => {
        const currTime = moment(val).format("HH:mm"),
          lastTime =
            idx !== 0
              ? moment(filterEcoData[idx - 1]?.pub_time).format("HH:mm")
              : null,
          nextTime =
            idx < filterEcoData.length - 1
              ? moment(filterEcoData[idx + 1]?.pub_time).format("HH:mm")
              : null
        const isSameToLast = currTime === lastTime
        const isSameToNext = currTime === nextTime
        return (
          <span
            className={classNames({
              "show-border": !isSameToNext,
            })}
          >
            {isSameToLast ? "" : currTime}
          </span>
        )
      },
    },
    {
      title: <FormattedMessage id="home.calendarPane.ecoTable.ths.th2" />,
      dataIndex: "title",
      align: "left",
      width: "30%",
      className: "fc-cell-name",
      shouldCellUpdate,
      render: (val, record) => (
        <div className="flex-ac">
          <Image
            src={`${hotSignResourceHost}/${record.country_image}`}
            preview={false}
          />
          <span>{val}</span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="home.calendarPane.ecoTable.ths.th3" />,
      dataIndex: "star",
      align: "center",
      shouldCellUpdate,
      render: val => <Star star={val} />,
    },
    {
      title: <FormattedMessage id="home.calendarPane.ecoTable.ths.th4" />,
      dataIndex: "actual",
      align: "center",
      className: "fc-cell-actual",
      shouldCellUpdate,
      // render: (val, record) => handleEcoUnitVal(val, record.unit),
      render: (val, record) => {
        return moment(record.pub_time).isAfter(moment()) ? (
          <span className="no-time">未公布</span>
        ) : (
          handleEcoUnitVal(val, record.unit)
        )
      },
    },
    {
      title: <FormattedMessage id="home.calendarPane.ecoTable.ths.th5" />,
      dataIndex: "consensus",
      align: "center",
      shouldCellUpdate,
      render: (val, record) => handleEcoUnitVal(val, record.unit),
    },
    {
      title: <FormattedMessage id="home.calendarPane.ecoTable.ths.th6" />,
      dataIndex: "previous",
      align: "center",
      shouldCellUpdate,
      render: (val, record) =>
        record.revised || handleEcoUnitVal(val, record.unit),
    },
    {
      title: <FormattedMessage id="home.calendarPane.ecoTable.ths.th7" />,
      dataIndex: "detail",
      align: "center",
      shouldCellUpdate,
      render: (_, record) => (
        <Button
          className="initial no-trigger fc-btn-showEcoDetail"
          onClick={() => showFCDetailModal({ data: record, handleEcoUnitVal })}
        >
          <FormattedMessage id="home.calendarPane.ecoTable.detailBtn" />
        </Button>
      ),
    },
  ]
  const eventColumns = [
    {
      title: <FormattedMessage id="home.calendarPane.eventTable.ths.th1" />,
      dataIndex: "event_time",
      width: "120px",
      align: "center",
      className: "fc-cell-time",
      shouldCellUpdate,
      render: (val, record, idx) => {
        if (Number(record.determine) === 1) {
          return (
            <span>
              <FormattedMessage id="home.calendarPane.eventTable.undetermined" />
            </span>
          )
        }
        return <span>{moment(val).format("HH:mm")}</span>
      },
    },
    {
      title: <FormattedMessage id="home.calendarPane.eventTable.ths.th2" />,
      dataIndex: "country",
      width: "120px",
      className: "fc-cell-country",
      shouldCellUpdate,
      render: (val, record) => (
        <div className="flex-ac">
          {/* <Image
            src={`https://cdn.jin10.com/assets/img/commons/flag/${val}.png`}
            preview={false}
            fallback=""
          /> */}
          <Image
            src={`${hotSignResourceHost}/${record.country_image}`}
            preview={false}
          />
          <span>{val}</span>
        </div>
      ),
    },
    {
      title: <FormattedMessage id="home.calendarPane.eventTable.ths.th3" />,
      dataIndex: "star",
      align: "center",
      width: "160px",
      shouldCellUpdate,
      render: val => <Star star={val} />,
    },
    {
      title: <FormattedMessage id="home.calendarPane.eventTable.ths.th4" />,
      className: "fc-cell-event",
      dataIndex: "event_content",
      shouldCellUpdate,
      render: (val, record) => (
        <div className="flex-ac">
          {record.people && (
            <Image
              src={`https://cdn.jin10.com/images/flag/tx/${record.people}.png`}
              preview={false}
            />
          )}
          {val}
        </div>
      ),
    },
  ]

  useEffect(() => {
    const getEcoData = async date => {
      setIsEcoDataLoading(true)
      // const { error, data } = await get(
      //   apis.getCalendarData({ ...args }),
      //   undefined,
      //   { isPrepareUrl: true }
      // )
      const { error, data } = await post(apis.getEcoData, {
        page: 1,
        size: 999,
        time: date.format("YYYY-MM-DD"),
      })
      setIsEcoDataLoading(false)
      if (error) return
      setEcoData(data)
    }
    const getEcoEventData = async date => {
      setIsEcoEventLoading(true)
      // let { error, data } = await get(
      //   apis.getCalendarEventData({ ...args }),
      //   undefined,
      //   { isPrepareUrl: true }
      // )
      let { error, data } = await post(apis.getEcoEvent, {
        page: 1,
        size: 999,
        time: date.format("YYYY-MM-DD"),
      })
      setIsEcoEventLoading(false)
      if (error) return
      data = data.sort((a, b) => a.determine - b.determine) // 升序
      setEcoEventData(data)
    }
    const _moment = moment(currDateStr)
    // const args = {
    //   year: _moment.year(),
    //   month: addZeroInFront(_moment.month() + 1),
    //   date: addZeroInFront(_moment.date()),
    // }
    getEcoData(_moment)
    getEcoEventData(_moment)
  }, [currDateStr])

  return (
    <Wrapper>
      <div className="fc-filter-x">
        <Radio.Group
          value={dateOptsDateStrs.includes(currDateStr) ? currDateStr : ""}
          options={dateOpts}
          optionType="button"
          onChange={e => setCurrDateStr(e.target.value)}
        />
        <DatePicker
          allowClear={false}
          value={moment(currDateStr)}
          onChange={(date, dateStr) => setCurrDateStr(dateStr)}
        />
        <FilterPopover filterKeys={filterKeys} handleFilter={setFilterKeys} />
      </div>
      <div className="fc-table-ul">
        <Table
          loading={{
            indicator: <Loading className="fc-table-loading" />,
            spinning: isEcoDataLoading,
          }}
          rowKey={record => record.id} // 必须！！
          title={() =>
            intl.formatMessage({
              id: "home.calendarPane.ecoTable.title",
            })
          } // title 是 function 类型
          dataSource={filterEcoData}
          columns={ecoColumns}
          pagination={false}
        />
        <Table
          loading={{
            indicator: <Loading className="fc-table-loading" />,
            spinning: isEcoEventLoading,
          }}
          className="fc-table-event"
          rowKey={record => record.id} // 必须！！
          title={() =>
            intl.formatMessage({
              id: "home.calendarPane.eventTable.title",
            })
          } // title 是 function 类型
          dataSource={onFilterData(ecoEventData, filterKeys)}
          columns={eventColumns}
          pagination={false}
        />
      </div>
    </Wrapper>
  )
}
