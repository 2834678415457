const modal = {
  modal: {
    symbolInfo: {
      mTitle1: "基礎信息",
      mTitle2: "交易時間",
      weeks: {
        sun: "星期日",
        mon: "星期一",
        tues: "星期二",
        wed: "星期三",
        thurs: "星期四",
        fri: "星期五",
        sat: "星期六",
      },
    },
    accInfoTip: {
      title: "賬戶信息計算規則",
      item1: {
        label: "當前權益",
        formula: "當前權益 = 餘額 ± 浮動盈虧",
      },
      item2: {
        label: "可用資金",
        formula: "可用資金 = 當前權益 - 已用保證金",
      },
      item3: {
        label: "風險度",
        formula: "風險度 = 已用保證金 / 當前權益",
      },
      item4: {
        label: "持倉盈虧",
        formula: "即浮動盈虧。持倉盈虧 = 浮動盈虧 = 淨值 - 餘額",
      },
    },
    orderEdit: {
      formItem1: {
        label: "止盈",
        profitText: "預計收益：",
      },
      formItem2: {
        label: "止損",
        profitText: "預計虧損：",
      },
      profitError: "價格設置錯誤，請重試",
      descUl: {
        item1: "訂單編號",
        item2: "開倉時間",
        item3: "手續費",
        item4: "庫存費",
      },
      message: {
        error: {
          valNull: "請填寫止盈或止損價格",
          tpInvalid: "止盈價格有誤",
          slInvalid: "止損價格有誤",
          editFailed: {
            title: "訂單修改失敗",
            desc: "訂單 {ticket} 止盈/止損值修改失敗{error}",
          },
        },
        success: {
          editSuccess: {
            title: "訂單修改成功",
            desc: "訂單 {ticket} 止盈/止損值修改成功",
          },
        },
      },
    },
    orderTrade: {
      tabs: {
        tab1: "市場",
        tab2: "掛單",
      },
      market: {
        descItem1: "現價",
        descItem2: "保證金",
        formItem1: "交易量",
        formItem2: {
          label: "止盈",
          profitText: "預計收益",
        },
        formItem3: {
          label: "止損",
          profitText: "預計虧損",
        },
        submitBtnText: {
          buy: "做多",
          sell: "做空",
        },
        message: {
          error: {
            volumeInvalid: "交易量有誤",
            tpInvalid: "止盈價格有誤",
            slInvalid: "止損價格有誤",
          },
        },
      },
      limit: {
        formItemPrice: "成交價格",
        submitBtnText: {
          buyLimit: "限價買入",
          sellLimit: "限價賣出",
          buyStop: "買入止損",
          sellStop: "賣出止損",
        },
        message: {
          error: {
            priceNull: "請填寫成交價格",
            volumeInvalid: "交易量有誤",
            tpInvalid: "止盈價格有誤",
            slInvalid: "止損價格有誤",
          },
        },
      },
      message: {
        success: {
          createOrder: {
            title: "訂單創建成功",
            desc: "訂單編號為： {ticket}",
          },
        },
        error: {
          marginNull: "商品保證金獲取失敗，請重試",
          profitInvalid: "價格設置錯誤，請重試",
          createOrder: {
            title: "訂單創建失敗",
          },
        },
      },
    },
    settings: {
      title: "設置",
      item1: {
        label: "平倉確認",
        tooltip: "如打開選項，關閉未平倉倉位之前需要用戶確認。",
      },
      more: "靜候更多自定義配置 ...",
    },
  },
}
export default modal
