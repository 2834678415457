import { Descriptions } from "antd"
import { FormattedMessage } from "react-intl"
import GlobalModal from "./GlobalModal"

const infoTips = [
  {
    label: <FormattedMessage id="modal.accInfoTip.item1.label" />,
    formula: <FormattedMessage id="modal.accInfoTip.item1.formula" />,
  },
  {
    label: <FormattedMessage id="modal.accInfoTip.item2.label" />,
    formula: <FormattedMessage id="modal.accInfoTip.item2.formula" />,
  },
  {
    label: <FormattedMessage id="modal.accInfoTip.item3.label" />,
    formula: <FormattedMessage id="modal.accInfoTip.item3.formula" />,
  },
  {
    label: <FormattedMessage id="modal.accInfoTip.item4.label" />,
    formula: <FormattedMessage id="modal.accInfoTip.item4.formula" />,
  },
]
export const AccInfoTipModal = ({ visible, onCancel }) => {
  return (
    <GlobalModal
      title={<FormattedMessage id="modal.accInfoTip.title" />}
      width={420}
      visible={visible}
      onCancel={onCancel}
      footer={null}
    >
      <Descriptions column={1} colon={false} layout="vertical" bordered>
        {infoTips.map((item, index) => (
          <Descriptions.Item key={index} label={item.label}>
            {item.formula}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </GlobalModal>
  )
}
