import { useRef, useState } from "react"
import { InputNumber, message, notification, Switch } from "antd"
import styled from "styled-components"
import GlobalModal from "./GlobalModal"
import config from "../../config/config"
import classNames from "classnames"
import { useSelector } from "react-redux"
import { selectSymbolByName } from "../../store/symbolSlice"
import { post } from "../../services/request"
import apis from "../../services/apis"
import { FormattedMessage, useIntl } from "react-intl"
import moment from "moment"

const { cmdConfig, defaultString: DEFAULT_STRING, locales } = config
const [, , enus] = locales.ids
const { buyIds, limitIds } = cmdConfig

const Wrapper = styled(GlobalModal)`
  .ant-modal-header {
    .oem-title-x {
      vertical-align: baseline;
      .cmd {
        margin-left: 8px;
        font-size: 13px;
        font-weight: 500;
        color: ${props => props.theme.themeRed};
        &.sell {
          color: ${props => props.theme.themeGreen};
        }
      }
    }
  }
  .ant-modal-body.ant-modal-body {
    /* padding-top: 16px; */
  }
  .oem-form-ul {
    & > * + * {
      margin-top: 8px;
    }
    .oem-form-li {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .oem-label {
        margin-right: 8px;
        font-weight: 500;
        color: ${props => props.theme.primary};
      }
      .oem-tip {
        visibility: hidden;
        margin-right: auto;
        color: ${props => props.theme.secondary};
        &.show {
          visibility: visible;
        }
      }
    }
    .oem-profit-x {
      margin-left: -16px;
      margin-right: -16px;
      padding: 0 16px;
      background-color: ${props => props.theme.tradeModalBg};
      font-size: 12px;
      color: ${props => props.theme.secondary};
      display: none;
      &.active {
        height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      & > span {
        line-height: 1em;
      }
      & > .oem-profit {
        &.green {
          font-weight: 500;
          color: ${props => props.theme.themeRed};
        }
        &.red {
          font-weight: 500;
          color: ${props => props.theme.themeGreen};
        }
      }
    }
  }
  .oem-desc-ul {
    margin-top: 12px;
    font-size: 12px;
    color: ${props => props.theme.secondary};
    .oem-desc-li {
      line-height: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`
export const OrderEditModal = ({ type, data, onCancel, ...props }) => {
  // console.log("==OrderEditModal", data, props)
  const intl = useIntl()
  const info = useSelector(state => selectSymbolByName(state, data.name))
  const {
    name,
    cnName,
    tp,
    sl,
    cmd,
    volume,
    ticket,
    open_time,
    open_price,
    commission,
    storage,
    digits,
  } = data
  const { ask, bid, size, trans_price_ask } = info
  const isBuy = buyIds.includes(cmd)
  const price = limitIds.includes(cmd) ? open_price : isBuy ? bid : ask
  // 止盈价格边界值
  const tpLimitPrice = limitIds.includes(cmd) ? open_price : isBuy ? ask : bid
  const slLimitPrice = limitIds.includes(cmd) ? open_price : isBuy ? bid : ask
  const priceStep = (10 ** (-1 * digits)).toFixed(digits)

  const tpRef = useRef(null)
  const slRef = useRef(null)
  const [isSwitchChecked, setIsSwitchChecked] = useState([
    type === 0 ? true : false,
    type === 1 ? true : false,
  ])
  const [profits, setProfits] = useState(new Array(2).fill(DEFAULT_STRING))
  const getProfit = (val, type) => {
    let profit
    const closePrice = Number(val) //type === 0 ? tp : sl
    if (!closePrice) {
      profit = DEFAULT_STRING
    } else if (
      (isBuy &&
        ((!type && closePrice < price) || (type && closePrice > price))) ||
      (!isBuy &&
        ((!type && closePrice > price) || (type && closePrice < price)))
    ) {
      profit = <FormattedMessage id="modal.orderEdit.profitError" />
    } else {
      const flag = (-1) ** (Number(isBuy) ^ 1)
      profit = (
        (closePrice - open_price) *
        volume *
        size *
        trans_price_ask *
        flag
      ).toFixed(2)
    }
    setProfits(profits =>
      type === 0 ? [profit, profits[1]] : [profits[0], profit]
    )
    return profit
  }
  // 提交修改
  const handleSubmit = async () => {
    const tp = tpRef.current.value
    const sl = slRef.current.value
    let params = {
      lots: volume,
      ticket,
      tp,
      sl,
      open_price,
    }
    if (!isSwitchChecked.reduce((prev, curr) => prev || curr)) {
      message.error(
        intl.formatMessage({ id: "modal.orderEdit.message.error.valNull" })
      )
      return
    }
    // 检验 止盈 tp 的值
    if (isSwitchChecked[0]) {
      if (!tp || !Number(profits[0])) {
        message.error(
          intl.formatMessage({ id: "modal.orderEdit.message.error.tpInvalid" })
        )
        return
      }
      params.tp = tp
    }
    // 检验 止损 sl 的值
    if (isSwitchChecked[1]) {
      if (!sl || !Number(profits[1])) {
        message.error(
          intl.formatMessage({ id: "modal.orderEdit.message.error.slInvalid" })
        )
        return
      }
      params.sl = sl
    }
    const { error } = await post(apis.modifyOrder, params)
    if (error) {
      notification["error"]({
        message: intl.formatMessage({
          id: "modal.orderEdit.message.error.editFailed.title",
        }),
        description: intl.formatMessage(
          {
            id: "modal.orderEdit.message.error.editFailed.desc",
          },
          {
            ticket,
            error: !!error && `：${error}`,
          }
        ),
      })
      return
    }
    notification["success"]({
      message: intl.formatMessage({
        id: "modal.orderEdit.message.success.editSuccess.title",
      }),
      description: intl.formatMessage(
        {
          id: "modal.orderEdit.message.success.editSuccess.desc",
        },
        {
          ticket,
        }
      ),
    })
    onCancel()
  }
  return (
    <Wrapper
      title={
        <p className="oem-title-x">
          <span className="name">{intl.locale !== enus ? cnName : name}</span>
          <span
            className={classNames("cmd", {
              sell: !isBuy,
            })}
          >
            {cmdConfig.entities[cmd]}&nbsp;&nbsp;{volume}
          </span>
        </p>
      }
      width={340}
      onOk={handleSubmit}
      onCancel={onCancel}
      {...props}
    >
      <div className="oem-form-ul">
        <div className="oem-form-li">
          <span className="oem-label">
            <FormattedMessage id="modal.orderEdit.formItem1.label" />
          </span>
          <Switch
            className="no-trigger"
            size="small"
            checked={isSwitchChecked[0]}
            onChange={() => setIsSwitchChecked(val => [!val[0], val[1]])}
          />
          <span className={classNames("oem-tip", { show: isSwitchChecked[0] })}>
            {isBuy ? ">" : "<"} {tpLimitPrice}
          </span>
          <InputNumber
            key={ticket}
            ref={tpRef}
            onBlur={() => getProfit(tpRef.current.value, 0)}
            step={priceStep}
            min={0}
            defaultValue={tp}
            placeholder={DEFAULT_STRING}
            disabled={!isSwitchChecked[0]}
          />
        </div>
        <div
          className={classNames("oem-profit-x", { active: isSwitchChecked[0] })}
        >
          <span>
            <FormattedMessage id="modal.orderEdit.formItem1.profitText" />
          </span>
          <span
            className={classNames("oem-profit", {
              green: !!Number(profits[0]),
            })}
          >
            {profits[0]}
          </span>
        </div>
        <div className="oem-form-li">
          <span className="oem-label">
            <FormattedMessage id="modal.orderEdit.formItem2.label" />
          </span>
          <Switch
            size="small"
            checked={isSwitchChecked[1]}
            onChange={() => setIsSwitchChecked(val => [val[0], !val[1]])}
          />
          <span className={classNames("oem-tip", { show: isSwitchChecked[1] })}>
            {isBuy ? "<" : ">"} {slLimitPrice}
          </span>
          <InputNumber
            key={ticket}
            ref={slRef}
            onBlur={() => getProfit(slRef.current.value, 1)}
            step={priceStep}
            min={0}
            defaultValue={sl}
            placeholder={DEFAULT_STRING}
            disabled={!isSwitchChecked[1]}
          />
        </div>
        <div
          className={classNames("oem-profit-x", { active: isSwitchChecked[1] })}
        >
          <span>
            <FormattedMessage id="modal.orderEdit.formItem2.profitText" />
          </span>
          <span
            className={classNames("oem-profit", { red: !!Number(profits[1]) })}
          >
            {profits[1]}
          </span>
        </div>
      </div>
      <div className="oem-desc-ul">
        <div className="oem-desc-li">
          <span className="label">
            <FormattedMessage id="modal.orderEdit.descUl.item1" />
          </span>
          <span className="val">{ticket}</span>
        </div>
        <div className="oem-desc-li">
          <span className="label">
            <FormattedMessage id="modal.orderEdit.descUl.item2" />
          </span>
          <span className="val">
            {intl.locale !== enus
              ? open_time
              : moment(open_time)
                  .locale("en")
                  .format("MMMM Do YYYY, h:mm:ss A")}
          </span>
        </div>
        <div className="oem-desc-li">
          <span className="label">
            <FormattedMessage id="modal.orderEdit.descUl.item3" />
          </span>
          <span className="val">{commission}</span>
        </div>
        <div className="oem-desc-li">
          <span className="label">
            <FormattedMessage id="modal.orderEdit.descUl.item4" />
          </span>
          <span className="val">{storage}</span>
        </div>
      </div>
    </Wrapper>
  )
}
