const home = {
  home: {
    quotePane: {
      title: "品种报价",
      tabs: {
        favorite: "自选",
        inland: "国内期货",
        intl: "国际市场",
      },
      table: {
        ths: {
          th1: "品种",
          th2: "涨跌幅",
          th3: "卖",
          th4: "买",
        },
        tr: {
          sell: "做空",
          buy: "做多",
          high: "最高价",
          low: "最低价",
        },
      },
      message: {
        error: {
          marginNull: "交易产品保证金信息获取失败，请重试",
          transactFailed: "下单失败",
        },
        success: {
          transactSuccess: {
            title: "下单成功",
            desc: "成功交易 {desc}手",
          },
        },
      },
      tooltip: {
        favoriteTab: "仅显示当前账户下能交易的商品",
      },
    },
    chartsPane: {
      title: "图表",
    },
    calendarPane: {
      title: "财经日历",
      dateRadios: {
        label1: "昨天",
        label2: "今天",
        label3: "明天",
      },
      ecoTable: {
        title: "经济数据",
        ths: {
          th1: "时间",
          th2: "指标名称",
          th3: "重要性",
          th4: "公布值",
          th5: "预测值",
          th6: "前值",
          th7: "数据解读",
        },
        detailBtn: "详情",
      },
      eventTable: {
        title: "经济事件",
        ths: {
          th1: "时间",
          th2: "国家",
          th3: "重要性",
          th4: "事件",
        },
        undetermined: "待定",
      },
      filterPopover: {
        btnText: "筛选",
        title: "国家/地区",
        countries: {
          all: "全部",
          America: "美国",
          Canada: "加拿大",
          Eurozone: "欧元区",
          Germany: "德国",
          England: "英国",
          France: "法国",
          Switzerland: "瑞士",
          Italy: "意大利",
          Spain: "西班牙",
          China: "中国",
          Japan: "日本",
          Korea: "韩国",
          India: "印度",
          HK: "中国香港",
          TW: "中国台湾",
          Singapore: "新加坡",
          Australia: "澳大利亚",
          NewZealand: "新西兰",
        },
        submitBtnText: "确定",
      },
      modal: {
        infos: {
          item1: "最新发布",
          item2: "公布值",
          item3: "预测值",
          item4: "前值",
          item5: "下次公布时间",
          item6: "重要性",
          noPub: "未公布",
        },
        subTitle2: "历史数据",
        subTitle3: "往期数据",
        table: {
          thead1: "日期",
          thead2: "前值",
          thead3: "预测值",
          thead4: "公布值",
        },
        error: {
          getData: "获取历史数据失败",
        },
      },
    },
    trWidgets: {
      menu: {
        settings: "设置",
        signOut: "退出登录",
      },
    },
    orderPane: {
      tabs: {
        tab1: "持仓单",
        tab2: "挂单交易",
        tab3: "历史订单",
      },
      market: {
        ths: {
          th1: "品种",
          th2: "手数",
          th3: "方向",
          th4: "开仓价/即时价",
          th5: "开仓时间",
          th6: "止盈",
          th7: "止损",
          th8: "隔夜利息",
          th9: "盈利",
          th10: {
            btnText: "平仓",
            ddItem1: "对所有头寸进行平仓",
            ddItem2: "对盈利头寸进行平仓",
            ddItem3: "对亏损头寸进行平仓",
          },
        },
        closeText: "平仓",
        message: {
          success: {
            closeSuccess: "{closeText}成功",
            closeSuccessDesc: "订单{tickets}{closeText}成功",
          },
          errorPartially: {
            title: "订单部分{closeText}失败",
            desc: "失败订单编号：{failedTickets}，失败原因依次为：{failedReason}",
          },
          error: {
            noTickets: "暂无相关订单",
            closeFailed: "{closeText}失败",
          },
        },
        confirm: {
          title: "确定{closeText}下列订单号的订单？",
          okText: "确定",
          cancelText: "取消",
        },
      },
      limit: {
        ths: {
          th4: "挂单价/即时价",
          th10: "全部撤销",
        },
        closeText: "撤销",
      },
      history: {
        picker: {
          placeholder: {
            startText: "开始日期",
            endText: "结束日期",
          },
        },
        ths: {
          th1: "品种",
          th2: "手数",
          th3: "方向",
          th4: "开仓价/平仓价",
          th5: "开仓时间",
          th6: "平仓时间",
          th7: "止盈",
          th8: "止损",
          th9: "隔夜利息",
          th10: "盈利",
          th11: "订单号", // 仅用于导出 excel
        },
        exportBtn: "导出订单数据",
      },
    },
    accountPane: {
      labels: {
        label1: "当前权益",
        label2: "可用资金",
        label3: "风险度",
        label4: {
          market: "持仓盈亏",
          history: "历史盈亏",
        },
      },
    },
  },
}

export default home
